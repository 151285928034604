import {
  SIDEBAR_FILTERS_CLEAR_IDS,
  SIDEBAR_FILTERS_CLEAR,
  SIDEBAR_FILTERS_SET,
} from '../constants';

export const clearSidebarFilter = () => ({
  type: SIDEBAR_FILTERS_CLEAR,
});

export const setSidebarFilter = (filterType, props, noApiCall = false) => ({
  type: SIDEBAR_FILTERS_SET,
  payload: {
    filterType,
    props,
    noApiCall,
  },
});

export const clearSidebarFilterByKeys = (filterType, keys, ids, parentKey) => ({
  type: SIDEBAR_FILTERS_CLEAR_IDS,
  payload: {
    filterType,
    keys,
    ids,
    parentKey,
  },
});
