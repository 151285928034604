import { ShippingClient } from 'clients/shipping-client';
import { notifyError } from 'actions/action-notifications';
import axios, { AxiosError } from 'axios';
import { IGeneratedDocumentResponse } from 'models/document_generator/IGeneratedDocumentResponse';
import { GeneratorUtil } from 'components/document/generator/generator-util';
import printJS from 'print-js';
import { windowFeatureIsEnabled } from 'config/window-features';
import { DeliveryMethodValue } from '../../constants/enums';
import { IPrintLabelButton } from './types';

export const printLabel = async ({
  orderId,
  deliveryMethod,
  dispatch,
  setPrintLabelClicked,
}: IPrintLabelButton) => {
  const isCurrentMethodOfDeliveryUps = deliveryMethod === DeliveryMethodValue.Ups;
  const isCurrentMethodOfDeliveryCourier = deliveryMethod === DeliveryMethodValue.Courier;

  try {
    setPrintLabelClicked?.(true);
    const generatedLabelResponse = await ShippingClient.fetchLabel(orderId);
    const print_courier_label_is_enabled = windowFeatureIsEnabled('courier_label');
    if (
      generatedLabelResponse?.data?.printInformation?.nuvizz_courier_order_id &&
      !print_courier_label_is_enabled
    ) {
      setPrintLabelClicked?.(true);
      return { nuVizzOrder: true };
    }

    const labelObject: IGeneratedDocumentResponse = {
      fileType: generatedLabelResponse.data.fileType,
      generatedDocument: generatedLabelResponse.data.labelBase64,
      mimeType: `image/${generatedLabelResponse.data.fileType}`,
    };
    const labLogisticsOrderId =
      generatedLabelResponse?.data?.printInformation?.lab_logistics_order_id;
    const trackingNumber = generatedLabelResponse.data.printInformation?.tracking_number;
    const associatedCourierOrderId =
      generatedLabelResponse?.data?.printInformation?.associated_courier_order_id;
    const nuvizzCourierOrderId =
      generatedLabelResponse?.data?.printInformation?.nuvizz_courier_order_id;

    if (nuvizzCourierOrderId) {
      printJS({
        printable: labelObject.generatedDocument,
        documentTitle: 'Shipping Label',
        type: 'pdf',
        base64: true,
      });
    }
    if (generatedLabelResponse?.data?.printInformation?.internal_courier) {
      printJS({
        printable: labelObject.generatedDocument,
        documentTitle: 'Shipping Label',
        type: 'pdf',
        base64: true,
      });
    }
    const viewableResult = GeneratorUtil.convertToViewable(
      labelObject,
      `${isCurrentMethodOfDeliveryUps && trackingNumber}` ||
        `${
          isCurrentMethodOfDeliveryCourier && labLogisticsOrderId
            ? labLogisticsOrderId
            : associatedCourierOrderId
        }` ||
        `${isCurrentMethodOfDeliveryCourier && nuvizzCourierOrderId}`,
    );

    if (trackingNumber) {
      printJS({
        printable: viewableResult.url,
        documentTitle: viewableResult.fileName,
        type: 'image',
        showModal: true,
      });
    }

    if (labLogisticsOrderId) {
      printJS({
        printable: viewableResult.url,
        documentTitle: viewableResult.fileName,
        type: 'pdf',
        showModal: true,
      });
    }

    if (associatedCourierOrderId) {
      printJS({
        printable: viewableResult.url,
        documentTitle: viewableResult.fileName,
        type: 'pdf',
        showModal: true,
      });
    }

    setPrintLabelClicked?.(true);
    return { nuVizzOrder: false };
  } catch (error) {
    // Provide the user with a descriptive message, including the AxiosError message if present
    let responseErrorMessage;
    if (axios.isAxiosError(error)) {
      const responseError = error as AxiosError<{ message?: string }>;
      responseErrorMessage = responseError.response?.data?.message ?? responseError.response?.data;
    } else {
      responseErrorMessage = null;
    }
    const generateShippingLabelMessage = 'Unable to generate shipping label.';
    const notifyMessage =
      responseErrorMessage == null
        ? generateShippingLabelMessage
        : `${generateShippingLabelMessage} ${responseErrorMessage}`;
    setPrintLabelClicked?.(false);
    dispatch?.(notifyError(notifyMessage));
    throw error;
  }
};
