import React, { useEffect, useState } from 'react';
import {
  IDocumentInfo,
  IPatientMergeProps,
  IPatientMergeSectionDetailsProps,
} from 'components/patient-merge-modal/interfaces';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { DocumentInfo } from 'components/patient-merge-modal/info-component/document-info';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { PatientMergeClient } from 'clients/patient-merge';
import { LookupResourceTypes } from 'constants/enums';
import {
  addInput,
  IPatientMergeInput,
  removeInput,
  addMultiInput,
  removeMultiInput,
} from 'actions/action-patient-merge';
import { isInputSelected } from 'components/patient-merge-modal/utils';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { isEmpty } from 'lodash';

export const DocumentSection = (props: IPatientMergeSectionDetailsProps) => {
  const { mergeId, expanded } = props;
  const [data, setData] = useState<IDocumentInfo[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [primaryData, setPrimaryData] = useState<IDocumentInfo[]>([]);
  const [selectedData, setSelectedData] = useState<IDocumentInfo[]>([]);
  const primary = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (expanded && !loaded) {
      PatientMergeClient.getMergeDocumentInfo(mergeId).then(response => {
        setData(response.data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);

  useEffect(() => {
    setPrimaryData(data.filter(document => document.patientId === primary));
  }, [primary, data]);
  useEffect(() => {
    setSelectedData(data.filter(document => document.patientId === selectedProfileId));
  }, [selectedProfileId, data]);

  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const selectValue = (value: boolean, type: LookupResourceTypes, selectedId: number) => {
    const input: IPatientMergeInput = {
      type,
      selectedId,
      sectionName: props.sectionName,
    };
    dispatch(value ? addInput(input) : removeInput(input));
  };

  useEffect(() => {
    const inputs = selectedData.map(x => ({
      type: LookupResourceTypes.Document,
      selectedId: x.id,
    }));
    dispatch(selectAll ? addMultiInput(inputs) : removeMultiInput(inputs));
  }, [selectAll]);
  const handleCheckbox =
    (type: LookupResourceTypes, selectedId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      selectValue(e.target.checked, type, selectedId);
    };

  const disableSelectAll = isEmpty(selectedData);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          sx={classes.checkboxFormControl}
          label="Check all"
          control={
            <Checkbox
              sx={classes.checkbox}
              disabled={disableSelectAll}
              onChange={event => setSelectAll(event.target.checked)}
              checked={selectAll}
            />
          }
        />
      </Grid>
      <Grid container item xs={6}>
        {selectedData.map((document: IDocumentInfo) => {
          return (
            <FormControlLabel
              key={`duplicate-${document.id}`}
              sx={classes.checkboxFormControl}
              label={<DocumentInfo document={document} />}
              control={
                <Checkbox
                  sx={classes.checkbox}
                  onChange={handleCheckbox(LookupResourceTypes.Document, document.id)}
                  checked={isInputSelected(document.id, LookupResourceTypes.Document, inputs)}
                />
              }
            />
          );
        })}
      </Grid>
      <Grid container item xs={6}>
        {primaryData.map((document: IDocumentInfo) => {
          return <DocumentInfo document={document} />;
        })}
      </Grid>
    </Grid>
  );
};
