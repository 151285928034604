/* eslint-disable no-confusing-arrow */
import React, { Component, Fragment } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Divider, Chip, Typography, Button } from '@mui/material';
import compose from 'recompose/compose';
import { SmallSpacer } from 'components/spacer/spacer';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import FieldTitle from 'components/form/field/field-title';
import { styles } from 'containers/tasks/task-detail-styles';
import { getObjectProperty } from 'helpers/misc';
import { initial, isEqual } from 'lodash';
import {
  COMMUNICATION_FORM,
  INT,
  COMMUNICATION_FORM_PHONE_CALL,
  DEFAULTED_DATE_FIELDS,
} from 'constants/index';
import { PillIcon } from 'components/icons/icons';
import { buildQaId } from 'utils/build-qa-id';
import {
  getInitialValues,
  getResultValues,
  getResultValue,
  evaluateCondition,
  getStatusByJsonId,
  getStatusByStatusId,
  getConfigFieldsByLevels,
  filterObjectFields,
} from './helpers';
import JsonField from './json-field';

const qaIdBuilder = buildQaId('dosing-regimen', '.');

class Form extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initialValues = null;
  }

  componentDidUpdate(prevProps) {
    const {
      valid,
      change,
      pristine,
      onValidityChange,
      formValues,
      externalValues,
      shouldShowCurrentStatusform,
      providers,
      forceStatusId,
      reduxFormValues,
    } = this.props;
    if (forceStatusId !== prevProps.forceStatusId) {
      change('status_id', forceStatusId);
    }

    // The else if statements are for when the valid prop passed down by redux form
    // and the actual validation in the syncErrors of the form differ
    // not sure what causes this and it occurs rarely and sporatically
    // we are treating the sync errors as the source of truth

    if (onValidityChange !== undefined) {
      if (prevProps.valid !== valid) {
        // For typical form validation changes
        onValidityChange(valid, pristine);
      } else if (
        prevProps &&
        prevProps.reduxFormValues &&
        prevProps.reduxFormValues.syncErrors &&
        Object.keys(prevProps.reduxFormValues.syncErrors).length > 0 &&
        reduxFormValues &&
        !reduxFormValues.syncErrors
      ) {
        // If a form that is not validated should be validated
        onValidityChange(true);
      } else if (
        prevProps &&
        prevProps.reduxFormValues &&
        !prevProps.reduxFormValues.syncErrors &&
        reduxFormValues &&
        reduxFormValues.syncErrors &&
        Object.keys(reduxFormValues.syncErrors).length > 0
      ) {
        // If a form that is validated should not be validated
        onValidityChange(false);
      }
    }

    if (formValues !== prevProps.formValues || this.haveLinkedTaskIdsChanged(prevProps)) {
      const { onFormChange, config } = this.props;
      if (onFormChange) {
        const visibleFields = this.getVisibleFields(externalValues, shouldShowCurrentStatusform);
        const values = Object.keys(formValues)
          .filter(property => visibleFields.find(it => it.property === property))
          .reduce((acc, property) => {
            acc[property] = getResultValue(
              config,
              property,
              formValues[property],
              providers,
              formValues,
            );
            return acc;
          }, {});
        visibleFields.forEach(field => {
          const { property, type } = field;
          // if is row start or end just ignore
          if (['row-start', 'row-end'].includes(type)) return;
          if (!Object.prototype.hasOwnProperty.call(values, property)) {
            values[property] = undefined;
          }
        });
        onFormChange(values, valid);
      }
    }
  }

  componentWillUnmount() {
    const { onValidityChange, onUnmount } = this.props;
    if (onValidityChange) {
      onValidityChange(true);
    }
    if (onUnmount) {
      onUnmount();
    }
  }

  isFieldVisible(config, providers, field) {
    const { initialValues, taskStatusId, data } = this.props;
    const { formValues } = providers;
    let visible = true;
    const statusId =
      taskStatusId ||
      formValues.status_id ||
      (data?.taskType === INT ? initialValues.status_id || data.status_id : formValues.status_id);

    if (formValues && field.status && field.status.length && statusId) {
      const shouldBeVisibleOnStatusNames = config.statuses
        .filter(it => field.status.includes(it.id))
        .map(it => it.name);
      const currentStatusName = getObjectProperty(
        providers.statuses.find(it => it.id === statusId),
        'name',
        '',
      );
      visible = shouldBeVisibleOnStatusNames.includes(currentStatusName);
    }

    if (field.transitioning) {
      visible = visible && statusId !== initialValues.status_id;
    }
    if (Object.prototype.hasOwnProperty.call(field, 'visible')) {
      visible = visible && evaluateCondition(config, providers, field.visible, formValues);
    }

    return visible;
  }

  getVisibleFields(externalValues, shouldShowCurrentStatusform) {
    const { config, level, providers, formValues } = this.props;
    const combinedValues = { ...formValues, ...externalValues };
    const visibleFields = config.fields.filter(field => {
      // if its a row start or end just make it visible
      if (['row-start', 'row-end', 'qlabel'].includes(field.type)) return true;

      const inLevel = !level || level === field.level;
      const visible = this.isFieldVisible(
        config,
        {
          ...providers,
          formValues: combinedValues,
          config,
        },
        field,
        shouldShowCurrentStatusform,
      );
      // Filter visible fields which level is matched ("patients, tasks, therapies")
      // or no level requirements (i.e. In therapy tab)
      return inLevel && visible;
    });
    // Remove duplicated field for the same property
    const visibleFieldsMap = {};
    visibleFields.forEach((field, idx) => {
      field.idx = idx;
      visibleFieldsMap[field.property] = field;
    });
    // and re-sort them by the previous existing order
    return Object.values(visibleFieldsMap).sort((fieldA, fieldB) => fieldA.idx - fieldB.idx);
  }

  cancel() {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel.call();
    }
  }

  handleSubmit(markAsReviewed = false) {
    const {
      onSubmit,
      config,
      formObject,
      providers,
      touch,
      disableCommunicationFormOpen,
      enableCommunicationFormOpenPhone,
    } = this.props;

    /**
     * This isn't ideal but there's a bug where some fields don't get registered correctly
     * and have errors when submitting.
     * If there are any fields with errors touch each one of them (to make validation msgs appear)
     * and stop submission.
     */
    if (formObject.syncErrors && Object.keys(formObject.syncErrors).length) {
      Object.keys(formObject.syncErrors).forEach(errorKey => {
        touch(errorKey);
      });
      return Promise.resolve();
    }

    if (onSubmit && (!disableCommunicationFormOpen || enableCommunicationFormOpenPhone)) {
      return onSubmit(getResultValues(config, formObject, providers), markAsReviewed);
    }
    return Promise.resolve();
  }

  haveLinkedTaskIdsChanged(prevProps) {
    const { linkedTasks } = this.props;
    if (linkedTasks && prevProps.linkedTasks) {
      return !isEqual(linkedTasks.sort(), prevProps.linkedTasks.sort());
    }
    return false;
  }

  renderTitleOld(visibleFields) {
    const { title, formId, currentStatusName, classes } = this.props;
    return (
      <h2 className={classes.formTitle}>
        {title}
        {currentStatusName && visibleFields.find(it => it.property === 'status_id') && (
          <>
            <span className={classes.formTitleStatus}>Current status:</span>
            <span className={classes.formTitleCurrentStatus} id={`${formId}_status`}>
              {currentStatusName}
            </span>
          </>
        )}
      </h2>
    );
  }

  renderTitleHeader() {
    const { title, formId, currentStatusName, classes, dosisRegimenText } = this.props;
    return (
      <>
        <div className={classes.formTitleHeaderWrapper}>
          <div className={classes.formTitleHeaderWrapperItem1} data-qa-task-form-title>
            {title}
          </div>
          <div className={classes.formTitleHeaderWrapperItem2}>
            <div>Current status</div>
            <div id={`${formId}_status`}>
              <Chip
                data-qa-id="current-status-chip"
                color="primary"
                label={currentStatusName}
                className={classes.chip}
              />
            </div>
          </div>
        </div>
        {dosisRegimenText ? (
          <div className={classes.pillIcon} data-qa-id={qaIdBuilder('pill')}>
            <PillIcon />
            <div className={classes.dosisRegimenText}>
              <Typography className={classes.dosisRegimenText}>{dosisRegimenText}</Typography>
            </div>
          </div>
        ) : null}
        <Divider className={classes.formTitleHeaderWrapperDivider} />
      </>
    );
  }

  renderTitle(visibleFields) {
    const { title, currentStatusName, therapyLevel, classes } = this.props;
    if (currentStatusName && visibleFields.find(it => it.property === 'status_id')) {
      return this.renderTitleHeader();
    }
    if (therapyLevel) {
      return (
        <div className={classes.therapyLevelHeader}>
          <h3>{title}</h3>
        </div>
      );
    }
    return <FieldTitle title={title} />;
  }

  renderField(field) {
    const {
      providers,
      config,
      initialValues,
      currentStatusName,
      externalValues,
      formValues,
      readOnly,
      form,
      tagResourceId,
      tagTypeId,
      tagName,
      fileUploadFormId,
      taskId,
      fileFormName,
      notes,
      drawBorder,
      additionalDocumentProps,
      taskIsSubmitting,
      shouldShowCurrentStatusform,
    } = this.props;

    const visibleFields = this.getVisibleFields(externalValues, shouldShowCurrentStatusform);
    const extendedFormValues = { ...formValues };
    Object.keys(externalValues).forEach(v => {
      if (!visibleFields.find(it => it.property === v)) {
        extendedFormValues[v] = externalValues[v];
      }
    });
    if (
      !readOnly ||
      (readOnly && field.status.some(statusVar => statusVar === currentStatusName))
    ) {
      const providersInfo = field.skipProviders ? {} : providers;
      return (
        <JsonField
          key={field.id}
          initialValues={initialValues}
          providers={{
            ...providersInfo,
            formValues: extendedFormValues,
            config,
          }}
          field={field}
          formId={form}
          tagResourceId={tagResourceId}
          tagTypeId={tagTypeId}
          tagName={tagName}
          fileUploadFormId={fileUploadFormId}
          taskId={taskId}
          fileFormName={fileFormName}
          notes={notes}
          drawBorder={drawBorder}
          additionalDocumentProps={additionalDocumentProps}
          taskIsSubmitting={taskIsSubmitting}
        />
      );
    }

    return <div />;
  }

  // eslint-disable-next-line class-methods-use-this
  renderRow(children) {
    return (
      <Grid container item xs={12}>
        {children}
      </Grid>
    );
  }

  // render all fields inside the form
  renderFields(fields) {
    const renderedCompents = [];
    let rowBuffer = [];
    let onRow = false;
    // for rendering when ever finding row-start
    // render all fields inside a row until finding a row-end
    // this won't work with multiple levels
    fields.forEach(field => {
      // for compatibility just assume if type is not provided
      // that it's a field
      const { type = 'field' } = field;
      if (type === 'row-start' && !onRow) onRow = true;
      if (!['row-start', 'row-end'].includes(type) && !onRow) {
        renderedCompents.push(this.renderField(field));
      }
      if (!['row-start', 'row-end'].includes(type) && onRow) {
        rowBuffer.push(this.renderField(field));
      }
      if (type === 'row-end' && onRow) {
        if (rowBuffer.length > 0) renderedCompents.push(this.renderRow([...rowBuffer]));
        onRow = false;
        rowBuffer = [];
      }
    });

    return renderedCompents;
  }

  render() {
    const {
      onSubmit,
      handleSubmit,
      pristine,
      submitting,
      providers,
      title,
      className,
      externalValues,
      shouldShowCurrentStatusform,
      alwaysEnabled,
      valid,
      disableSubmit,
      disableSubmitIfInvalid,
      buttonIdPrefix,
      cancelButtonText,
      submitButtonText,
      submitAndReviewButtonText,
      buttons,
      onCancel,
      hideSubmitAndReview,
      disableSubmitAndReview,
      useHandleSubmit,
      disableCommunicationFormOpen,
      enableCommunicationFormOpenPhone,
      renderBeforeFields,
      dischargeReason,
      dischargeReasonDropdown,
      reason,
    } = this.props;
    const visibleFields = this.getVisibleFields(externalValues, shouldShowCurrentStatusform);
    if (!visibleFields.length) {
      return null;
    }
    const statusField = visibleFields.filter(field => field.id === 'status');
    const formFieldsWithoutStatus = visibleFields.filter(field => field.id !== 'status');

    const disableSaveButton =
      (alwaysEnabled !== undefined && !alwaysEnabled && pristine) ||
      (disableSubmitIfInvalid && !valid) ||
      submitting ||
      disableSubmit ||
      (disableCommunicationFormOpen !== undefined &&
        enableCommunicationFormOpenPhone !== undefined &&
        disableCommunicationFormOpen &&
        !enableCommunicationFormOpenPhone);

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off" className={className}>
        {title && this.renderTitle(visibleFields)}
        <Grid container>
          {buttons}
          {this.renderFields(statusField)}
          {renderBeforeFields !== undefined && renderBeforeFields()}
          {this.renderFields(formFieldsWithoutStatus)}
        </Grid>
        <SmallSpacer />
        {onSubmit && (
          <Grid container alignItems="center">
            {dischargeReason && (
              <Grid item xs={5}>
                {dischargeReasonDropdown}
              </Grid>
            )}
            <Grid item xs={dischargeReason ? 7 : 12}>
              <ConfirmationPanel
                cancelButtonName="edit_therapy_cancel_button"
                submitButtonName="edit_therapy_submit_button"
                submitAndReviewButtonName="edit_therapy_submit_and_review_button"
                cancelButtonText={cancelButtonText}
                submitButtonText={submitButtonText}
                submitAndReviewButtonText={submitAndReviewButtonText}
                handleCancel={() => this.cancel()}
                handleSubmit={useHandleSubmit ? () => this.handleSubmit(false) : null}
                handleSubmitAndReview={useHandleSubmit ? () => this.handleSubmit(true) : null}
                hideCancel={!onCancel}
                leftAlignedButtons={providers.leftAlignedButtons}
                disableSubmit={
                  !dischargeReason
                    ? disableSaveButton
                    : dischargeReason && reason === null
                    ? true
                    : disableSaveButton
                }
                hideSubmitAndReview={hideSubmitAndReview}
                disableSubmitAndReview={disableSubmitAndReview ? disableSubmitAndReview() : true}
                buttonIdPrefix={buttonIdPrefix}
              />
            </Grid>
          </Grid>
        )}
      </form>
    );
  }
}

function mapStateToProps(state, props) {
  const { config, data, providers, forceNextStatus, readOnly, formId } = props;
  const form = state ? state.form : null;
  const initialValues = getInitialValues(config, data, providers);
  let externalValues = {};
  if (props.linkedFormIds) {
    Object.keys(props.linkedFormIds).forEach(level => {
      const formId = props.linkedFormIds[level];
      const otherFormValues = getFormValues(formId)(state);
      if (otherFormValues) {
        const levelFields = getConfigFieldsByLevels(config, [level]);
        const importantValues = filterObjectFields(otherFormValues, levelFields, true);
        externalValues = {
          ...externalValues,
          ...importantValues,
        };
      }
    });
  }
  let currentStatusName = null;
  let shouldShowCurrentStatusform = true;

  if (!readOnly) {
    if (forceNextStatus) {
      const status = getStatusByStatusId(providers, initialValues.status_id);
      if (status) {
        const nextStatusJsonId = status.status.next;
        const nextStatus = nextStatusJsonId && getStatusByJsonId(providers, nextStatusJsonId);
        if (
          nextStatusJsonId &&
          nextStatus &&
          /*
           *  Currently only ar-provider have customStatusDisabledChecking func
           *  so if the function is not in the provider we need to return true
           *  otherwise call the fuction with params.
           */
          (!providers.customStatusDisabled || !providers.customStatusDisabled(nextStatus.name))
        ) {
          const nextStatusId = nextStatus.id;
          initialValues.status_id = nextStatusId;
        } else {
          shouldShowCurrentStatusform = false;
        }
        currentStatusName = status.status.name;
      }
    }
  } else {
    currentStatusName = getStatusByStatusId(providers, data.status_id)
      ? getStatusByStatusId(providers, data.status_id).status?.id
      : null;
  }
  const newFormValues = readOnly
    ? { ...initialValues, ...getFormValues(props.formId)(state) }
    : getFormValues(props.formId)(state);

  let filedsToBeDefaulted = [];
  if (
    props.taskFormIdValues &&
    Object.keys(props.taskFormIdValues).length &&
    props.taskFormIdValues.registeredFields &&
    Object.keys(props.taskFormIdValues.registeredFields).length
  ) {
    filedsToBeDefaulted = (Object.values(props.taskFormIdValues.registeredFields) || [])
      .filter(t => DEFAULTED_DATE_FIELDS.includes(t.name) && t.type === 'Field')
      .map(t => t.name);
  }

  const newFormValuesWithDefaults = newFormValues;

  if (newFormValues) {
    Object.keys(newFormValues).forEach(v => {
      if (!newFormValues[v] && !!initialValues[v] && filedsToBeDefaulted.includes(v)) {
        newFormValuesWithDefaults[v] = initialValues[v];
      }
    });
  }

  const disableCommunicationFormOpen = !!(form && form[COMMUNICATION_FORM]);
  const enableCommunicationFormOpenPhone = !(form && form[COMMUNICATION_FORM_PHONE_CALL]);

  const formValues = newFormValuesWithDefaults || {};

  const reduxFormValues = form && form[formId] ? form[formId] : {};
  return {
    form: props.formId,
    formObject: state && state.form && props.formId ? state.form[props.formId] : null,
    initialValues,
    formValues,
    externalValues,
    currentStatusName,
    shouldShowCurrentStatusform,
    enableReinitialize: !props.avoidReinitialize,
    keepDirtyOnReinitialize: !props.avoidReinitialize,
    disableCommunicationFormOpen,
    enableCommunicationFormOpenPhone,
    reduxFormValues,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps), reduxForm({}))(Form);
