import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Checkbox } from '@mui/material';
import compose from 'recompose/compose';
import DetailField from 'components/form/field/field';
import { Field, reduxForm, formValueSelector, change as _change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import {
  EDIT_FINANCIAL_ASSISTANCE_FORM,
  FINANCIAL,
  UPLOAD_DOCUMENT_FORM,
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
} from 'constants/index';
import { noteTagTypes } from 'constants/lists';
import { fetchFinancialAssistance, editFinancialAssistanceTask } from 'actions/action-financials';
import {
  validateDate,
  validateDollarValue,
  required,
  validateNumeric,
  length6,
  maxLength20,
  validateLessThanFiveDigitsValue,
} from 'components/form/validation/validation';
import { stripFormattedDollarValue } from 'services/utils/formatting';
import { convertToArborDate } from 'models/time/arbor-date';
import moment from 'moment';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import {
  renderDropdown,
  renderTextField,
  renderMoneyTextField,
  renderPhoneTextField,
} from 'components/form/field/redux-field';
import { getFileExtension, acceptedFileExtensions } from 'services/utils/document-service';
import { uploadFile, getDocuments } from 'services/utils/upload-document-service';
import { SmallSpacer } from 'components/spacer/spacer';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { styles } from '../../task-detail-styles';
import WrappedFileUpload from '../../../patient/financials/wrapped-file-upload';
import DocumentConfirmationDialog from '../../../patient/financials/document-confirmation-dialog';
import {
  FinancialAssistanceSubTypes,
  FinancialAssistanceTypes,
  FinancialAssistanceFieldsEnum,
} from '../../../../constants/enums';
import { FinancialAssistanceStatus } from '../fa-utils';

const taskTypeName = FINANCIAL;
const tagType = noteTagTypes.find(tag => tag.label === taskTypeName);

class EditFoundation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: FinancialAssistanceStatus.Active,
      therapyId: null,
      // Empty string  means user selected there is no group and based it that way on DB
      noGroupIdCheckbox: props.initialValues.group_id === '',
      noPCNCheckbox: props.initialValues.pcn === '',
      // upload file props
      pendingDocument: undefined,
      uploadedDocuments: [],
      uploadProcessing: false,
      pendingDocumentLoss: false,
      lostData: [],
      dialogCancel: undefined,
      dialogContinue: undefined,
      foundationType: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGroupIdCheckbox = this.handleGroupIdCheckbox.bind(this);
    this.handleNoPCNCheckbox = this.handleNoPCNCheckbox.bind(this);

    // upload file
    this.handleFileUploadCancel = this.handleFileUploadCancel.bind(this);
    this.handleFileUploadSubmit = this.handleFileUploadSubmit.bind(this);
    this.fileSelected = this.fileSelected.bind(this);
    // show confirmationDialog
    this.handleDialogContinue = this.handleDialogContinue.bind(this);
    this.handleDialogCancel = this.handleDialogCancel.bind(this);
  }

  componentDidMount() {
    const { financialAssistance } = this.props;
  }

  componentDidUpdate(prevProps, prevState) {
    const { fileFormName } = this.props;
    if (prevState.pendingDocument && prevState.pendingDocument.fileName !== fileFormName) {
      this.onPendingDocumentFileNameChange(fileFormName);
    }
  }

  onPendingDocumentFileNameChange(newFileName) {
    this.setState(prevState => ({
      pendingDocument: {
        ...prevState.pendingDocument,
        ...{ fileName: newFileName },
      },
    }));
  }

  // Upload file
  handleFileUploadCancel() {
    this.setState({ pendingDocument: undefined });
  }

  handleFileUploadSubmit() {
    this.setState({ uploadProcessing: true });
    const { pendingDocument } = this.state;
    const {
      selectedCustomerId,
      selectedPatientId,
      fileFormName,
      fileFormTypes,
      fileFormNote,
      financialAssistance,
    } = this.props;

    if (pendingDocument) {
      uploadFile(
        pendingDocument.file,
        `${pendingDocument.fileName}.${pendingDocument.fileExtension}`,
        selectedCustomerId,
        selectedPatientId,
      )
        .then(result => {
          this.setState(prevState => ({
            uploadedDocuments: [
              ...prevState.uploadedDocuments,
              {
                uuid: result.uuid,
                taskTypeId: tagType.value,
                taskIds: [financialAssistance.id],
                filename: `${fileFormName}.${pendingDocument.fileExtension}`,
                labels: fileFormTypes.split(','),
                note: fileFormNote,
              },
            ],
            pendingDocument: undefined,
            uploadProcessing: false,
          }));
        })
        .catch(() => {
          this.setState({
            fileUploadErrorMessage: 'File could not be uploaded. Try again.',
          });
        });
    }
  }

  fileSelected(event) {
    if (event && event.length > 0) {
      const selectedFile = event[0];
      const { filePrefix, fileExtension } = getFileExtension(selectedFile.name);
      if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
        const { changeField, fileUploadFormId } = this.props;
        changeField(fileUploadFormId, UPLOAD_DOC_FILENAME_FIELD, filePrefix);

        this.setState({
          pendingDocument: {
            fileName: filePrefix,
            fileExtension,
            file: selectedFile,
          },
        });
      }
    }
  }

  handleDialogContinue(values) {
    const { pendingDocument } = this.state;
    if (pendingDocument) {
      const lostData = [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.handleSubmit(values);
        },
      });
    } else {
      this.handleSubmit(values);
    }
  }

  handleDialogCancel() {
    const { uploadedDocuments, pendingDocument } = this.state;
    const { cancel } = this.props;
    if ((uploadedDocuments && uploadedDocuments.length > 0) || pendingDocument) {
      const lostData = uploadedDocuments.map(ud => `Uploaded: ${ud.filename}`) || [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          cancel();
        },
      });
    } else {
      cancel();
    }
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editFinancialAssistanceTask, // eslint-disable-line
      reset,
      cancel,
      financialAssistance,
      existingDocuments,
      addNewDocument, // eslint-disable-line
    } = this.props;
    const { noGroupIdCheckbox, noPCNCheckbox, uploadedDocuments } = this.state;

    const payload = {
      ...values,
      id: financialAssistance.id,
      patient_id: financialAssistance.patient_id,
      therapy_id: financialAssistance.therapy_id,
      task_fa_id: financialAssistance.task_fa_id,
      status: values.status || values.status === 0 ? values.status : '',
      expiration_date: values.expiration_date
        ? convertToArborDate(values.expiration_date).getUtcDatetime()
        : null,
      group_id: noGroupIdCheckbox ? '' : values.group_id,
      pcn: noPCNCheckbox ? '' : values.pcn,
      award_amount: values.award_amount ? stripFormattedDollarValue(values.award_amount) : '',
      foundation_name: values.foundation_name ? values.foundation_name : '',
      foundation_program: values.foundation_program ? values.foundation_program : '',
      spend_down: values.spend_down ? stripFormattedDollarValue(values.spend_down) : '',
      remaining_balance: values.remaining_balance
        ? stripFormattedDollarValue(values.remaining_balance)
        : '',
      phone_number: values.phone_number ? values.phone_number : '',
      bin: values.bin ? values.bin : null,
      member_id: values.member_id || null,
      copay: values.copay ? stripFormattedDollarValue(values.copay) : null,
      documents: uploadedDocuments.map(doc => ({
        uuid: doc.uuid,
        file_name: doc.filename,
        labels: doc.labels,
        tags: doc.taskIds.map(id => ({
          tag_type_id: tagType.value,
          resource_id: id,
        })),
        noteText: doc.note,
      })),
      financial_assistance_type: values.foundation_type,
    };

    editFinancialAssistanceTask(payload);

    if (uploadedDocuments.length) {
      getDocuments({
        patient_id: financialAssistance.patient_id,
        fetchFromId: Math.max(...existingDocuments.map(d => d.id)),
      }).then(result => {
        addNewDocument(result.data.documents);
      });
    }

    cancel();
    reset();
  }

  handleGroupIdCheckbox() {
    this.setState(prevState => ({
      noGroupIdCheckbox: !prevState.noGroupIdCheckbox,
    }));
  }

  handleNoPCNCheckbox() {
    this.setState(prevState => ({
      noPCNCheckbox: !prevState.noPCNCheckbox,
    }));
  }

  render() {
    const {
      submitting,
      classes,
      addingFromFinancials,
      therapies,
      financialAssistance,
      fileUploadFormId,
      defaultDocumentLabels,
      pristine,
      handleSubmit,
      showDocumentForm,
    } = this.props;

    let { fieldsToShow } = this.props;

    if (!fieldsToShow?.length) {
      fieldsToShow = [
        FinancialAssistanceFieldsEnum.AwardAmount,
        FinancialAssistanceFieldsEnum.ExpirationDate,
        FinancialAssistanceFieldsEnum.SpendDownAmount,
        FinancialAssistanceFieldsEnum.RemainingBalance,
        FinancialAssistanceFieldsEnum.PhoneNumber,
        FinancialAssistanceFieldsEnum.ProgramName,
        FinancialAssistanceFieldsEnum.Bin,
        FinancialAssistanceFieldsEnum.Pcn,
        FinancialAssistanceFieldsEnum.MemberId,
        FinancialAssistanceFieldsEnum.GroupId,
        FinancialAssistanceFieldsEnum.Copay,
      ];
    }

    const {
      status,
      therapyId,
      noGroupIdCheckbox,
      noPCNCheckbox,
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      lostData,
      dialogCancel,
      dialogContinue,
      foundationType,
    } = this.state;

    const isPharmacyBenefit =
      financialAssistance.financial_assistance_type === 3001 ||
      financialAssistance.financial_assistance_type === 3004 ||
      financialAssistance.financial_assistance_type === 3007;
    const isCopay = [
      FinancialAssistanceSubTypes.CopayCard_Check,
      FinancialAssistanceSubTypes.CopayCard_CreditCard,
      FinancialAssistanceSubTypes.CopayCard_PharmacyBenefit,
    ].includes(financialAssistance.financial_assistance_type);
    const isInternalFoundation = () =>
      FinancialAssistanceTypes.InternalFoundation === this.props.initialFoundationTypeCategory;

    const uploadProps = {
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      classes,
      fileUploadFormId,
      defaultDocumentLabels,
      handleFileUploadSubmit: this.handleFileUploadSubmit,
      handleFileUploadCancel: this.handleFileUploadCancel,
      fileSelected: this.fileSelected,
    };

    const documentConfirmationDialogProps = {
      lostData,
      dialogCancel,
      dialogContinue,
      pendingDocumentLoss,
    };
    const buildOptionsForSelectCategory = foundationTypeId => {
      const internalFoundationMap = {
        3001: 3004,
        3002: 3005,
        3003: 3006,
      };
      const externalFoundationMap = {
        3001: 3007,
        3002: 3008,
        3003: 3009,
      };
      return [
        { value: foundationTypeId, label: FinancialAssistanceTypes.Foundation },
        {
          value: internalFoundationMap[foundationTypeId],
          label: FinancialAssistanceTypes.InternalFoundation,
        },
        {
          value: externalFoundationMap[foundationTypeId],
          label: FinancialAssistanceTypes.ExternalFoundation,
        },
      ];
    };
    return (
      <Grid container justifyContent="center" spacing={7}>
        <Grid item xs={12}>
          <DocumentConfirmationDialog {...documentConfirmationDialogProps} />
          <form className={classes.addFormContainer} autoComplete="off">
            <Grid container>
              {addingFromFinancials && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="therapy_id"
                    label="Therapy *"
                    validate={[required]}
                    component={renderDropdown}
                    fields={Object.values(therapies).map(t => ({
                      label: t.drug_name,
                      value: t.id,
                    }))}
                    state={therapyId}
                  />
                </Grid>
              )}
              {FinancialAssistanceTypes.Foundation === this.props.initialFoundationTypeCategory && (
                <Grid item xs={12} className={classes.fieldContainer}>
                  <Field
                    name="foundation_type"
                    label="Foundation Type *"
                    component={renderDropdown}
                    fields={buildOptionsForSelectCategory(
                      this.props.financialAssistance.financial_assistance_type,
                    )}
                    state={foundationType}
                    isOptionDisabled={option =>
                      option.label === FinancialAssistanceTypes.Foundation
                    }
                  />
                </Grid>
              )}
              {isCopay && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="foundation_name"
                    label="Foundation Name *"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
              )}
              {fieldsToShow.includes(FinancialAssistanceFieldsEnum.AwardAmount) &&
                isInternalFoundation() && (
                  <Grid item xs={3} className={classes.fieldContainer}>
                    <Field
                      name="award_amount"
                      label="Award Amount"
                      component={renderMoneyTextField}
                      validate={[validateDollarValue, validateLessThanFiveDigitsValue]}
                    />
                  </Grid>
                )}
              {fieldsToShow.includes(FinancialAssistanceFieldsEnum.AwardAmount) &&
                !isInternalFoundation() && (
                  <Grid item xs={3} className={classes.fieldContainer}>
                    <Field
                      name="award_amount"
                      label="Award Amount *"
                      component={renderMoneyTextField}
                      validate={[required, validateDollarValue, validateLessThanFiveDigitsValue]}
                    />
                  </Grid>
                )}

              {fieldsToShow.includes(FinancialAssistanceFieldsEnum.ExpirationDate) && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="expiration_date"
                    label="Expiration Date *"
                    component={renderDatePicker}
                    validate={[validateDate, required]}
                  />
                </Grid>
              )}
              {fieldsToShow.includes(FinancialAssistanceFieldsEnum.SpendDownAmount) && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="spend_down"
                    label="Spend Down Amount"
                    component={renderMoneyTextField}
                    validate={[validateDollarValue]}
                  />
                </Grid>
              )}
              {fieldsToShow.includes(FinancialAssistanceFieldsEnum.RemainingBalance) && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="remaining_balance"
                    label="Remaining Balance"
                    component={renderMoneyTextField}
                    validate={[validateDollarValue]}
                  />
                </Grid>
              )}
              {fieldsToShow.includes(FinancialAssistanceFieldsEnum.PhoneNumber) && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="phone_number"
                    label="Phone Number *"
                    component={renderPhoneTextField}
                    validate={[required]}
                  />
                </Grid>
              )}
              {fieldsToShow.includes(FinancialAssistanceFieldsEnum.ProgramName) && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="foundation_program"
                    label="Program Name *"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
              )}
              {fieldsToShow.includes(FinancialAssistanceFieldsEnum.Bin) && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="bin"
                    label="BIN *"
                    component={renderTextField}
                    validate={[required, validateNumeric, length6]}
                  />
                </Grid>
              )}
              {isPharmacyBenefit && fieldsToShow.includes(FinancialAssistanceFieldsEnum.Pcn) && (
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={6} className={classes.fieldContainer}>
                      <Field
                        name="pcn"
                        label={!noPCNCheckbox ? 'PCN *' : 'PCN'}
                        disabled={noPCNCheckbox}
                        component={renderTextField}
                        validate={!noPCNCheckbox ? [required, maxLength20] : null}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container alignItems="center" justifyContent="flex-start">
                        <Checkbox
                          checked={noPCNCheckbox}
                          color="primary"
                          onChange={this.handleNoPCNCheckbox}
                          value="checkedPCN"
                          label="Secondary"
                        />
                        <DetailField defaultValue="No PCN" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isPharmacyBenefit &&
                fieldsToShow.includes(FinancialAssistanceFieldsEnum.MemberId) && (
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Field
                      name="member_id"
                      label="Member ID *"
                      component={renderTextField}
                      validate={[required]}
                    />
                  </Grid>
                )}
              {isPharmacyBenefit &&
                fieldsToShow.includes(FinancialAssistanceFieldsEnum.GroupId) && (
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6} className={classes.fieldContainer}>
                        <Field
                          name="group_id"
                          label={!noGroupIdCheckbox ? 'Group ID *' : 'Group ID'}
                          validate={!noGroupIdCheckbox ? [required] : null}
                          component={renderTextField}
                          disabled={noGroupIdCheckbox}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems="center" justifyContent="flex-start">
                          <Checkbox
                            checked={noGroupIdCheckbox}
                            color="primary"
                            onChange={this.handleGroupIdCheckbox}
                            value="checkedA"
                            label="Secondary"
                          />
                          <DetailField defaultValue="No Group Id" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              {!isPharmacyBenefit &&
                fieldsToShow.includes(FinancialAssistanceFieldsEnum.MemberId) && (
                  <Grid item xs={6} className={classes.fieldContainer}>
                    <Field name="member_id" component={renderTextField} label="Member ID" />
                  </Grid>
                )}
              {fieldsToShow.includes(FinancialAssistanceFieldsEnum.Copay) && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="copay"
                    label="Copay"
                    component={renderMoneyTextField}
                    decimalScale={2}
                    fixedDecimalScale
                    decimalSeparator="."
                  />
                </Grid>
              )}
            </Grid>
            {showDocumentForm && <WrappedFileUpload {...uploadProps} />}
            <SmallSpacer />
            <ConfirmationPanel
              cancelButtonName="add_task_cancel_button"
              submitButtonName="add_task_submit_button"
              handleSubmit={handleSubmit(this.handleDialogContinue)}
              handleCancel={this.handleDialogCancel}
              disableSubmit={
                submitting ||
                (pristine && !pendingDocument && !uploadedDocuments.length) ||
                [3001, 3002, 3003].includes(foundationType)
              }
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(id) {
  const fileFormId = `${UPLOAD_DOCUMENT_FORM}-${tagType.label}-${id}`;
  return fileFormId;
}

function mapStateToProps(state, props) {
  const { therapies, uploadedDocuments } = state;
  const { financialAssistance } = props;
  const fileUploadFormId = handleFormName(financialAssistance.id);
  const selector = formValueSelector(fileUploadFormId);
  const fileFormName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileFormTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileFormNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);

  return {
    therapies: therapies.data,
    initialValues: {
      award_amount: props.financialAssistance.award_amount
        ? props.financialAssistance.award_amount
        : '',
      status:
        props.financialAssistance.status ||
        props.financialAssistance.status === FinancialAssistanceStatus.Inactive
          ? props.financialAssistance.status
          : FinancialAssistanceStatus.Active,
      expiration_date: props.financialAssistance.expiration_date
        ? moment.utc(props.financialAssistance.expiration_date)
        : null,
      foundation_name: props.financialAssistance.foundation_name
        ? props.financialAssistance.foundation_name
        : '',
      foundation_program: props.financialAssistance.program_name
        ? props.financialAssistance.program_name
        : '',
      spend_down: props.financialAssistance.spend_down ? props.financialAssistance.spend_down : '',
      remaining_balance: props.financialAssistance.remaining_balance
        ? props.financialAssistance.remaining_balance
        : '',
      phone_number: props.financialAssistance.phone_number
        ? props.financialAssistance.phone_number
        : '',
      group_id: ![undefined].includes(props.financialAssistance.group_id)
        ? props.financialAssistance.group_id
        : '',
      pcn: ![undefined].includes(props.financialAssistance.pcn)
        ? props.financialAssistance.pcn
        : '',
      bin: props.financialAssistance.bin ? props.financialAssistance.bin : '',
      member_id: props.financialAssistance.member_id ? props.financialAssistance.member_id : null,
      copay: props.financialAssistance.copay,
      foundation_type: props.financialAssistance.financial_assistance_type,
    },
    initialFoundationTypeCategory: props.financialAssistance.financial_assistance_type_category,
    defaultDocumentLabels: state.lookups.defaultDocumentLabels,
    fileUploadFormId,
    fileFormName,
    fileFormTypes,
    fileFormNote,
    selectedCustomerId: state.filters.selectedCustomerId,
    selectedPatientId: financialAssistance.patient_id,
    existingDocuments: uploadedDocuments.documents,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchFinancialAssistance,
      editFinancialAssistanceTask,
      changeField: (formName, fieldName, value) => dispatch(_change(formName, fieldName, value)),
      addNewDocument,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({ form: EDIT_FINANCIAL_ASSISTANCE_FORM })(EditFoundation));
