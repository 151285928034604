import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Checkbox,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WithStyles } from '@mui/styles';
import cx from 'classnames';
import DetailField from 'components/form/field/field';
import { combineStyles } from 'services/utils/styles-service';
import { accordianStyles } from 'components/accordian/accordian-styles';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';
import { useDebounce } from 'use-debounce';
import { useDispatch } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IHeaderCell } from 'containers/application-manager/base-table/types';
import { UserUtils } from 'utils/user-utils';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { UserPermissions } from 'interfaces/user/Permissions';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { Field, InjectedFormProps, change, reduxForm } from 'redux-form';
import {
  renderDropdown,
  renderReactRadioGroup,
  renderTextField,
} from 'components/form/field/redux-field';
import { required } from 'components/form/validation/validation';
import compose from 'recompose/compose';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { fetchGpis, specialtyTypeFormatter } from 'services/utils/therapy-service';
import { Timezone } from 'models/time/timezone';
import momentBD from 'moment-business-days';
import {
  IResponse,
  IResult,
  IResultChildren,
  mapProtocolsToTableResult,
  IResultTable,
  IProtocolData,
} from './data-table';
import { CpmpStyles } from '../index.styles';
import { IAdvancedSearchForm, IDiseaseStateList } from './types';

import { SectionTitleStyled } from '../../components/section-title';
import { SearchField } from '../../components/search-field';
import { IClinicalItemProtocolFormModalRef, ClinicalItemProtocolMainModal } from './form-modal';
import { EfficacyProtocolComponent } from './efficacy-protocol';
import { ADVANCED_SEARCH_FORM, ProtocolTypeList } from './constants';

const searchFieldPlaceholder = 'Search by Clinical item or Category';
const initialSearchTerm = '';
const debounceTimeMs = 1000;

type ICpmpProps = WithStyles<typeof CpmpStyles>;
type OnClickCb = () => void;
type OnClickCbBuilder = (protocol: any) => OnClickCb;

const logicOperatorsOptions: { label: string; value: number }[] = [
  { label: 'And', value: 1 },
  { label: 'Or', value: 0 },
];

const protocolStatus: { label: string; value: number; selected?: boolean }[] = [
  { label: 'All', value: 2, selected: true },
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

const asDynamicFields = [
  { fieldName: 'as_disease', defaultValue: [] },
  { fieldName: 'as_icd10', defaultValue: '' },
  {
    fieldName: 'as_efficacy_protocol_status',
    defaultValue: protocolStatus.find(t => t.selected)?.value,
  },
  { fieldName: 'as_brand_name', defaultValue: [] },
  { fieldName: 'as_generic_name', defaultValue: '' },
  { fieldName: 'as_gpi_10', defaultValue: '' },
  {
    fieldName: 'as_safety_protocol_status',
    defaultValue: protocolStatus.find(t => t.selected)?.value,
  },
];

type IFormProps = ICpmpProps & InjectedFormProps<IAdvancedSearchForm>;

const CpmpClinicalItemProtocol = (props: IFormProps): JSX.Element => {
  const { classes, handleSubmit } = props;
  const dispatch = useDispatch();
  const user = useTypedSelector(state => state.auth.currentUser);

  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [reloadTableTrigger, setReloadTableTrigger] = React.useState<Date | undefined>(undefined);
  const [protocol, setProtocol] = React.useState<any | undefined>(undefined);
  const [protocolType, setProtocolType] = React.useState<string | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const [protocolsData, setProtocolsData] = React.useState<IProtocolData | undefined>(undefined);
  const [advancedSearch, setAdvancedSearch] = React.useState<boolean>(false);
  const [asdiseaseStateData, setAsdiseaseStateData] = React.useState<IDiseaseStateList[]>([]);
  const [efficacyCheckbox, setEfficacyCheckbox] = React.useState<boolean>(true);
  const [safetyCheckbox, setSafetyCheckbox] = React.useState<boolean>(true);
  const DataTableContext = React.createContext(protocolsData);

  const userHasPermissionToAddEditCip = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerCpmpCipEdit);
  }, [user]);

  const addFormModalRef = React.useRef<any>(null);
  const editFormModalRef = React.useRef<any>(null);

  const canEdit = userHasPermissionToAddEditCip;
  const url = 'application-manager/cpmp/clinical-item-protocol';
  const customerTimezone = Timezone.getInstance().timezone;

  const getSafetyAndEfficacyProtocolData = (response: IResult[]): IResultTable[] => {
    return response.map(r => mapProtocolsToTableResult(r));
  };

  const getOnEditCellClick = (clickedProtocol: any, proType: string) => () => {
    setProtocol(clickedProtocol);
    setProtocolType(proType);
    addFormModalRef.current?.setOpen(true);
  };
  const EditCell = (props: any & { onClickBuilder: OnClickCbBuilder }) => {
    const { onClickBuilder, ...protocol } = props;

    return <EditIcon fontSize="small" onClick={onClickBuilder(protocol)} />;
  };

  const buildRendererEditCell = (onClickBuilder: OnClickCbBuilder) => (props: IResult) =>
    <EditCell {...props} onClickBuilder={onClickBuilder} />;

  const COLUMN_SETTINGS: IHeaderCell<IResult, IResultChildren>[] = [
    {
      label: 'Clinical Item',
      parentKey: 'clinical_item_name',
      sortable: true,
      showParentTooltip: cellContent => cellContent != null && cellContent.length > 25,
      serverSideSearchField: 'clinical_item_name',
      hideLeftPadding: true,
      childColSpan: 5,
      childComponent: (parent: IResult, child: IResultChildren) => {
        const safetyProtocols =
          child && child.safetyProtocols && child.safetyProtocols.length > 0
            ? child.safetyProtocols
            : [];

        const efficacyProtocols =
          child && child.efficacyProtocols && child.efficacyProtocols.length > 0
            ? child.efficacyProtocols
            : [];
        return (
          <div>
            {efficacyProtocols && efficacyProtocols.length > 0 && (
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} className={canEdit ? classes.dataCellToEdit : classes.dataCell}>
                  <Typography className={classes.contentTitle}>Efficacy Protocol:</Typography>
                  {efficacyProtocols.map(efficacyProtocol => {
                    const efficacyProtocolsIcdCode = efficacyProtocol.icdCodes
                      ? efficacyProtocol.icdCodes.join(',')
                      : '';
                    return (
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={canEdit ? 11 : 12}>
                          <Accordion
                            sx={{
                              backgroundColor: 'transparent',
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              {efficacyProtocolsIcdCode.length > 120 ? (
                                <Typography>
                                  <Tooltip title={efficacyProtocolsIcdCode} placement="right-end">
                                    <span>
                                      {`${
                                        efficacyProtocol.specialty_type_name
                                      } (${efficacyProtocolsIcdCode.substring(0, 120)} ...)`}
                                    </span>
                                  </Tooltip>
                                </Typography>
                              ) : (
                                <Typography>{`${efficacyProtocol.specialty_type_name} (${efficacyProtocolsIcdCode})`}</Typography>
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid item xs={4}>
                                  <DetailField
                                    fieldName="Intervention Criteria"
                                    field={
                                      !efficacyProtocol.consecutive_intervention_score
                                        ? `${efficacyProtocol.clinical_item_name} ${
                                            efficacyProtocol.clinical_data_operator &&
                                            efficacyProtocol.clinical_data_value
                                              ? `${efficacyProtocol.clinical_data_operator} ${efficacyProtocol.clinical_data_value}`
                                              : `in [${efficacyProtocol.options}]`
                                          }`
                                        : '-'
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <DetailField
                                    fieldName="Consecutive Intervention Scores"
                                    field={
                                      efficacyProtocol.consecutive_intervention_score
                                        ? `${efficacyProtocol.consecutive_value} ${
                                            efficacyProtocol.consecutive_type_id === 1
                                              ? 'Increasing'
                                              : 'Decreasing'
                                          }`
                                        : '-'
                                    }
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <DetailField
                                    fieldName="Protocol Status"
                                    field={efficacyProtocol.status ? 'Active' : 'Inactive'}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <DetailField
                                    fieldName="Effective Date"
                                    field={momentBD
                                      .utc(efficacyProtocol.effective_date)
                                      .tz(customerTimezone, true)
                                      .startOf('day')
                                      .format('MM/DD/YYYY')}
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <br />
                        </Grid>
                        {canEdit && (
                          <Tooltip title="Edit Efficacy Protocols" placement="left">
                            <Grid
                              container
                              xs={1}
                              direction="row"
                              justifyContent="center"
                              className={classes.editCell}
                              alignItems="center"
                              onClick={getOnEditCellClick(
                                efficacyProtocol,
                                ProtocolTypeList.Efficacy,
                              )}
                            >
                              <EditIcon fontSize="small" />
                            </Grid>
                          </Tooltip>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {safetyProtocols && safetyProtocols.length > 0 && (
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} className={canEdit ? classes.dataCellToEdit : classes.dataCell}>
                  <Typography className={classes.contentTitle}>Safety Protocol:</Typography>
                  {safetyProtocols.map(safetyProtocol => {
                    const safetyProtocolDrugs = safetyProtocol?.associatedDrugs || [];
                    const safetyProtocolBrandName = safetyProtocolDrugs.find(
                      d => d.brand_name_code === 'T',
                    )
                      ? safetyProtocolDrugs
                          .filter(d => d.brand_name_code === 'T')
                          .map(d => d.drug_name)
                          .join(',')
                      : '';
                    const safetyProtocolGeneric = safetyProtocolDrugs.find(
                      d => d.brand_name_code === 'G',
                    )
                      ? safetyProtocolDrugs
                          .filter(d => d.brand_name_code === 'G')
                          .map(d => d.drug_name)
                          .join(',')
                      : '';
                    return (
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={canEdit ? 11 : 12}>
                          <Accordion
                            sx={{
                              backgroundColor: 'transparent',
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              {safetyProtocolBrandName.length > 60 ? (
                                <Typography>
                                  <Tooltip
                                    title={`${safetyProtocolBrandName} (${safetyProtocolGeneric} - ${safetyProtocol.gpi_10})`}
                                    placement="right-end"
                                  >
                                    <span>
                                      {`${safetyProtocolBrandName.substring(
                                        0,
                                        60,
                                      )} ... (${safetyProtocolGeneric.substring(0, 60)} - ${
                                        safetyProtocol.gpi_10
                                      })`}{' '}
                                    </span>
                                  </Tooltip>
                                </Typography>
                              ) : (
                                <Typography>{`${safetyProtocolBrandName} (${safetyProtocolGeneric} - ${safetyProtocol.gpi_10})`}</Typography>
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid item xs={4}>
                                  <DetailField
                                    fieldName="Intervention Criteria"
                                    field={
                                      !safetyProtocol.consecutive_intervention_score
                                        ? `${safetyProtocol.clinical_item_name} ${
                                            safetyProtocol.clinical_data_operator &&
                                            safetyProtocol.clinical_data_value
                                              ? `${safetyProtocol.clinical_data_operator} ${safetyProtocol.clinical_data_value}`
                                              : `in [${safetyProtocol.options}]`
                                          }`
                                        : '-'
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <DetailField
                                    fieldName="Consecutive Intervention Scores"
                                    field={
                                      safetyProtocol.consecutive_intervention_score
                                        ? `${safetyProtocol.consecutive_value} ${
                                            safetyProtocol.consecutive_type_id === 1
                                              ? 'Increasing'
                                              : 'Decreasing'
                                          }`
                                        : '-'
                                    }
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <DetailField
                                    fieldName="Protocol Status"
                                    field={
                                      safetyProtocol &&
                                      safetyProtocol.clinical_item_category_name &&
                                      safetyProtocol.status
                                        ? 'Active'
                                        : 'Inactive'
                                    }
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <DetailField
                                    fieldName="Effective Date"
                                    field={momentBD
                                      .utc(safetyProtocol.effective_date)
                                      .tz(customerTimezone, true)
                                      .startOf('day')
                                      .format('MM/DD/YYYY')}
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                          <br />
                        </Grid>
                        {canEdit && (
                          <Tooltip title="Edit Safety Protocols" placement="left">
                            <Grid
                              container
                              xs={1}
                              direction="row"
                              justifyContent="center"
                              className={classes.editCell}
                              alignItems="center"
                              onClick={getOnEditCellClick(safetyProtocol, ProtocolTypeList.Safety)}
                            >
                              <EditIcon fontSize="small" />
                            </Grid>
                          </Tooltip>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </div>
        );
      },
    },
    {
      label: 'Clinical Category',
      parentKey: 'clinical_item_category_name',
      serverSideSearchField: 'clinical_item_category_name',
      sortable: true,
      showParentTooltip: cellContent => cellContent != null && cellContent.length > 25,
      hideLeftPadding: true,
      hideChildCol: true,
    },
    {
      label: 'Efficacy Protocol',
      parentKey: 'efficacyProtocolsId',
      showParentTooltip: cellContent => cellContent != null && cellContent.length > 25,
      hideLeftPadding: true,
      sortable: false,
      hideChildCol: true,
    },
    {
      label: 'Safety Protocol',
      parentKey: 'safetyProtocolsId',
      showParentTooltip: cellContent => cellContent != null && cellContent.length > 25,
      sortable: true,
      hideLeftPadding: true,
      hideChildCol: true,
    },
  ];

  if (canEdit) {
    COLUMN_SETTINGS.push({
      label: '',
      sortable: false,
      hideLeftPadding: true,
      // parentComponent: buildRendererEditCell(getOnEditCellClick),
    });
  }

  const renderAddClinicalItemProtocolButton = () => (
    <Button
      variant="contained"
      className={classes.addButton}
      onClick={() => {
        setProtocolType(null);
        setProtocol(undefined);
        addFormModalRef.current?.setOpen(true);
      }}
    >
      Add Clinical Item Protocol
    </Button>
  );

  const onProtocolFormSuccess = () => {
    setReloadTrigger(new Date());
  };

  const onSubmit = (formValues: Partial<IAdvancedSearchForm>) => {
    const efficacyParameters: {
      specialty_type_id?: number | undefined;
      status: number | undefined;
    } = {
      specialty_type_id:
        formValues?.as_disease && !Array.isArray(formValues.as_disease)
          ? formValues.as_disease
          : undefined,
      status: formValues.as_efficacy_protocol_status ?? undefined,
    };
    const safetyParameters: { gpi?: string | undefined; status: number | undefined } = {
      gpi:
        formValues?.as_gpi_10 && !Array.isArray(formValues.as_gpi_10)
          ? formValues.as_gpi_10
          : undefined,
      status: formValues.as_safety_protocol_status ?? undefined,
    };
    if (protocolsData?.fullData && protocolsData.fullData.length) {
      const filteredData = protocolsData.fullData.reduce((acc, item) => {
        const tempEfficacyProtocols = item.efficacyProtocols;
        const tempSafetyProtocols = item.safetyProtocols;
        const filteredTempEfficacyProtocol = [];
        const filteredTempSafetyProtocol = [];
        if (efficacyCheckbox) {
          for (const efficacyRow of tempEfficacyProtocols) {
            if (efficacyParameters.specialty_type_id) {
              if (efficacyRow.specialty_type_id === efficacyParameters.specialty_type_id) {
                if (efficacyParameters.status === 2) {
                  filteredTempEfficacyProtocol.push(efficacyRow);
                } else if (Number(efficacyRow.status) === Number(efficacyParameters.status)) {
                  filteredTempEfficacyProtocol.push(efficacyRow);
                }
              }
            } else if (efficacyParameters.status === 2) {
              filteredTempEfficacyProtocol.push(efficacyRow);
            } else if (Number(efficacyRow.status) === Number(efficacyParameters.status)) {
              filteredTempEfficacyProtocol.push(efficacyRow);
            }
          }
        }
        if (safetyCheckbox) {
          for (const safetyRow of tempSafetyProtocols) {
            if (safetyParameters.gpi) {
              if (safetyRow.gpi_10 === safetyParameters.gpi) {
                if (safetyParameters.status === 2) {
                  filteredTempSafetyProtocol.push(safetyRow);
                } else if (Number(safetyRow.status) === safetyParameters.status) {
                  filteredTempSafetyProtocol.push(safetyRow);
                }
              }
            } else if (safetyParameters.status === 2) {
              filteredTempSafetyProtocol.push(safetyRow);
            } else if (Number(safetyRow.status) === safetyParameters.status) {
              filteredTempSafetyProtocol.push(safetyRow);
            }
          }
        }
        if (
          filteredTempEfficacyProtocol &&
          filteredTempEfficacyProtocol.length &&
          filteredTempSafetyProtocol &&
          filteredTempSafetyProtocol.length
        ) {
          acc.push({
            ...item,
            efficacyProtocols: filteredTempEfficacyProtocol,
            efficacyProtocolsId: `${filteredTempEfficacyProtocol.length}`,
            safetyProtocols: filteredTempSafetyProtocol,
            safetyProtocolsId: `${filteredTempSafetyProtocol.length}`,
            children: [
              {
                efficacyProtocols: filteredTempEfficacyProtocol,
                safetyProtocols: filteredTempSafetyProtocol,
              },
            ],
          });
        } else if (filteredTempEfficacyProtocol && filteredTempEfficacyProtocol.length) {
          acc.push({
            ...item,
            efficacyProtocols: filteredTempEfficacyProtocol,
            efficacyProtocolsId: `${filteredTempEfficacyProtocol.length}`,
            safetyProtocols: [],
            safetyProtocolsId: '-',
            children: [
              {
                efficacyProtocols: filteredTempEfficacyProtocol,
                safetyProtocols: [],
              },
            ],
          });
        } else if (filteredTempSafetyProtocol && filteredTempSafetyProtocol.length) {
          acc.push({
            ...item,
            efficacyProtocols: [],
            efficacyProtocolsId: '-',
            safetyProtocols: filteredTempSafetyProtocol,
            safetyProtocolsId: `${filteredTempSafetyProtocol.length}`,
            children: [
              {
                efficacyProtocols: [],
                safetyProtocols: filteredTempSafetyProtocol,
              },
            ],
          });
        }
        return acc;
      }, [] as IResultTable[]);
      setProtocolsData({
        ...protocolsData,
        items: filteredData,
        totalCount: filteredData.length,
        results: filteredData,
      });
    }
  };

  const setFieldValue = (field: string, value: any) =>
    dispatch(change(ADVANCED_SEARCH_FORM, field, value));

  const getIcdCodes = (diseaseStateId: number) => {
    const selectedDiseaseSate = asdiseaseStateData.find(t => t.id === diseaseStateId);
    setFieldValue('as_icd10', selectedDiseaseSate?.icdCode.join());
  };

  const setProtocolsDiseaseState = (tableData: IResultTable[]): void => {
    const diseaseStateIds: number[] = [];
    const diseaseStateList = tableData.reduce((acc, item) => {
      const listOfEfficacyProtocols = item.efficacyProtocols;
      if (listOfEfficacyProtocols && listOfEfficacyProtocols.length) {
        for (const protocol of listOfEfficacyProtocols) {
          if (
            protocol.specialty_type_id &&
            protocol.specialty_type_name &&
            !diseaseStateIds.includes(protocol.specialty_type_id)
          ) {
            diseaseStateIds.push(protocol.specialty_type_id);
            acc.push({
              id: protocol.specialty_type_id,
              diseaseName: protocol.specialty_type_name,
              icdCode: protocol.icdCodes ?? [],
            });
          }
        }
      }
      return acc;
    }, [] as IDiseaseStateList[]);
    setAsdiseaseStateData(diseaseStateList);
  };

  const getGenericName = (value: any) => {
    const safetyProtocolDrugs = value?.associatedDrugs || [];
    const safetyProtocolGeneric = safetyProtocolDrugs.find((d: any) => d.brand_name_code === 'G')
      ? safetyProtocolDrugs
          .filter((d: any) => d.brand_name_code === 'G')
          .map((d: any) => d.drug_name)
          .join(',')
      : '';
    setFieldValue('as_generic_name', safetyProtocolGeneric);
  };

  const handleFetchGpisOptions = async (searchText: string) => {
    const result = await fetchGpis(searchText);
    return (
      result?.data?.drugs?.map((therapy: { ndc: any; drug_info: any; is_specialty_drug: any }) => ({
        ...therapy,
        id: therapy.ndc,
        label: therapy.drug_info,
      })) || []
    );
  };

  const resetAdvancedSearchForm = (): void => {
    asDynamicFields.map(f => setFieldValue(`${f.fieldName}`, f.defaultValue));
    if (protocolsData) {
      setProtocolsDiseaseState(protocolsData.items);
    }
    setAdvancedSearch(true);
  };

  const efficacyCheckboxFunc = (value: any): void => {
    if (!value.target.checked) {
      const efficacyRowFields = ['as_disease', 'as_icd10', 'as_efficacy_protocol_status'];
      const efficacyFieldsToReset = asDynamicFields.filter(t =>
        efficacyRowFields.includes(t.fieldName),
      );
      efficacyFieldsToReset.map(f => setFieldValue(`${f.fieldName}`, f.defaultValue));
    }
    setEfficacyCheckbox(value.target.checked);
  };

  const safetyCheckboxFunc = (value: any): void => {
    if (!value.target.checked) {
      const safetyRowFields = [
        'as_brand_name',
        'as_generic_name',
        'as_gpi_10',
        'as_safety_protocol_status',
      ];
      const safetyFieldsToReset = asDynamicFields.filter(t =>
        safetyRowFields.includes(t.fieldName),
      );
      safetyFieldsToReset.map(f => setFieldValue(`${f.fieldName}`, f.defaultValue));
    }
    setSafetyCheckbox(value.target.checked);
  };

  React.useEffect(() => {
    let active = true;
    (async () => {
      const result = await ApplicationManagerClient.fetchEfficacySafetyProtocol();
      if (result?.data?.items && result.data.items.length) {
        const tempData = result.data.items.map((r: any) => mapProtocolsToTableResult(r));
        setProtocolsData({
          ...result.data,
          items: tempData,
          totalCount: result.data.items.length,
          results: tempData,
          fullData: tempData,
        });
        setReloadTableTrigger(new Date());
      }
    })();
    return () => {
      active = false;
    };
  }, [reloadTrigger]);

  return (
    <>
      {canEdit && (
        <SectionTitleStyled
          title="Clinical Item Protocol"
          rightSideItems={[
            {
              element: renderAddClinicalItemProtocolButton(),
              key: 'add-clinical-item-protocol-button',
            },
          ]}
        />
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xs="auto">
              <Button
                variant="outlined"
                onClick={() => {
                  if (!advancedSearch) {
                    resetAdvancedSearchForm();
                  }
                  setSearchTerm('');
                }}
              >
                ADVANCED SEARCH
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Grid container>
                <Grid item xs="auto">
                  <SearchField
                    width={48}
                    value={searchTerm}
                    onChange={setSearchTerm}
                    initialValue={initialSearchTerm}
                    placeholder={searchFieldPlaceholder}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {advancedSearch && (
            <Grid item xs="auto" className={classes.advancedSearchContainer}>
              <form>
                <Grid container item spacing={2} className={classes.containerPadding}>
                  <Grid item xs={12}>
                    <Checkbox
                      name="efficacyCk"
                      color="primary"
                      checked={efficacyCheckbox}
                      onChange={value => {
                        efficacyCheckboxFunc(value);
                      }}
                      id="efficacyCkId"
                    />
                    <Typography className={classes.asProtocolType}>Efficacy</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="as_disease"
                      label="Disease"
                      component={renderDropdown}
                      fields={asdiseaseStateData.map(t => {
                        return {
                          label: t.diseaseName,
                          value: t.id,
                        };
                      })}
                      onChange={(value: any) => getIcdCodes(value)}
                      disabled={!efficacyCheckbox}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      name="as_icd10"
                      label="ICD-10"
                      component={renderTextField}
                      width="100%"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="as_efficacy_protocol_status"
                      label="Efficacy Protocol Status"
                      component={renderDropdown}
                      fields={protocolStatus}
                      disabled={!efficacyCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      name="safetyCk"
                      color="primary"
                      checked={safetyCheckbox}
                      onChange={value => {
                        safetyCheckboxFunc(value);
                      }}
                      id="safetyCkId"
                    />
                    <Typography className={classes.asProtocolType}>Safety</Typography>
                  </Grid>
                  <Grid container className={classes.fixedSection} spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        name="as_brand_name"
                        label="Brand name"
                        labelVariant="body1"
                        component={AutocompleteMinigrid}
                        fetchOptions={handleFetchGpisOptions}
                        hint="Search by medication, GPI or NDC"
                        columnsToShow={{
                          gpi: 'GPI',
                          drug_name: 'Drug Name',
                          dosage_form_description: 'Form',
                          dose: 'Dose',
                          ldd: 'LDD',
                          specialty_type: 'Specialty Type',
                          ndc: 'NDC',
                        }}
                        minSearchLength={2}
                        valueFormatter={specialtyTypeFormatter}
                        onChange={(value: any) => {
                          setFieldValue('as_gpi_10', value.gpi.substr(0, 10));
                          getGenericName(value);
                        }}
                        disabled={!safetyCheckbox}
                        resetValue={!safetyCheckbox}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="as_generic_name"
                        label="Generic Name"
                        component={renderTextField}
                        width="100%"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        name="as_gpi_10"
                        label="GPI-10"
                        component={renderTextField}
                        width="100%"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="as_safety_protocol_status"
                        label="Safety Protocol Status"
                        component={renderDropdown}
                        fields={protocolStatus}
                        disabled={!safetyCheckbox}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ConfirmationPanel
                    cancelButtonName="advanced_search_cancel_button"
                    submitButtonName="advanced_search_apply_button"
                    cancelButtonText="Cancel"
                    submitButtonText="Apply"
                    handleCancel={() => {
                      setAdvancedSearch(false);
                      setReloadTrigger(new Date());
                    }}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </Grid>
              </form>
            </Grid>
          )}
          <Grid item xs="auto">
            <DataTableContext.Provider value={protocolsData}>
              <BaseTable<IResponse, IResult, IResultChildren>
                actionsPermitted
                enableParentCheckboxes={false}
                enableChildCheckboxes={false}
                paginationQueryParamSettings={{
                  pageSizeQueryStringKey: 'pageSize',
                  pageNumberQueryStringKey: 'pageNumber',
                  searchTermQueryStringKey: 'q',
                  sortPropQueryStringKey: 'sortProp',
                  sortOrderQueryStringKey: 'sortOrder',
                }}
                dataSet={protocolsData}
                childPkSelector={c => (c && c.clinical_data_id ? c.clinical_data_id : '')}
                parentPkSelector={p => (p && p.id ? p.id : '')}
                columnSettings={COLUMN_SETTINGS}
                triggerReload={reloadTrigger || reloadTableTrigger}
                searchTerm={JSON.stringify({
                  type: 'MULTIPLE',
                  search: [
                    {
                      values: debouncedSearchTerm,
                      columns: ['clinical_item_name', 'clinical_item_category_name'],
                      fullMatch: false,
                      active: !!debouncedSearchTerm,
                    },
                  ],
                })}
                classesOverride={{
                  customChildRow: classes.childRow,
                }}
              />
            </DataTableContext.Provider>
          </Grid>
        </Grid>
      </Grid>
      <ClinicalItemProtocolMainModal
        ref={addFormModalRef}
        onSuccess={onProtocolFormSuccess}
        protocolType={protocolType}
        protocolInfo={protocol}
      />
    </>
  );
};

const combinedStyles = combineStyles(CpmpStyles, accordianStyles);

export default compose(withStyles(combinedStyles))(
  reduxForm<IAdvancedSearchForm>({
    form: ADVANCED_SEARCH_FORM,
    initialValues: {
      as_efficacy_protocol_status: protocolStatus.find(t => t.selected)?.value,
      as_safety_protocol_status: protocolStatus.find(t => t.selected)?.value,
    },
  })(CpmpClinicalItemProtocol as any) as any,
);
