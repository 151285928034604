import React, { useEffect, useState, useRef, forwardRef } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import moment from 'moment';
import {
  Button,
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography,
  ClickAwayListener,
} from '@mui/material';
import { activeFinancialAssistanceList } from 'constants/lists';
import Dropdown from 'components/form/field/dropdown';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { DATABASE_DATE_FORMAT } from 'constants/index';
import { yearRange, months } from './datetime-picker-utils';
import Validation from '../validation/validation';
import { styles } from '../field/field-styles';
import 'react-datepicker/dist/react-datepicker.css';
import './datetime-picker.css';

const useStyles = makeStyles(styles);

function DateTimePicker({
  input,
  placeholder,
  disabled,
  id,
  name,
  useCustomContainer,
  textFieldInputProps,
  InputComponentInputProps,
  minDate,
  maxDate,
  showDisabledMonthNavigation,
  missing_value_id,
  resetFormToggle,
  ...props
}) {
  const classes = useStyles();

  const { showTimeSelect, fullWidth, onChange, 'data-qa-id': dataQaId } = props;
  const inputRef = useRef(null);
  const formatDate = !showTimeSelect ? DATABASE_DATE_FORMAT : 'YYYY-MM-DD HH:mm';

  let value = null;
  if (moment.isMoment(input.value)) {
    value = moment(input.value.format(formatDate), formatDate).toDate();
  } else if (input.value) {
    value = moment(input.value).toDate();
  }

  // eslint-disable-next-line react/no-find-dom-node
  const getElement = type => inputRef.current.input.querySelector(type);

  // eslint-disable-next-line arrow-body-style

  const missing_value_prop =
    missing_value_id && typeof missing_value_id === 'object'
      ? { [Object.keys(missing_value_id)[0]]: Object.values(missing_value_id)[0] }
      : {};

  const currentYear = moment().year();
  const currentMonth = moment().month();

  const yearsRange = yearRange(minDate, maxDate);
  const nonOutlierYearRange = yearRange().slice(1, yearRange().length - 1);

  const [yearSelect, setYearSelect] = useState(
    value && !Number.isNaN(Number(value)) ? moment(value).year() : currentYear,
  );
  const [monthSelect, setMonthSelect] = useState(
    value && !Number.isNaN(Number(value)) ? moment(value).month() : currentMonth,
  );
  const [disablePrevMonth, setDisablePrevMonth] = useState(yearSelect < nonOutlierYearRange[0]);
  const [disableNextMonth, setDisableNextMonth] = useState(
    yearSelect > Math.max(...nonOutlierYearRange),
  );

  const resetSelects = () => {
    const resetVal = value || moment();
    setYearSelect(moment(resetVal).year());
    setMonthSelect(moment(resetVal).month());
    setDisablePrevMonth(yearSelect < nonOutlierYearRange[0]);
    setDisableNextMonth(yearSelect > Math.max(...nonOutlierYearRange));
  };

  useEffect(() => {
    resetSelects();
  }, [resetFormToggle]);

  const displayYearRange =
    !disablePrevMonth && !disableNextMonth ? nonOutlierYearRange : yearsRange;

  const handleBlur = e => {
    resetSelects();
    if (input.onBlur) {
      input.onBlur();
    }
  };
  const CustomHeaderComponent = ({
    decreaseMonth,
    increaseMonth,
    date,
    changeYear,
    changeMonth,
    dataQaId,
  }) => {
    const handleDecreaseMonth = event => {
      decreaseMonth();
      // one year offset from min year so if they select from that year it still shows
      if (monthSelect - 1 === 0 && yearSelect === yearRange()[1]) {
        setMonthSelect(monthSelect - 1);
        setDisablePrevMonth(true);
      } else if (monthSelect - 1 < 0) {
        setMonthSelect(11);
        setYearSelect(yearSelect - 1);
        setDisablePrevMonth(false);
      } else {
        setMonthSelect(monthSelect - 1);
        setDisablePrevMonth(false);
      }
      setDisableNextMonth(false);
    };

    const handleIncreaseMonth = event => {
      increaseMonth();
      const maxYear = Math.max(...yearRange());
      // one year offset from max year so if they select from that year it still shows
      if (monthSelect + 1 === 11 && yearSelect === maxYear - 1) {
        setMonthSelect(monthSelect + 1);
        setDisableNextMonth(true);
      } else if (monthSelect + 1 > 11) {
        setMonthSelect(0);
        setYearSelect(yearSelect + 1);
        setDisableNextMonth(false);
      } else {
        setMonthSelect(monthSelect + 1);
        setDisableNextMonth(false);
      }
      setDisablePrevMonth(false);
    };
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Button onClick={handleDecreaseMonth} disabled={disablePrevMonth}>
          {'<'}
        </Button>
        <div className={classes.monthSelect}>
          <Dropdown
            label="Month"
            useNumericValue
            state={monthSelect || monthSelect === 0 ? months[monthSelect] : months[currentMonth]}
            handler={event => {
              setMonthSelect(months.indexOf(event?.target?.value));
              changeMonth(months.indexOf(event?.target?.value));
            }}
            minimal
            fields={months}
            data-qa-id={`date-dropdown-dropdown-month-${id}`}
            name="date-dropdown-dropdown-month"
            id="date-dropdown-dropdown-month-dropdown"
          />
        </div>

        <div className={classes.yearSelect}>
          <Dropdown
            label="Year"
            useNumericValue
            minimal
            state={yearSelect || currentYear}
            handler={event => {
              setYearSelect(event.target.value);
              changeYear(event?.target?.value);
            }}
            fields={displayYearRange}
            data-qa-id={`date-dropdown-dropdown-year-${id}`}
            name="date-dropdown-dropdown-year"
            id="date-dropdown-dropdown-year-dropdown"
            className={classes.monthButton}
          />
        </div>

        <Button onClick={handleIncreaseMonth} disabled={disableNextMonth}>
          {'>'}
        </Button>
      </Grid>
    );
  };

  return (
    <DatePicker
      selected={value}
      portalId="root-portal"
      renderCustomHeader={CustomHeaderComponent}
      data-qa-id={`${dataQaId}-calendar`}
      onChange={date => {
        let newValue = null;
        if (moment(date).isValid()) {
          if (showTimeSelect) {
            newValue = moment.utc(moment(date).format('YYYY-MM-DD hh:mm A'), 'YYYY-MM-DD hh:mm A');
          } else {
            newValue = moment.utc(moment(date).format(DATABASE_DATE_FORMAT), DATABASE_DATE_FORMAT);
          }
          if (moment(newValue).year() !== yearSelect) {
            setYearSelect(moment(newValue).year());
            if (moment(newValue).month() - 1 === 0 && moment(newValue).year() === yearRange()[1]) {
              setDisablePrevMonth(true);
              setDisableNextMonth(false);
            } else {
              setDisablePrevMonth(false);
              setDisableNextMonth(false);
            }
          }
          if (moment(newValue).month() !== monthSelect) {
            setMonthSelect(moment(newValue).month());
            const maxYear = Math.max([...yearRange()]);
            // one year offset from max year so if they select from that year it still shows
            if (moment(newValue).year() === maxYear - 1) {
              setDisableNextMonth(true);
              setDisablePrevMonth(false);
            }
          }
        }
        input.onChange(newValue);
        if (onChange) {
          onChange(newValue);
        }
        if (input.onBlur) {
          input.onBlur();
        }
      }}
      placeholderText={placeholder}
      customInput={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <TextField
          disabled={disabled}
          variant="standard"
          id={id}
          value={value}
          name={name}
          fullWidth={fullWidth}
          data-qa-id={`${dataQaId}-calendar-textfield`}
          onChange={onChange}
          inputProps={textFieldInputProps}
          placeholderText={placeholder}
          {...missing_value_prop}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={InputComponentInputProps}
        />
      }
      ref={inputRef}
      onBlur={handleBlur}
      disabledKeyboardNavigation
      onKeyDown={event => {
        // fix issue with focus and delete keys by forcing blur upon ESC and ENTER keys press
        if (event.key === 'Escape' || event.key === 'Enter') {
          getElement('input').blur();
          getElement('div').focus();
        }
      }}
      {...props}
      minDate={minDate}
      maxDate={maxDate}
      showDisabledMonthNavigation={showDisabledMonthNavigation}
      id={id}
      disabled={disabled}
    />
  );
}

export const renderDatePicker = withStyles(styles, { withTheme: true })(
  ({
    input,
    classes,
    label,
    id,
    placeholder,
    meta: { touched, error, warning },
    renderFooter,
    ...custom
  }) => {
    const [reset, setReset] = useState(false);
    return (
      <ClickAwayListener onClickAway={e => setReset(!reset)}>
        <Grid container direction="column">
          {label && (
            <Typography variant="caption" className={classes.fieldLabel}>
              {label}
            </Typography>
          )}
          <DateTimePicker
            input={input}
            dateFormat={['MM/dd/yyyy', 'MM/dd/yy']}
            placeholderText={placeholder !== undefined ? placeholder : 'mm/dd/yyyy'}
            {...custom}
            resetFormToggle={reset}
            id={id}
            className={custom.centerInput ? classes.centerInputValue : null}
          />
          <Validation touched={touched} error={error} warning={warning} />
          {renderFooter && renderFooter()}
        </Grid>
      </ClickAwayListener>
    );
  },
);

export const renderDateTimePicker = withStyles(styles, { withTheme: true })(
  ({ input, classes, label, meta: { touched, error, warning }, ...custom }) => {
    const [reset, setReset] = useState(false);
    return (
      <ClickAwayListener onClickAway={e => setReset(!reset)}>
        <Grid container direction="column">
          {label && (
            <Typography variant="caption" className={classes.fieldLabel}>
              {label}
            </Typography>
          )}
          <DateTimePicker
            input={input}
            showTimeSelect
            timeFormat="hh:mm a"
            timeIntervals={30}
            timeCaption="Time"
            dateFormat={['MM/dd/yyyy hh:mm a', 'MM/dd/yy hh:mm a']}
            placeholderText="mm/dd/yyyy hh:mm"
            resetFormToggle={reset}
            {...custom}
          />
          <Validation touched={touched} error={error} warning={warning} />
        </Grid>
      </ClickAwayListener>
    );
  },
);

export const renderCalendarPicker = withStyles(styles, { withTheme: true })(
  ({ input, label, meta: { touched, error, warning }, ...custom }) => {
    const [reset, setReset] = useState(false);
    return (
      <ClickAwayListener onClickAway={e => setReset(!reset)}>
        <Grid container direction="column" alignItems="center">
          <Typography variant="caption">{label}</Typography>
          <DateTimePicker
            input={input}
            inline
            dateFormat={['MM/dd/yyyy', 'MM/dd/yy']}
            placeholderText="mm/dd/yyyy"
            calendarClassName="calendar-inline"
            resetFormToggle={reset}
            {...custom}
          />
          <Validation touched={touched} error={error} warning={warning} />
        </Grid>
      </ClickAwayListener>
    );
  },
);

export const renderTimePicker = withStyles(styles, { withTheme: true })(
  ({ input, label, classes, meta: { touched, error, warning }, ...custom }) => {
    const [reset, setReset] = useState(false);
    return (
      <ClickAwayListener onClickAway={e => setReset(!reset)}>
        <div style={{ display: 'inline-flex', flexDirection: 'column', width: '80%' }}>
          <Typography className={classes.fieldLabel}>{label}</Typography>
          <TextField
            variant="standard"
            {...input}
            id="time"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            resetFormToggle={reset}
            {...custom}
          />
          <Validation touched={touched} error={error} warning={warning} />
        </div>
      </ClickAwayListener>
    );
  },
);

export default function ({ value, onChange, ...props }) {
  const [reset, setReset] = useState(false);
  return (
    <ClickAwayListener onClickAway={e => setReset(!reset)}>
      <DateTimePicker
        {...props}
        resetFormToggle={reset}
        input={{
          value,
          onChange,
        }}
      />
    </ClickAwayListener>
  );
}
