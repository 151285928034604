import { parseFullName as parseFullNameUtility } from 'parse-full-name';

const parseName = user => {
  const {
    title,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    suffix,
  } = user;
  const parsedName = parseFullNameUtility(
    `${title || ''} ${firstName || ''} ${middleName || ''} ${lastName || ''} ${
      suffix || ''
    }`.trim(),
  );
  const nameObj = {
    first:
      firstName &&
      parsedName &&
      parsedName.first &&
      parsedName.first.toLowerCase() === firstName.toLowerCase()
        ? parsedName.first
        : firstName,
    last:
      lastName &&
      parsedName &&
      parsedName.last &&
      parsedName.last.toLowerCase() === lastName.toLowerCase()
        ? parsedName.last
        : lastName,
    middle:
      middleName &&
      parsedName &&
      parsedName.middle &&
      parsedName.middle.toLowerCase() === middleName.toLowerCase()
        ? parsedName.middle
        : middleName,
    suffix:
      suffix &&
      parsedName &&
      parsedName.suffix &&
      parsedName.suffix.toLowerCase() === suffix.toLowerCase()
        ? parsedName.suffix
        : suffix,
    title:
      title &&
      parsedName &&
      parsedName.title &&
      parsedName.title.toLowerCase() === title.toLowerCase()
        ? parsedName.title
        : title,
  };
  if (!nameObj.first) {
    nameObj.first = '';
  }
  if (!nameObj.last) {
    nameObj.last = '';
  }
  if (!nameObj.middle) {
    nameObj.middle = '';
  }
  if (!nameObj.suffix) {
    nameObj.suffix = '';
  }
  if (!nameObj.title) {
    nameObj.title = '';
  }
  return nameObj;
};

const parseFullName = user => {
  const nameProperties = parseName(user);
  return `${nameProperties.first}${
    nameProperties.middle
      ? ` ${nameProperties.middle.charAt(0).toUpperCase()}${nameProperties.middle.slice(1)}`
      : ''
  } ${nameProperties.last} ${nameProperties.suffix}`.trim();
};

const parseFirstLastName = user => {
  const nameProperties = parseName(user);
  return `${nameProperties.first} ${nameProperties.last}`.trim();
};

const parseContactDisplayName = (deliveryContact, isProvider = true) => {
  const {
    first_name: firstName,
    last_name: lastName,
    provider_office_name: providerOfficeName,
  } = deliveryContact;

  if (isProvider && providerOfficeName) {
    return `${providerOfficeName}${firstName || lastName ? ' - ' : ''}${firstName || ''} ${
      lastName || ''
    }`.trim();
  }

  return `${firstName || ''} ${lastName || ''}`.trim();
};

export { parseFullName, parseFirstLastName, parseContactDisplayName };
