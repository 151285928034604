import React, { useEffect, useState } from 'react';
import {
  IStepProps,
  IPatientMergeSectionDetailsProps,
  IPatientMergeProps,
  ISectionWithIdentifier,
  findSectionByTagType,
} from 'components/patient-merge-modal/interfaces';
import { DemographicSection } from 'components/patient-merge-modal/select-form-view/sections/demographic-section';
import { ClinicalSection } from 'components/patient-merge-modal/select-form-view/sections/clinical-section';
import { TherapySection } from 'components/patient-merge-modal/select-form-view/sections/therapy-section';
import { DocumentSection } from 'components/patient-merge-modal/select-form-view/sections/document-section';
import { PatientTasksSection } from 'components/patient-merge-modal/select-form-view/sections/patient-tasks-section';
import { IncomeAndBenefitsSection } from 'components/patient-merge-modal/select-form-view/sections/income-benefits-section';
import { PatientMergeSection } from 'components/patient-merge-modal/patient-merge-section';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import { Grid, useTheme } from '@mui/material';
import { IPatientMergeInput } from 'actions/action-patient-merge';
import { IUploadedDocument, IUploadedDocuments } from 'interfaces/redux/IUploadedDocuments';
import { PatientMergeStyles } from '../styles';

const sections: ISectionWithIdentifier<IPatientMergeProps>[] = [
  {
    title: 'Demographics',
    render: (props: IPatientMergeSectionDetailsProps) => <DemographicSection {...props} />,
    identifier: 'Demographics',
  },
  {
    title: 'Income And Benefits',
    render: (props: IPatientMergeSectionDetailsProps) => <IncomeAndBenefitsSection {...props} />,
    identifier: 'IncomeAndBenefitsSection',
  },
  {
    title: 'Clinical',
    render: (props: IPatientMergeSectionDetailsProps) => <ClinicalSection {...props} />,
    identifier: 'Clinical',
  },
  {
    title: 'Therapies',
    render: (props: IPatientMergeSectionDetailsProps) => <TherapySection {...props} />,
    identifier: 'Therapies',
  },
  {
    title: 'Patient Level Tasks',
    render: (props: IPatientMergeSectionDetailsProps) => <PatientTasksSection {...props} />,
    identifier: 'PatientLevelTasks',
  },
  {
    title: 'Documents',
    render: (props: IPatientMergeSectionDetailsProps) => <DocumentSection {...props} />,
    identifier: 'Documents',
  },
];

function sectionHasAnySelectedInput(section: string | undefined, inputs: any[]) {
  if (!section) return false;
  const filteredInputs = inputs.filter(input => input.sectionName === section) || [];
  return filteredInputs.length > 0;
}
function validateInput(inputs: IPatientMergeInput[], patientDocuments: IUploadedDocument[]) {
  const validations = sections.reduce((acc: string[], sec: any) => {
    if (sec.identifier === 'Documents' && patientDocuments.length === 0) {
      return [...acc];
    }
    if (!sectionHasAnySelectedInput(sec.identifier, inputs)) {
      return [...acc, `None value selected to be merged under ${sec.title} Tab`];
    }
    return acc;
  }, []);
  return validations;
}

export function SelectFormSections(props: IStepProps) {
  const { onSectionChange, ...patientMergeProps } = props;
  const [expanded, setExpanded] = useState<undefined | number>(undefined);
  const handleChange = (idx: number) => async (_: unknown, isExpanded: boolean) => {
    setExpanded(isExpanded ? idx : undefined);
  };
  const theme = useTheme();
  const styles = PatientMergeStyles(theme);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);
  const patientDocuments = useSelector((state: IState) => state.uploadedDocuments.documents);

  useEffect(() => {
    const inputsWithSectionName = inputs?.map(i => ({
      ...i,
      sectionName: findSectionByTagType(i.type),
    }));
    const messages = validateInput(inputsWithSectionName, patientDocuments);
    setValidationMessages(messages);
  }, [inputs]);
  return (
    <>
      {sections.map((section, idx) => {
        const propsWithSectionName = { ...patientMergeProps, sectionName: section.identifier };
        return (
          <PatientMergeSection
            key={`section-${section.title}`}
            section={section}
            expanded={idx === expanded}
            onChange={handleChange(idx)}
            {...propsWithSectionName}
          />
        );
      })}
      {validationMessages.length > 0 && (
        <Grid container sx={styles.errorListContainer}>
          <Grid item>
            Warning:
            <ul>
              {validationMessages.map((message: string) => (
                <li>{message}</li>
              ))}
            </ul>
          </Grid>
        </Grid>
      )}
    </>
  );
}
