import React, { useEffect, useState } from 'react';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import {
  IPatientMergeProps,
  IPatientMergeSectionDetailsProps,
  IProfileIncomingBenefitsInfo,
} from 'components/patient-merge-modal/interfaces';
import { Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import { addInput, IPatientMergeInput, removeInput } from 'actions/action-patient-merge';
import {
  FinancialSection,
  IncomeSection,
  MedicalSection,
  PharmacySection,
} from 'components/patient-merge-modal/select-form-view/sections/income-benefits-subsections';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';
import { PatientMergeClient } from 'clients/patient-merge';
import { LookupResourceTypes } from 'constants/enums';
import { isInputSelected } from 'components/patient-merge-modal/utils';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { isEmpty } from 'lodash';

export const IncomeAndBenefitsSection = ({
  mergeId,
  expanded,
  sectionName,
}: IPatientMergeSectionDetailsProps) => {
  const [infos, setInfos] = useState<IProfileIncomingBenefitsInfo[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<IProfileIncomingBenefitsInfo>();
  const [primaryProfile, setPrimaryProfile] = useState<IProfileIncomingBenefitsInfo>();
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const {
    primary: primaryProfileId,
    duplicate: selectedProfileId,
    inputs,
  } = useSelector(({ patientMerge }: IState) => patientMerge);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loaded && expanded) {
      PatientMergeClient.getMergeIncomeAndBenefitInfo(mergeId).then(response => {
        setInfos(response.data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);
  useEffect(() => {
    setPrimaryProfile(infos.find(profile => profile.patientId === primaryProfileId));
  }, [infos, primaryProfileId]);
  useEffect(() => {
    setSelectedProfile(infos.find(profile => profile.patientId === selectedProfileId));
  }, [infos, selectedProfileId]);

  const selectValue = (value: boolean, type: LookupResourceTypes, selectedId: number) => {
    const input: IPatientMergeInput = {
      type,
      selectedId,
      sectionName,
    };
    dispatch(value ? addInput(input) : removeInput(input));
  };

  useEffect(() => {
    selectedProfile?.pbmInsurances?.map(x =>
      selectValue(selectAll, LookupResourceTypes.PharmacyInsurance, x.id),
    );
    selectedProfile?.medicalInsurances?.map(x =>
      selectValue(selectAll, LookupResourceTypes.MedicalInsurance, x.id),
    );
    selectedProfile?.financialAssistances?.map(x =>
      selectValue(selectAll, LookupResourceTypes.FinancialAssistance, x.id),
    );
    selectedProfile?.incomes?.map(x => selectValue(selectAll, LookupResourceTypes.Income, x.id));
  }, [selectAll]);
  const handleCheckbox =
    (type: LookupResourceTypes, selectedId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      selectValue(e.target.checked, type, selectedId);
    };

  const disableSelectAll =
    isEmpty(selectedProfile?.pbmInsurances) &&
    isEmpty(selectedProfile?.medicalInsurances) &&
    isEmpty(selectedProfile?.financialAssistances) &&
    isEmpty(selectedProfile?.incomes);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          sx={classes.checkboxFormControl}
          label="Check all"
          control={
            <Checkbox
              sx={classes.checkbox}
              disabled={disableSelectAll}
              onChange={event => setSelectAll(event.target.checked)}
              checked={selectAll}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Pharmacy Benefits</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container xs={6}>
          {selectedProfile?.pbmInsurances ? (
            selectedProfile.pbmInsurances.map(pbmInsurance => (
              <Grid key={`selected-${pbmInsurance.id}`} item xs={12}>
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={<PharmacySection {...pbmInsurance} />}
                  control={
                    <Checkbox
                      onChange={handleCheckbox(
                        LookupResourceTypes.PharmacyInsurance,
                        pbmInsurance.id,
                      )}
                      checked={isInputSelected(
                        pbmInsurance.id,
                        LookupResourceTypes.PharmacyInsurance,
                        inputs,
                      )}
                      sx={classes.checkbox}
                    />
                  }
                />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
        <Grid item container xs={6}>
          {primaryProfile?.pbmInsurances ? (
            primaryProfile.pbmInsurances.map(pbmInsurance => (
              <Grid key={`primary-${pbmInsurance.id}`} item xs={12}>
                <PharmacySection {...pbmInsurance} />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Medical Benefits</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container xs={6}>
          {selectedProfile?.medicalInsurances ? (
            selectedProfile.medicalInsurances.map(medicalInsurance => (
              <Grid key={`selected-${medicalInsurance.id}`} item xs={12}>
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={<MedicalSection {...medicalInsurance} />}
                  control={
                    <Checkbox
                      onChange={handleCheckbox(
                        LookupResourceTypes.MedicalInsurance,
                        medicalInsurance.id,
                      )}
                      checked={isInputSelected(
                        medicalInsurance.id,
                        LookupResourceTypes.MedicalInsurance,
                        inputs,
                      )}
                      sx={classes.checkbox}
                    />
                  }
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={6}>
              -
            </Grid>
          )}
        </Grid>
        <Grid item container xs={6}>
          {primaryProfile?.medicalInsurances ? (
            primaryProfile.medicalInsurances.map(medicalInsurance => (
              <Grid key={`primary-${medicalInsurance.id}`} item xs={12}>
                <MedicalSection {...medicalInsurance} />
              </Grid>
            ))
          ) : (
            <Grid item xs={6}>
              -
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Financial Assistance</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container xs={6}>
          {selectedProfile?.financialAssistances ? (
            selectedProfile.financialAssistances.map(financialAssistance => (
              <Grid key={`selected-${financialAssistance.id}`} item xs={12}>
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={<FinancialSection {...financialAssistance} />}
                  control={
                    <Checkbox
                      onChange={handleCheckbox(
                        LookupResourceTypes.FinancialAssistance,
                        financialAssistance.id,
                      )}
                      checked={isInputSelected(
                        financialAssistance.id,
                        LookupResourceTypes.FinancialAssistance,
                        inputs,
                      )}
                      sx={classes.checkbox}
                    />
                  }
                />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
        <Grid item container xs={6}>
          {primaryProfile?.financialAssistances ? (
            primaryProfile.financialAssistances.map(financialAssistance => (
              <Grid key={`primary-${financialAssistance.id}`} item xs={12}>
                <FinancialSection {...financialAssistance} />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Income</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container xs={6}>
          {selectedProfile?.incomes ? (
            selectedProfile.incomes.map(income => (
              <Grid key={`selected-${income.id}`} item xs={12}>
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={<IncomeSection {...income} />}
                  control={
                    <Checkbox
                      onChange={handleCheckbox(LookupResourceTypes.Income, income.id)}
                      sx={classes.checkbox}
                    />
                  }
                />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
        <Grid item container xs={6}>
          {primaryProfile?.incomes ? (
            primaryProfile.incomes.map(income => (
              <Grid key={`primary-${income.id}`} item xs={12}>
                <IncomeSection {...income} />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
