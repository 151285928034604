import {
  SIDEBAR_FILTERS_CLEAR_IDS,
  SIDEBAR_FILTERS_CLEAR,
  SIDEBAR_FILTERS_SET,
  FETCH_USER_PREFERENCES,
  DATE_OPTIONS,
  PATIENT_LIST_DEFAULT_PAGE_SIZE,
  PATIENT_LIST_INITIAL_PAGE,
} from '../constants';

const initialState = {
  loading: false,
  data: {
    task: {
      date: DATE_OPTIONS.TODAY,
      serviceGroups: [],
      clinics: [],
      displayOnlyFC: [],
      displayOnlyDC: [],
      displayOnlyCSL: [],
      displayOnlyINT: [],
      fcInProgressReason: [],
      completed: false,
      orCategory: [],
      orType: [],
      statusIds: {
        AR: [],
        DUR: [],
        DC: [],
        PA: [],
        FA: [],
        CSL: [],
        FC: [],
        FDC: [],
        RS: [],
        INT: [],
        OR: [],
        TPR: [],
      },
      incidentCategory: [],
      incidentType: [],
      paNewRenewal: [],
      faNewRenewal: [],
      cslInitialOngoing: [],
      auditWarningsCategory: [],
      auditWarningsRules: [],
      excludeOverdueARs: false,
      hideInternalFundingFA: false,
      adherencePackaging: false,
      page: PATIENT_LIST_INITIAL_PAGE,
      pageSize: PATIENT_LIST_DEFAULT_PAGE_SIZE,
    },
    schedule: {
      from: null,
      to: null,
      date: 'TODAY',
      serviceGroups: [],
      clinics: [],
      orderBy: 'start_dt',
      orderDirection: 'desc',
    },
    report: {
      from: null,
      to: null,
      date: null,
      serviceGroups: [],
      clinics: [],
      patientStatuses: [],
    },
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_PREFERENCES: {
      const pref = payload.data.user_preferences;
      return {
        loading: false,
        data: {
          task: (pref && pref.sidebar && pref.sidebar.task) || initialState.data.task,
          schedule: (pref && pref.sidebar && pref.sidebar.schedule) || initialState.data.schedule,
          report: (pref && pref.sidebar && pref.sidebar.report) || initialState.data.report,
        },
      };
    }
    case SIDEBAR_FILTERS_CLEAR: {
      return { ...initialState };
    }
    case SIDEBAR_FILTERS_SET: {
      const { filterType, props } = payload;
      const { page: _1, pageSize: _2, ...newProps } = props;
      return {
        ...state,
        data: {
          ...state.data,
          [filterType]: {
            ...state.data[filterType],
            ...newProps,
          },
        },
      };
    }
    case SIDEBAR_FILTERS_CLEAR_IDS: {
      const { filterType, keys, ids, parentKey } = payload;
      const newState = {
        ...state,
        data: {
          ...state.data,
          [filterType]: {
            ...state.data[filterType],
          },
        },
      };
      keys.forEach(key => {
        if (parentKey) {
          if (ids && Array.isArray(newState.data[filterType][parentKey][key])) {
            newState.data[filterType][parentKey][key] = newState.data[filterType][parentKey][
              key
            ].filter(val => {
              if (typeof val === 'object') {
                return !ids.includes(Number(val.idValue));
              }
              return !ids.includes(Number(val));
            });
          } else if (
            ids &&
            (newState.data[filterType][parentKey][key] ||
              newState.data[filterType][parentKey][key] === 0)
          ) {
            newState.data[filterType][parentKey][key] =
              initialState.data[filterType][parentKey][key];
          } else if (!ids) {
            newState.data[filterType][parentKey][key] =
              initialState.data[filterType][parentKey][key];
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (ids && Array.isArray(newState.data[filterType][key])) {
            newState.data[filterType][key] = newState.data[filterType][key].filter(val => {
              if (typeof val === 'object') {
                return !ids.includes(Number(val.idValue));
              }
              if (typeof val === 'string' && val.length > 1) {
                return !ids.includes(val);
              }
              return !ids.includes(Number(val));
            });
          } else if (
            ids &&
            (newState.data[filterType][key] || newState.data[filterType][key] === 0)
          ) {
            newState.data[filterType][key] = initialState.data[filterType][key];
          } else if (!ids) {
            newState.data[filterType][key] = initialState.data[filterType][key];
          }
        }
      });
      return newState;
    }
    default:
      return state;
  }
};
