import useScanDetection from 'use-scan-detection';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ShippingClient } from 'clients/shipping-client';
import { useDispatch } from 'react-redux';
import { notifyError } from 'actions/action-notifications';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { DeliveryMethodValue } from 'constants/enums';
import {
  ICurrentDeliveryInformation,
  IShippingVerificationScanHistoryItem,
  PackageLabelScanStatus,
  ShippingLabelScanStatus,
} from './types';
import {
  PackageScanRequirements,
  ScanSounds,
  getSoundFor,
  playScanSound,
  createScanEntry,
  getPackageScanStatus,
  getShippingScanStatus,
} from './utils';

// https://github.com/markjaniczak/use-scan-detection#parameters
export const SCAN_DETECTION_SETTINGS = {
  // Average time between characters in milliseconds. Used to determine if input is from keyboard or a scanner. Defaults to 50.
  averageWaitTime: 50,
  // Time to evaluate the buffer after each character.
  // timeToEvaluate: 50,
  // Minimum number of characters for a barcode to successfully read. Should be greater than 0. Defaults to 1.
  minLength: 4,
};

export interface UseLabelScanArgs {
  onOrderScan?(
    orderId: string,
    labelValue: string,
    historyItem: IShippingVerificationScanHistoryItem,
  ): Promise<void>;
  onPackageScan?(
    orderId: string,
    labelValue: string,
    historyItem: IShippingVerificationScanHistoryItem,
  ): Promise<void>;
  onShippingScan?(
    orderId: string,
    labelValue: string,
    historyItem: IShippingVerificationScanHistoryItem,
  ): Promise<void>;
  disableScanner?: boolean;
}

export interface UseLabelScanState {
  orderId?: string;
  setOrderId: (orderId: string | null) => void;
  setPackageScanRequirements: (currentDeliveryInfo?: ICurrentDeliveryInformation) => void;
  packageScanRequirements: PackageScanRequirements | null;
  history: IShippingVerificationScanHistoryItem[];
  packageScanStatus: any;
  shippingScanStatus: any;
  fetchHistory: (orderIdValue?: string) => Promise<void>;
  scannedPackages: IShippingVerificationScanHistoryItem[];
  scannedShippingLabel?: IShippingVerificationScanHistoryItem;
  processScanInput: (value: string, manual: boolean) => void;
  isLoadingScanHistory: boolean;
  orderScanCompleted: boolean;
}

export const useLabelScan = ({
  onOrderScan,
  onPackageScan,
  onShippingScan,
  disableScanner = false,
}: UseLabelScanArgs): UseLabelScanState => {
  const [orderId, setOrderIdInternal] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);
  const [packageScanRequirements, setPackageScanRequirementsInternal] =
    useState<PackageScanRequirements | null>(null);
  const [history, setHistory] = useState<IShippingVerificationScanHistoryItem[]>([]);
  const currentUser = useTypedSelector(state => state.auth.currentUser.username);
  const dispatch = useDispatch();

  const packageScanStatus = useMemo<PackageLabelScanStatus>(
    () => getPackageScanStatus(history, packageScanRequirements),
    [history, packageScanRequirements],
  );

  const orderScanCompleted = useMemo<boolean>(
    () =>
      !!orderId &&
      history.some(item => item.field_name === 'order' && item.status_message === 'ok'),
    [orderId, history],
  );

  const shippingScanStatus = useMemo<ShippingLabelScanStatus>(
    () => getShippingScanStatus(history),
    [history],
  );

  const postScanHistory = async (
    orderId: string,
    historyItem: IShippingVerificationScanHistoryItem,
    manual: boolean,
  ) => {
    const { username: _, ...payload } = historyItem;
    if (historyItem.field_name === 'order' && historyItem.status_message === 'ok') {
      setOrderIdInternal(undefined);
      setHistory([]);
    }
    const res = await ShippingClient.recordScan(orderId, { ...payload, manual });
    if (res.data.history) {
      setHistory(res.data.history);
    }
    if (historyItem.field_name === 'order') {
      setOrderIdInternal(orderId);
    }
  };
  const fetchHistory = useCallback(
    async (orderIdValue?: string) => {
      if (orderIdValue && !isFetching) {
        setIsFetching(true);
        setOrderIdInternal(undefined);
        const res = await ShippingClient.fetchScanHistory(orderIdValue);
        setOrderIdInternal(orderIdValue);
        setHistory(res.data.history);
        setIsFetching(false);
      }
    },
    [isFetching, setIsFetching, setHistory],
  );

  const handlePackageScanError = useCallback(
    (message: string) => {
      dispatch(notifyError(message));
      playScanSound(ScanSounds.Error);
    },
    [dispatch],
  );

  /**
   * The following methods are triggered when one of the labels are scanned in the right order.
   * This is where we would play a sound, validate the barcode, and record the scan action.
   * @param orderId The current orderId
   * @param labelValue The value scanned from the label
   */
  const onOrderScanInternal = useCallback(
    (orderId: string, labelValue: string, manual: boolean) => {
      if (isFetching) return;
      const scanHistoryItem = createScanEntry(currentUser, orderId, labelValue, 'order');
      setHistory(prev => [...prev, scanHistoryItem]);
      playScanSound(ScanSounds.Ok);
      postScanHistory(orderId, scanHistoryItem, manual);
      onOrderScan?.(orderId, labelValue, scanHistoryItem);
    },
    [currentUser, isFetching, onOrderScan],
  );
  const onPackageScanInternal = useCallback(
    (orderId: string, labelValue: string, manual: boolean) => {
      // Do updates to status & send a request to record the scan
      const scanHistoryItem = createScanEntry(currentUser, orderId, labelValue, 'package');
      setHistory(prev => [...prev, scanHistoryItem]);
      playScanSound(getSoundFor(scanHistoryItem));
      postScanHistory(orderId, scanHistoryItem, manual);
      onPackageScan?.(orderId, labelValue, scanHistoryItem);
    },
    [currentUser, onPackageScan],
  );
  const onShippingScanInternal = useCallback(
    (orderId: string, labelValue: string, manual: boolean) => {
      // Do updates to status & send a request to record the scan
      const scanHistoryItem = createScanEntry(currentUser, orderId, labelValue, 'label');
      setHistory(prev => [...prev, scanHistoryItem]);
      playScanSound(getSoundFor(scanHistoryItem));
      postScanHistory(orderId, scanHistoryItem, manual);
      onShippingScan?.(orderId, labelValue, scanHistoryItem);
    },
    [currentUser, onShippingScan],
  );

  const parseScannedLabelInternal = useCallback(
    (value: string, manual: boolean) => {
      // TODO: ARBOR-10663 - follow up task for adding -SL to shipping labels
      if (!value) return;
      // Always read scanned barcode text as UPPERCASE
      value = value.toUpperCase();

      if (value.endsWith('-SL')) {
        // In a future update, we want the shipping label barcode to end with an -SL.
        // The backend needs to be updated to send this value w/ -SL to the shipping label printing service,
        // and look for the -SL during scan history validation.
        // For debugging, the scanner needs multiple characters to recognize the scan input,
        // so adding the -SL will help recognize the order number scan and we remove it after.
        value = value.slice(0, -3);
      }
      if (value.endsWith('-FC')) {
        const [parsedOrderId] = value.split('-FC');
        // If they haven't scanned an order yet, handle the order scan
        if (!orderId && parsedOrderId) {
          onOrderScanInternal(parsedOrderId, value, manual);
          return;
        }
        // If they scan the same FC they are already on
        if (orderId === parsedOrderId) {
          if (shippingScanStatus === ShippingLabelScanStatus.Complete) {
            // User scanned the same FC that's currently open (completed), don't reload it
            return;
          }
          // User scanned the same FC they are currently working on (in-progress)
          handlePackageScanError(
            'You are already working on this order. Continue scanning the packages and shipping label.',
          );
          return;
        }

        onOrderScanInternal(parsedOrderId, value, manual);
        return;
      }
      if (value.endsWith('-NR') || value.endsWith('-CC')) {
        if (packageScanRequirements?.isPickup) {
          handlePackageScanError('Pick-up orders do not require a package scan.');
          return;
        }
        if (!orderId) {
          // No order selected
          handlePackageScanError('You must scan an order form before scanning any labels.');
          return;
        }
        onPackageScanInternal(orderId, value, manual);
        return;
      }
      if (
        value.match(/^[0-9A-Z]+$/g) /* future enhancement/bugfix - value.endsWith('-SL') */ &&
        packageScanStatus === PackageLabelScanStatus.Complete
      ) {
        if (packageScanRequirements?.isPickup) return;
        if (!orderId) {
          // No order selected
          handlePackageScanError('You must scan an order before scanning any labels.');
          return;
        }
        if (shippingScanStatus === ShippingLabelScanStatus.Complete) {
          handlePackageScanError('You already scanned a shipping label for this order.');
          return;
        }
        onShippingScanInternal(orderId, value, manual);
        return;
      }
      handlePackageScanError('The barcode you scanned did not register.');
    },
    [
      handlePackageScanError,
      onOrderScanInternal,
      onPackageScanInternal,
      onShippingScanInternal,
      orderId,
      packageScanRequirements?.isPickup,
      packageScanStatus,
      shippingScanStatus,
    ],
  );

  // Look for scan events on the whole page
  useScanDetection({
    ...SCAN_DETECTION_SETTINGS,
    onComplete: (scannedLabelCode: unknown) => {
      if (disableScanner) return;
      let value: string = scannedLabelCode as string;
      if (value.startsWith('Enter') || value.endsWith('Enter')) {
        value = value.replace(/^Enter/g, '').replace(/Enter$/g, '');
      }
      if (value.indexOf('Shift') > -1) {
        value = value.replace(/Shift([A-Z])/g, '$1');
      }
      parseScannedLabelInternal(value, false);
    },
  });

  const scannedPackages = useMemo(() => {
    return orderId && !isFetching ? history.filter(item => item.field_name === 'package') : [];
  }, [history, orderId, isFetching]);
  const scannedShippingLabel = useMemo(() => {
    return orderId && !isFetching
      ? history.filter(item => item.field_name === 'label').reverse()[0]
      : undefined;
  }, [history, orderId, isFetching]);

  const setOrderId = useCallback(
    async (orderIdValue?: string | null) => {
      if (orderIdValue && orderId && orderIdValue === orderId) return;
      // Request the order history when the orderId is set
      if (orderIdValue) {
        await fetchHistory(orderIdValue);
      } else {
        setHistory([]);
        setOrderIdInternal(undefined);
      }
    },
    [orderId, setHistory, setOrderIdInternal, fetchHistory],
  );

  const setPackageScanRequirements = useCallback(
    (currentDeliveryInfo?: ICurrentDeliveryInformation) => {
      setPackageScanRequirementsInternal({
        ccCount: currentDeliveryInfo?.ccPackageCount ?? 0,
        nrCount: currentDeliveryInfo?.nrPackageCount ?? 0,
        isPickup: currentDeliveryInfo?.deliveryMethod === DeliveryMethodValue.PickUp,
      });
    },
    [setPackageScanRequirementsInternal],
  );

  return {
    orderId,
    setOrderId,
    history,
    packageScanStatus,
    shippingScanStatus,
    fetchHistory,
    isLoadingScanHistory: isFetching,
    scannedPackages,
    scannedShippingLabel,
    processScanInput: parseScannedLabelInternal,
    orderScanCompleted,
    setPackageScanRequirements,
    packageScanRequirements,
  };
};
