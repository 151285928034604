import { Grid, FormControlLabel, Divider, Checkbox } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import {
  IProfileTherapy,
  IPatientMergeProps,
  IPatientMergeSectionDetailsProps,
} from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { addInput, IPatientMergeInput, removeInput } from 'actions/action-patient-merge';
import { LookupResourceTypes } from 'constants/enums';
import { useTheme } from '@mui/styles';
import { TherapyInfo } from 'components/patient-merge-modal/info-component/therapy-info';
import { PatientMergeClient } from 'clients/patient-merge';
import { isInputSelected } from 'components/patient-merge-modal/utils';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { isEmpty } from 'lodash';
import { INACTIVE_THERAPY_CATEGORIES } from '../../constants';

export const TherapySection = (props: IPatientMergeSectionDetailsProps) => {
  const { mergeId, expanded } = props;
  const [loaded, setLoaded] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const primaryId = useSelector((state: IState) => state.patientMerge.primary);
  const duplicateId = useSelector((state: IState) => state.patientMerge.duplicate);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);

  const [duplicateTherapies, setDuplicateTherapies] = useState<IProfileTherapy[]>([]);
  const [primaryTherapies, setPrimaryTherapies] = useState<IProfileTherapy[]>([]);
  const [therapies, setTherapies] = useState<IProfileTherapy[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loaded && expanded) {
      PatientMergeClient.getMergeTherapyInfo(mergeId).then(response => {
        setTherapies(response.data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);

  useEffect(() => {
    setPrimaryTherapies(
      therapies.filter((therapy: IProfileTherapy) => therapy.patientId === primaryId),
    );
    setDuplicateTherapies(
      therapies.filter((therapy: IProfileTherapy) => therapy.patientId === duplicateId),
    );
  }, [therapies, primaryId, duplicateId]);
  const selectValue = (value: boolean, type: LookupResourceTypes, selectedId: number) => {
    const input: IPatientMergeInput = {
      type,
      selectedId,
      sectionName: props.sectionName,
    };
    dispatch(value ? addInput(input) : removeInput(input));
  };

  useEffect(() => {
    duplicateTherapies.map(x => selectValue(selectAll, LookupResourceTypes.Therapy, x.id));
  }, [selectAll]);
  const handleCheckbox =
    (type: LookupResourceTypes, selectedId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      selectValue(e.target.checked, type, selectedId);
    };

  const disableSelectAll = isEmpty(duplicateTherapies);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          sx={classes.checkboxFormControl}
          label="Check all"
          control={
            <Checkbox
              sx={classes.checkbox}
              disabled={disableSelectAll}
              onChange={event => setSelectAll(event.target.checked)}
              checked={selectAll}
            />
          }
        />
      </Grid>
      <Grid container item xs={6} rowSpacing={3}>
        {duplicateTherapies.map((therapy, idx) => {
          return (
            <Fragment key={`duplicate-therapy-${therapy.id}`}>
              <Grid item xs={12}>
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={<TherapyInfo key={`duplicate-${therapy.id}`} therapy={therapy} />}
                  control={
                    <Checkbox
                      sx={classes.checkbox}
                      onChange={handleCheckbox(LookupResourceTypes.Therapy, therapy.id)}
                      checked={isInputSelected(therapy.id, LookupResourceTypes.Therapy, inputs)}
                    />
                  }
                />
              </Grid>
              {idx < duplicateTherapies.length - 1 && (
                <Grid item xs={12}>
                  <Divider sx={classes.divider} light />
                </Grid>
              )}
            </Fragment>
          );
        })}
      </Grid>
      <Grid container item xs={6} rowSpacing={3}>
        {primaryTherapies.map((therapy, idx) => {
          return (
            <Fragment key={`primary-therapy-${therapy.id}`}>
              <Grid item xs={12}>
                <TherapyInfo key={`primary-${therapy.id}`} therapy={therapy} />
              </Grid>
              {idx < primaryTherapies.length - 1 && (
                <Grid item xs={12}>
                  <Divider sx={classes.divider} light />
                </Grid>
              )}
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};
