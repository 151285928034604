import React from 'react';
import { Grid } from '@mui/material';

type IProps = { active: boolean; note: string | undefined; type: string; limit?: number };

const noteStyleByType: Record<string, Record<string, React.CSSProperties>> = {
  fc: {
    withinLimit: {
      backgroundColor: 'lightgray',
      color: 'black',
      fontWeight: 500,
      padding: '2px',
      paddingLeft: '5px',
      borderRadius: '5px',
    },
    overLimit: {
      backgroundColor: '#fff1ef',
      color: '#b5302c',
      fontWeight: 500,
      padding: '2px',
      paddingLeft: '5px',
      borderRadius: '5px',
    },
  },
  demo: {
    withinLimit: {
      backgroundColor: 'lightgray',
      color: 'black',
      fontWeight: 500,
      padding: '2px',
      paddingLeft: '5px',
      borderRadius: '5px',
    },
    overLimit: {
      backgroundColor: '#fff1ef',
      color: '#b5302c',
      fontWeight: 500,
      padding: '2px',
      paddingLeft: '5px',
      borderRadius: '5px',
    },
  },
};
const containerStylesByType: Record<string, React.CSSProperties> = {
  fc: { marginLeft: '12px', marginTop: '-33px' },
  demo: { position: 'absolute' },
};

const NoteFieldCharacterLimitMessage = (props: IProps) => {
  const { active, note, type, limit = 50 } = props;
  if (!active || !note || note?.length === 0) {
    return null;
  }
  if (note.length <= limit) {
    return (
      <Grid item xs={12} style={containerStylesByType[type]}>
        <span style={noteStyleByType[type].withinLimit}>{limit - note.length} characters left</span>
      </Grid>
    );
  }

  const msg =
    type === 'fc'
      ? `This note has a ${limit} character limit. You will need to remove ${
          note.length - limit
        } characters to complete this bundle.`
      : `This note has a ${limit} character limit. You will need to remove ${
          note.length - limit
        } characters before saving changes.`;

  return (
    <Grid item xs={12} style={containerStylesByType[type]}>
      <span style={noteStyleByType[type].overLimit}>{msg}</span>
    </Grid>
  );
};

export default NoteFieldCharacterLimitMessage;
