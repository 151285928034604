import { BooleanOption } from 'interfaces/forms/types';
import { ILabelValue } from '../interfaces/ILabelValue';
import {
  AR,
  CONTACT_RELATIONSHIPS,
  CSL,
  DC,
  DUR,
  ENSURE_THERAPY_MUST_MOVE_TO_UPDATE_STATUS,
  FA,
  FC,
  FDC,
  INT,
  MR,
  NO_ALLERGY_INFORMATION_AVAILABLE,
  NO_KNOWN_ALLERGIES,
  OR,
  PA,
  QRE,
  RS,
  SERVICE_ENROLLMENT_SHOULD_BE_SET,
  SMS,
  STAR,
  STATUS_FA_ALREADY_IN_PLACE,
  THERAPIES,
  THERAPY_SHOULD_HAVE_START_DATE,
  THERAPY_SHOULD_NOT_BE_ON_ON_HOLD_FOR_TOO_LONG,
  THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE,
  TPR,
} from '.';

import { TherapyAdministrationStatuses } from './therapy-administration-statuses';
import { MethodOptions } from './method-options';
import { NoteTagTypes } from './note-tag-types';
import { NoteTypes } from './note-types';
import { NoteTagLabels } from './note-tag-labels';
import {
  AncillarySupplyValues,
  ClinicalSupportOptInReasonLabel,
  ClinicalSupportOptInReasonValue,
  ClinicalSupportOptOutReasonLabel,
  ClinicalSupportOptOutReasonValue,
  ContactMethodTypes,
  CopayCardTypes,
  CounselingTaskType,
  DeliveryMethodLabel,
  DeliveryMethodValue,
  FinancialAssistanceSubTypes,
  FinancialAssistanceTypes,
  MedicationStatus,
  PrescriptionAssistanceProgramTypes,
  RsCancelReasons,
} from './enums';

export const genderList = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'FAAB', value: 'FAAB' },
  { label: 'MAAB', value: 'MAAB' },
  { label: 'Other', value: 'Other ' },
  { label: 'Not Disclosed', value: 'Not Disclosed' },
];

export const raceList = [
  { label: 'White - Non Hispanic', value: 'White - Non Hispanic ' },
  { label: 'Black - Non Hispanic', value: 'Black - Non Hispanic' },
  {
    label: 'American Indian/Alaskan Native',
    value: 'American Indian/Alaskan Native',
  },
  { label: 'Hispanic', value: 'Hispanic' },
  { label: 'Asian/Pacific Islander', value: 'Asian/Pacific Islander ' },
  { label: 'Other', value: 'Other' },
];

export const commonPronounList = [
  { label: 'She/Her', value: 'She/Her' },
  { label: 'He/Him', value: 'He/Him' },
  {
    label: 'They/Them',
    value: 'They/Them',
  },
  { label: 'She/They', value: 'She/They' },
  { label: 'He/They', value: 'He/They' },
];

export const pronounList = [...commonPronounList, { label: 'Other', value: 'Other' }];

export const relationshipList = [
  { label: 'Child', value: CONTACT_RELATIONSHIPS.CHILD },
  { label: 'Parent', value: CONTACT_RELATIONSHIPS.PARENT },
  { label: 'Spouse', value: CONTACT_RELATIONSHIPS.SPOUSE },
  { label: 'Sibling', value: CONTACT_RELATIONSHIPS.SIBLING },
  { label: 'Other', value: CONTACT_RELATIONSHIPS.OTHER },
  { label: 'Provider', value: CONTACT_RELATIONSHIPS.PROVIDER },
  { label: 'Significant Other', value: CONTACT_RELATIONSHIPS.SIGNIFICANT_OTHER },
];

export const activeFinancialAssistanceList = [
  FinancialAssistanceTypes.CopayCard,
  FinancialAssistanceTypes.Mpap,
  FinancialAssistanceTypes.InternalFoundation,
  FinancialAssistanceTypes.ExternalFoundation,
];

export const inactiveFinancialAssistanceList = [
  FinancialAssistanceTypes.Pap,
  FinancialAssistanceTypes.Foundation,
];

export const staticPharmacies = [
  {
    name: 'Patient Assistance Program',
    address: 'N/A',
    npi: '0000000000',
    doing_business_as: 'Patient Assistance Program',
    is_internal: 'Yes',
    phone: '',
    fax: '',
  },
  {
    name: 'Buy and Bill',
    address: 'N/A',
    npi: '0000000001',
    doing_business_as: 'Buy and Bill',
    is_internal: 'Yes',
    phone: '',
    fax: '',
  },
];

export const copayCardTypeList = [
  CopayCardTypes.pharmacyBenefit,
  CopayCardTypes.creditCard,
  CopayCardTypes.check,
];

export const prescriptionAssistanceProgramTypeList = [
  PrescriptionAssistanceProgramTypes.manufacturerPrescriptionAssistanceProgram,
  // PrescriptionAssistanceProgramTypes.healthSystemPrescriptionAssistanceProgram,
];
export const FreeDrugReasonsLabels = [
  { id: 1, label: 'Patient is uninsured' },
  { id: 2, label: 'Patient cannot afford - no internal/external foundation available' },
  { id: 3, label: 'Drug not covered by patient insurance' },
];

export const getFreeDrugReasonId = (freeDrugReason: string) => {
  const [reason] = FreeDrugReasonsLabels.filter(x => x.label === freeDrugReason);
  return reason !== undefined ? reason?.id : null;
};
export const getFreeDrugsReasonDescription = (id: number) => {
  const [reason] = FreeDrugReasonsLabels.filter(x => x.id === id);
  return reason !== undefined ? reason.label : null;
};
export const foundationTypeList = [
  { id: 3001, label: CopayCardTypes.pharmacyBenefit },
  { id: 3002, label: CopayCardTypes.creditCard },
  { id: 3003, label: CopayCardTypes.check },
];

export const internalFoundationTypeList = [
  { id: 3004, label: CopayCardTypes.pharmacyBenefit },
  { id: 3010, label: CopayCardTypes.accountPayable },
];

export const externalFoundationTypeList = [
  { id: 3007, label: CopayCardTypes.pharmacyBenefit },
  { id: 3008, label: CopayCardTypes.creditCard },
  { id: 3009, label: CopayCardTypes.check },
];

export const interventionCategoryList = [
  { label: 'Patient', value: 'Patient' },
  { label: 'Prescriber', value: 'Prescriber' },
  { label: 'Other', value: 'Other' },
];

export const interventionTypeList = [
  { label: 'Adherence', value: 'Adherence' },
  { label: 'Care Plan', value: 'Care Plan' },
  { label: 'DDI', value: 'DDI' },
  { label: 'ADR', value: 'ADR' },
  { label: 'Regimen', value: 'Regimen' },
  { label: 'Referral of service', value: 'Referral of service' },
  { label: 'Lab', value: 'Lab' },
];

export const emailTypes = [
  { label: 'Personal', value: 'Personal' },
  { label: 'Business', value: 'Business' },
  { label: 'Secondary', value: 'Secondary' },
];

export const phoneTypes = [
  { label: 'Home', value: 'Home' },
  { label: 'Mobile', value: 'Mobile' },
  { label: 'Business', value: 'Business' },
];

export const addressTypes = [
  { label: 'Home', value: 'Home' },
  { label: 'Work', value: 'Work' },
  { label: 'Mailing', value: 'Mailing' },
  { label: 'Summer', value: 'Summer' },
  { label: 'Winter', value: 'Winter' },
  { label: 'Billing', value: 'Billing' },
  { label: 'Medicare', value: 'Medicare' },
  { label: 'Provider', value: 'Provider' },
];

export const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'New Brunswick', value: 'NB' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Alberta', value: 'AB' },
  { label: 'Newfoundland and Labrador', value: 'NL' },
];

export const contactMethodTypes = [
  { label: 'Phone', value: ContactMethodTypes.Phone },
  { label: 'Email', value: ContactMethodTypes.Email },
  { label: 'SMS', value: ContactMethodTypes.SMS },
  { label: 'EHR Message', value: ContactMethodTypes.EHRMessage },
];

export const noteTypes = [
  { label: 'Patient Note', value: NoteTypes.Patient },
  { label: 'Patient Communication', value: NoteTypes.PatientCommunication },
];

export const noteMentionTypes = [
  { label: 'User', value: 1 },
  { label: 'Service Group', value: 2 },
];

// TODO - This list should load from db
export const noteTagTypes = [
  { label: NoteTagLabels.Patient, value: NoteTagTypes.Patient, displayLabel: 'Patient' },
  { label: NoteTagLabels.Therapy, value: NoteTagTypes.Therapy, displayLabel: 'Therapy' },
  { label: NoteTagLabels.Clinical, value: NoteTagTypes.Clinical, displayLabel: 'Clinical' },
  {
    label: NoteTagLabels.Demographic,
    value: NoteTagTypes.Demographic,
    displayLabel: 'Demographic',
  },
  { label: NoteTagLabels.Financial, value: NoteTagTypes.Financial, displayLabel: 'Financial' },
  {
    label: NoteTagLabels.MedicalInsurance,
    value: NoteTagTypes.MedicalInsurance,
    displayLabel: 'Medical Insurance',
  },
  {
    label: NoteTagLabels.PbmInsurance,
    value: NoteTagTypes.PbmInsurance,
    displayLabel: 'PBM Insurance',
  },
  { label: NoteTagLabels.Income, value: NoteTagTypes.Income, displayLabel: 'Income' },
  { label: NoteTagLabels.Contact, value: NoteTagTypes.Contact, displayLabel: 'contact' },
  { label: NoteTagLabels.BI, value: NoteTagTypes.BI, displayLabel: 'BI' },
  { label: NoteTagLabels.PA, value: NoteTagTypes.PA, displayLabel: 'Prior Authorization' },
  { label: NoteTagLabels.FA, value: NoteTagTypes.FA, displayLabel: 'Financial Assistance' },
  { label: NoteTagLabels.CA, value: NoteTagTypes.CA, displayLabel: 'CA' },
  { label: NoteTagLabels.CI, value: NoteTagTypes.CI, displayLabel: 'CI' },
  { label: NoteTagLabels.FC, value: NoteTagTypes.FC, displayLabel: 'Fill Coordination' },
  { label: NoteTagLabels.TPR, value: NoteTagTypes.TPR, displayLabel: 'Third Party Referral' },
  { label: NoteTagLabels.DC, value: NoteTagTypes.DC, displayLabel: 'Data Collect' },
  {
    label: NoteTagLabels.FDC,
    value: NoteTagTypes.FDC,
    displayLabel: 'Fill Delivery Confirmation',
  },
  { label: NoteTagLabels.INT, value: NoteTagTypes.INT, displayLabel: 'Intervention' },
  { label: NoteTagLabels.CSL, value: NoteTagTypes.CSL, displayLabel: 'Counseling' },
  { label: NoteTagLabels.AR, value: NoteTagTypes.AR, displayLabel: 'Appointment Referral' },
  { label: NoteTagLabels.OR, value: NoteTagTypes.OR, displayLabel: 'Outreach' },
  { label: NoteTagLabels.MR, value: NoteTagTypes.MR, displayLabel: 'Medication Review' },
  { label: NoteTagLabels.QRE, value: NoteTagTypes.QRE, displayLabel: 'Quality Related Event' },
  { label: DUR, value: NoteTagTypes.DUR, displayLabel: 'Drug Utilization Review' },
  { label: NoteTagLabels.RS, value: NoteTagTypes.RS, displayLabel: 'Risk Strat' },
];

// Combine this and the tag types.
export const documentTagTypes = noteTagTypes;

export const NOTE_TYPES = {
  in_person: 1,
  email: 2,
  sms: 3,
  phone_call: 4,
  mail: 5,
  fax: 6,
  ehr: 7,
};

export const noteCommunicationTypesScheduleOutreach = [
  { label: 'In Person', value: NOTE_TYPES.in_person },
  { label: 'Phone', value: NOTE_TYPES.phone_call },
];

export const noteCommunicationTypes = [
  { label: 'In Person', value: NOTE_TYPES.in_person },
  { label: 'Email', value: NOTE_TYPES.email },
  { label: 'SMS', value: NOTE_TYPES.sms },
  { label: 'Phone', value: NOTE_TYPES.phone_call },
  { label: 'Letter', value: NOTE_TYPES.mail },
  { label: 'Fax', value: NOTE_TYPES.fax },
  { label: 'EHR', value: NOTE_TYPES.ehr },
];

export const otherCommunicationOptions = [
  { label: 'Provider', value: 'provider', lookupValue: 1 },
  { label: 'Insurance', value: 'insurance', lookupValue: 2 },
  { label: 'Care Team Member', value: 'care_team_member', lookupValue: 3 },
  { label: 'Pharmacy', value: 'pharmacy', lookupValue: 4 },
  { label: 'Other', value: 'other', lookupValue: 5 },
];

export const tprServices = [
  { label: 'Nursing - Patient Support Program', id: 1 },
  { label: 'FA - Copay assistance, PAP (free drug)', id: 2 },
  { label: 'Benefit Investigation', id: 3 },
  { label: 'Sharps support', id: 4 },
  { label: 'Injection device', id: 5 },
  {
    label: 'Referral services - Prescription triage, MD Referral, Infusion site',
    id: 6,
  },
  { label: 'Supplemental supply', id: 7 },
  { label: 'Screening - labs, medical screening', id: 8 },
  { label: 'In-home services - FDO, Injection training', id: 9 },
];

export const noteCommunicationResults = [
  {
    label: 'LVM',
    value: 1,
    communication_type_id: 4,
    requires_action: 1,
  },
  {
    label: 'Reached Contact',
    value: 2,
    communication_type_id: 4,
    requires_action: 0,
  },
  {
    label: 'Unable to Reach Contact',
    value: 3,
    communication_type_id: 4,
    requires_action: 1,
  },
];

export const counselingReasons = [
  { label: 'Opt Out', value: 1 },
  { label: 'Therapy Complete', value: 2 },
  { label: 'Patient Opt Out', value: 3 },
  { label: 'Other', value: 4 },
];

export const counselingTaskTypes = [
  { id: CounselingTaskType.Initial, name: 'Initial' },
  { id: CounselingTaskType.Ongoing, name: 'Ongoing' },
  { id: CounselingTaskType.CheckIn, name: 'Check-In' },
];

export const interventionReasonIds = {
  INTENTIONAL_OTHER: 1002,
  INTENTIONAL_PRESCRIBER_HELD: 1001,
  INTENTIONAL_ILLNESS: 1008,
  INTENTIONAL_PLANNED_MEDICAL_PROCEDURE: 1011,
  INTENTIONAL_DOSE_REDUCTION: 1012,
  UNINTENTIONAL_OTHER: 1010,
};

export const interventionInfectionTypesIds = {
  C_DIFF: 1,
  COVID_19: 2,
  HBV_OR_HCV: 3,
  MRSA: 4,
  SHINGLES_CHICKEN_POX: 5,
  STREPTOCOCCUS: 6,
  TB: 7,
  VRE: 8,
  WOUNDS: 9,
  UNKNOWN: 10,
  OTHER: 11,
};

export const interventionRecommendationIds = {
  DDI_OTHER: 3007,
  REGIMEN_OTHER: 5006,
  LAB_OTHER: 7001,
};

export const therapyAdministrationStatusCheckboxes = [
  { text: 'Pre-Therapy', id: 1 },
  { text: 'On Therapy', id: 2 },
  { text: 'On Hold', id: 4 },
  { text: 'Discontinued', id: 8 },
  { text: 'No Go', id: 16 },
];

export const therapyEnrollmentStatusCheckboxes = [
  { text: 'Referral', id: 1 },
  { text: 'Therapy Enrolled', id: 2 },
  { text: 'Therapy Unenrolled', id: 4 },
];

export const taskStatusCheckboxes = [
  { id: 1, text: 'Outstanding' },
  { id: 2, text: 'In Progress' },
  { id: 4, text: 'Done' },
  { id: 8, text: 'Archived' },
];

export const methodOptions = [
  { id: MethodOptions.InPerson, name: 'In Person' },
  { id: MethodOptions.OnThePhone, name: 'On the Phone' },
  { id: MethodOptions.ChartReview, name: 'Chart Review' },
  { id: MethodOptions.SecureLink, name: 'Secure Link' },
];

export const reports = [
  {
    label: 'Therapy PA Turnaround Time',
    value: 101,
    type: 'metrics',
    section: 'left-0',
    category: 'pa',
  },
  {
    label: 'Therapy Administration',
    value: 201,
    type: 'metrics',
    section: 'right-0',
    category: 'therapyAdmin',
  },
  {
    label: 'Therapy On Hold Reason',
    value: 202,
    type: 'chart',
    section: 'right-0',
    category: 'therapyAdmin',
  },
  {
    label: 'Therapy No Go Reason',
    value: 203,
    type: 'chart',
    section: 'right-0',
    category: 'therapyAdmin',
  },
  {
    label: 'Therapy Discontinued Reason',
    value: 204,
    type: 'chart',
    section: 'right-0',
    category: 'therapyAdmin',
  },
  {
    label: 'Therapy Enrollment',
    value: 205,
    type: 'metrics',
    section: 'left-1',
    category: 'therapyEnrollment',
  },
  {
    label: 'Therapy Enrollment Breakdown',
    value: 206,
    type: 'chart',
    section: 'left-1',
    category: 'therapyEnrollment',
  },
  {
    label: 'Therapy Discontinued Enrollment Breakdown',
    value: 215,
    type: 'chart',
    section: 'left-1',
    category: 'therapyEnrollment',
  },
  {
    label: 'Therapy Dispensing Enrollment',
    value: 207,
    type: 'metrics',
    section: 'left-2',
    category: 'therapyDispensing',
  },
  {
    label: 'Therapy Dispensing Enrollment Completed Breakdown',
    value: 208,
    type: 'chart',
    section: 'left-2',
    category: 'therapyDispensing',
  },
  {
    label: 'Therapy Dispensing Enrollment Incomplete Reason',
    value: 213,
    type: 'chart',
    section: 'left-2',
    category: 'therapyDispensing',
  },
  {
    label: 'Therapy Dispensing Opt-out Reason',
    value: 209,
    type: 'chart',
    section: 'left-2',
    category: 'therapyDispensing',
  },
  {
    label: 'Therapy Clinical Support Enrollment',
    value: 210,
    type: 'metrics',
    section: 'left-3',
    category: 'therapyClinical',
  },
  {
    label: 'Therapy Clinical Support Enrollment Completed Breakdown',
    value: 211,
    type: 'chart',
    section: 'left-3',
    category: 'therapyClinical',
  },
  {
    label: 'Therapy Clinical Support Enrollment Incomplete Reason',
    value: 214,
    type: 'chart',
    section: 'left-3',
    category: 'therapyClinical',
  },
  {
    label: 'Therapy Clinical Support Opt-out Reason',
    value: 212,
    type: 'chart',
    section: 'left-3',
    category: 'therapyClinical',
  },
  {
    label: 'Appointment Status',
    value: 301,
    type: 'metrics',
    section: 'right-1',
    category: 'ar',
  },
  {
    label: 'Appointment Will Not Meet',
    value: 302,
    type: 'chart',
    section: 'right-1',
    category: 'ar',
  },
  {
    label: 'Appointment Unable To Meet',
    value: 309,
    type: 'chart',
    section: 'right-1',
    category: 'ar',
  },
  {
    label: 'Appointment Scheduled',
    value: 303,
    type: 'chart',
    section: 'right-1',
    category: 'ar',
  },
  {
    label: 'Appointment Completed',
    value: 304,
    type: 'chart',
    section: 'right-1',
    category: 'ar',
  },
  {
    label: 'Appointment Therapy',
    value: 305,
    type: 'metrics',
    section: 'right-2',
    category: 'arTherapy',
  },
  {
    label: 'Appointment Therapy On Hold Reason',
    value: 306,
    type: 'chart',
    section: 'right-2',
    category: 'arTherapy',
  },
  {
    label: 'Appointment Therapy No Go Reason',
    value: 307,
    type: 'chart',
    section: 'right-2',
    category: 'arTherapy',
  },
  {
    label: 'Appointment Therapy Discontinued Reason',
    value: 308,
    type: 'chart',
    section: 'right-2',
    category: 'arTherapy',
  },
];

export const therapyEnrollmentStatuses = [
  { label: 'Referral', value: 12001 },
  { label: 'Therapy Enrolled', value: 12002 },
  { label: 'Therapy Unenrolled', value: 12003 },
];

export const therapyAdministrationStatuses = [
  { label: 'Pre-Therapy', value: 1001 },
  { label: 'On Therapy', value: TherapyAdministrationStatuses.OnTherapy },
  { label: 'On Hold', value: 1003 },
  { label: 'Discontinued', value: 1004 },
  { label: 'No-Go', value: 1005 },
];

export const therapyClinicalDispensingReasons = [
  {
    label: ClinicalSupportOptOutReasonLabel.CdmGraduationClinicianDecision,
    value: ClinicalSupportOptOutReasonValue.CdmGraduationClinicianDecision,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.CdmGraduationCriteriaMet,
    value: ClinicalSupportOptOutReasonValue.CdmGraduationCriteriaMet,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.CdmEnrollmentCriteriaNotMet,
    value: ClinicalSupportOptOutReasonValue.CdmEnrollmentCriteriaNotMet,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.NoLongerDispensing,
    value: ClinicalSupportOptOutReasonValue.NoLongerDispensing,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.ClinicianRecommendationComplexity,
    value: ClinicalSupportOptOutReasonValue.ClinicianRecommendationComplexity,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.PatientPerceptionLongTimeUse,
    value: ClinicalSupportOptOutReasonValue.PatientPerceptionLongTimeUse,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.PatientPerceptionTherapyControl,
    value: ClinicalSupportOptOutReasonValue.PatientPerceptionTherapyControl,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.TimeConstraint,
    value: ClinicalSupportOptOutReasonValue.TimeConstraint,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.NotBeingSeenAtOurClinic,
    value: ClinicalSupportOptOutReasonValue.NotBeingSeenAtOurClinic,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.PatientIsUnreachable,
    value: ClinicalSupportOptOutReasonValue.PatientIsUnreachable,
  },
  {
    label: ClinicalSupportOptOutReasonLabel.NotClinicallyMonitoredTherapyAtThisSite,
    value: ClinicalSupportOptOutReasonValue.NotClinicallyMonitoredTherapyAtThisSite,
  },
  { label: ClinicalSupportOptOutReasonLabel.Other, value: ClinicalSupportOptOutReasonValue.Other },
];

export const userSelectableTherapyClinicalDispensingReasons =
  therapyClinicalDispensingReasons.filter(
    reason =>
      ![
        ClinicalSupportOptOutReasonValue.CdmGraduationCriteriaMet,
        ClinicalSupportOptOutReasonValue.CdmEnrollmentCriteriaNotMet,
        ClinicalSupportOptOutReasonValue.NoLongerDispensing,
      ].includes(reason.value),
  );
export const userNotSelectableTherapyClinicalSupportReasons = [
  ClinicalSupportOptOutReasonValue.CdmGraduationCriteriaMet,
  ClinicalSupportOptOutReasonValue.CdmEnrollmentCriteriaNotMet,
];

type ClinicalSupportReasons = {
  label: ClinicalSupportOptOutReasonLabel;
  value: ClinicalSupportOptOutReasonValue;
  isDisabled?: boolean;
};
export const getUserSelectableTherapyClinicalDispensingReasons = (
  selectedOption: ClinicalSupportOptOutReasonValue[],
): ClinicalSupportReasons[] =>
  therapyClinicalDispensingReasons.reduce((options: ClinicalSupportReasons[], option) => {
    const disableClinicalSupportOption = userNotSelectableTherapyClinicalSupportReasons.includes(
      option.value,
    );
    if (
      !userNotSelectableTherapyClinicalSupportReasons.includes(option.value) ||
      selectedOption?.includes(option.value)
    ) {
      options.push({ ...option, isDisabled: disableClinicalSupportOption });
    }
    return options;
  }, []);

export const cdmOptInClinicalReasons = [
  {
    label: ClinicalSupportOptInReasonLabel.ProviderReferredIntoCdm,
    value: ClinicalSupportOptInReasonValue.ProviderReferredIntoCdm,
  },
];

export const methodOfDelivery = [
  { label: DeliveryMethodLabel.PickUp, value: DeliveryMethodValue.PickUp },
  { label: DeliveryMethodLabel.FedEx, value: DeliveryMethodValue.FedEx, disabled: false },
  { label: DeliveryMethodLabel.Ups, value: DeliveryMethodValue.Ups },
  { label: DeliveryMethodLabel.Usps, value: DeliveryMethodValue.Usps },
  { label: DeliveryMethodLabel.Courier, value: DeliveryMethodValue.Courier },
  { label: DeliveryMethodLabel.InternalDelivery, value: DeliveryMethodValue.InternalDelivery },
];

export const fcInProgressReasons = [
  { label: 'Waiting on payment info', value: 1 },
  { label: 'Waiting on lab results', value: 2 },
  { label: 'Waiting on prescription from provider', value: 3 },
  { label: 'Waiting on user to complete', value: 4 },
  { label: 'DUR Review needed', value: 5 },
];

export const taskStatusesExceptionToShowInFilters = [STATUS_FA_ALREADY_IN_PLACE];

export const deliveryShippingMethodMap: Record<number, { label: string; value: string }[]> = {
  [DeliveryMethodValue.FedEx]: [
    { label: 'FedEx Ground Business', value: 'fedex_ground_business' },
    { label: 'FedEx Ground Home', value: 'fedex_ground_home' },
    { label: 'FedEx First Overnight', value: 'fedex_first_overnight' },
    { label: 'FedEx Priority Overnight', value: 'fedex_priority_overnight' },
    { label: 'FedEx Standard Overnight', value: 'fedex_standard_overnight' },
    { label: 'FedEx 2Day AM', value: 'fedex_2day_am' },
    { label: 'FedEx 2Day', value: 'fedex_2day' },
  ],
  [DeliveryMethodValue.Ups]: [
    { label: 'UPS Ground', value: 'ups_ground' },
    { label: 'UPS Next Day Air Saver', value: 'ups_next_day_air_saver' },
    { label: 'UPS 2nd Day Air', value: 'ups_2nd_day_air' },
    { label: 'UPS Next Day Air', value: 'ups_next_day_air' },
    { label: 'UPS Ground - Saturday', value: 'ups_ground_saturday' },
    { label: 'UPS 2nd Day Air - Saturday', value: 'ups_2nd_day_air_saturday' },
    { label: 'UPS Next Day Air - Saturday', value: 'ups_next_day_air_saturday' },
  ],
  [DeliveryMethodValue.Usps]: [{ label: 'Priority Mail', value: 'usps' }],
};

export const ancillarySupplies = [
  { label: 'Sharps Container', value: AncillarySupplyValues.SharpsContainer },
  { label: 'Pill Organizer', value: AncillarySupplyValues.PillOrganizer },
  { label: 'Easy Open Caps', value: AncillarySupplyValues.EasyOpenCaps },
  { label: 'Alcohol Swabs', value: AncillarySupplyValues.AlcoholSwabs },
  { label: 'Adhesive Bandages', value: AncillarySupplyValues.AdhesiveBandages },
  { label: 'Adherence Packaging', value: AncillarySupplyValues.AdherencePackaging },
  { label: 'Glucose Logs', value: AncillarySupplyValues.GlucoseLogs },
  { label: 'Oral Syringe', value: AncillarySupplyValues.OralSyringe },
  { label: 'Medicine Dispensing Cup', value: AncillarySupplyValues.MedicineDispensingCup },
  { label: 'Not Needed', value: AncillarySupplyValues.NotNeeded },
  { label: 'Cotton Balls', value: AncillarySupplyValues.CottonBalls },
  { label: 'Bottle Stoppers For Liquids', value: AncillarySupplyValues.BottleStoppersForLiquids },
];

export const defaultPreferencesAncillarySupplies = [
  AncillarySupplyValues.AdherencePackaging,
  AncillarySupplyValues.EasyOpenCaps,
];

export enum ExternalDispensingPharmacyReasons {
  LDD = 1,
  providerAdministered = 2,
  payerLockout = 3,
  freeDrugProgram = 4,
  patientChoiceLikesCurrentPharmacy = 5,
  patientChoiceBetterOOPDS = 6,
  patientChoiceOther = 7,
  clinicianRecommendationComplexity = 8,
  longTermCare = 9,
  other = 10,
  pharmacyNotLicensed = 11,
  outOfServiceArea = 12,
  notBeingSeenAtOurClinicLimitedAccessToClinicalInfo = 13,
}

export enum TherapyLookupStatusReasons {
  patientDeceased = 1022,
}

export const excludedReasons = [
  { label: 'Provider outside of health system', value: 'Provider outside of health system' },
  { label: 'Manager Deprioritize', value: 'Manager Deprioritize' },
  { label: 'Therapy Discontinued/Completed', value: 'Therapy Discontinued' },
  { label: 'Other', value: 'Other' },
];

export const unableToSeeReasons = [
  { label: 'On the phone', value: 'On the phone' },
  { label: 'With another patient', value: 'With another patient' },
  { label: 'Missed patient in office', value: 'Missed patient in office' },
  { label: 'Doing other work', value: 'Doing other work' },
];

export const submitMethods = [
  { label: 'Phone', value: 'Phone' },
  { label: 'Form', value: 'Form' },
];

export const medicationStatusCodes = [
  { label: 'Active', value: MedicationStatus.Active },
  { label: 'Canceled', value: MedicationStatus.Canceled },
  { label: 'Completed', value: MedicationStatus.Completed },
  { label: 'Deleted', value: MedicationStatus.Deleted },
  { label: 'Discontinued', value: MedicationStatus.Discontinued },
  { label: 'Entered in Error', value: MedicationStatus.EnteredInError },
  { label: 'Intended', value: MedicationStatus.Intended },
  { label: 'On-hold', value: MedicationStatus.OnHold },
  { label: 'Other', value: MedicationStatus.Other },
];

export const appointmentReportIds = [301, 302, 303, 304, 309];

export const yesNo10 = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];

export const activeInactive10 = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

export const yesNoTrueFalse: BooleanOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const supportedTasksTabTypes = [
  'FDC',
  'FC',
  'CSL',
  'PA',
  'FA',
  'MR',
  'INT',
  'TPR',
  'OR',
  'DC',
  'AR',
  'QRE',
  RS,
];

export const emrStatusDropdown: { label: string; value: string }[] = [
  { label: 'active', value: 'active' },
  { label: 'discontinued', value: 'discontinued' },
];

export const contactOptions = {
  NOT_SPECIFIED: 'Not Specified',
  OPT_IN: 'Opt in',
  OPT_OUT: 'Opt out',
};

export enum secureLinkAutomationStatus {
  NO = 0,
  MANUAL = 1,
  AUTOMATION = 2,
}

export const secureLinkAutomationOptions: Record<number, string> = {
  0: 'No (SMS Only)',
  1: 'Yes (Manual)',
  2: 'Yes (Automation)',
};

export const contactEnrollment = Object.values(contactOptions).map(value => ({
  label: value,
  value,
}));

export const secureLinkAutomation = Object.keys(secureLinkAutomationOptions).map(value => ({
  label: secureLinkAutomationOptions[Number(value)],
  value,
}));

/**
 * All lists come from dropdowns on
 * https://trellis.specialtyrxc.com/patient/benefitsinvestigationrxcheck.aspx
 */
export class BenefitsInvestigationFields {
  static CompoundCodes: ILabelValue[] = [
    { value: '0', label: '0 - Not Specified' },
    { value: '1', label: '1 - Not a Compound', default: true },
    { value: '2', label: '2 - Compound' },
  ];

  static PrescriptionOriginCode: ILabelValue[] = [
    { value: '0', label: '0 - Not Known' },
    { value: '1', label: '1 - Written' },
    { value: '2', label: '2 - Telephone' },
    { value: '3', label: '3 - Electronic', default: true },
    { value: '4', label: '4 - Facsimile' },
    { value: '5', label: '5 - Pharmacy' },
  ];

  static OtherCoverageCode: ILabelValue[] = [
    {
      value: '0',
      label: '0 - not specified by patient (default)',
      default: true,
    },
    { value: '1', label: '1 - no other coverage' },
    { value: '2', label: '2 - other coverage exists/billed-payment collected' },
    { value: '3', label: '3 - other coverage billed - claim not covered' },
    {
      value: '4',
      label: '4 - other coverage exists/bill-payment not collected',
    },
    {
      value: '8',
      label: '8 - claim is billing for pt financial responsibility',
    },
  ];

  static SpecialPackagingIndicator: ILabelValue[] = [
    { value: '0', label: '0 - Not Specified', default: true },
    { value: '1', label: '1 - Not Unit Dose' },
    { value: '2', label: '2 - Manufacturer Unit Dose' },
    { value: '3', label: '3 - Pharmacy Unit Dose' },
    {
      value: '4',
      label: '4 - Pharmacy Unit Dose Patient Compliance Packaging',
    },
    {
      value: '5',
      label: '5 - Pharmacy Multi-drug Patient Compliance Packaging',
    },
    { value: '6', label: '6 - Remote Device Unit Dose' },
    { value: '7', label: '7 - Remote Device Multi-drug compliance' },
    { value: '8', label: '8 - Manufacturer Unit of Package (not unit dose' },
  ];

  static UnitOfMeasure: ILabelValue[] = [
    { value: 'EA', label: 'EA - Each', default: true },
    { value: 'GM', label: 'GM - Grams' },
    { value: 'ML', label: 'ML - Milliliters' },
  ];

  static LevelOfService: ILabelValue[] = [
    { value: '0', label: '0 - Not Specified', default: true },
    { value: '1', label: '1 - Patient consultation - professional service' },
    { value: '2', label: '2 - Home delivery' },
    { value: '3', label: '3 - Emergency' },
    { value: '4', label: '4 - 24 hour service' },
    {
      value: '5',
      label: '5 - Patient consultation regarding generic product selection',
    },
    { value: '6', label: '6 - In-home service' },
  ];

  static IntermediaryAuthType: ILabelValue[] = [
    { value: '0', label: '0 - Not Specified', default: true },
    { value: '1', label: '1 - Intermediary Authorization' },
    { value: '99', label: '99 - Other Override' },
  ];

  static PaType: ILabelValue[] = [
    { value: '0', label: '0 - Not Specified', default: true },
    { value: '1', label: '1 - Prior Authorization' },
    { value: '2', label: '2 - Medical Certification' },
    { value: '3', label: '3 - EPSDT' },
    { value: '4', label: '4 - Exemption from Copay and/or Coinsurance' },
    { value: '5', label: '5 - Exemption from RX' },
    { value: '6', label: '6 - Family Planning Indicator' },
    { value: '7', label: '7 - TANF' },
    { value: '8', label: '8 - Payer Defined Exemption' },
    { value: '9', label: '9 - Emergency Preparedness' },
  ];

  static PharmServiceType: ILabelValue[] = [
    { value: '', label: 'Not Specified' },
    { value: '1', label: '1 - Community/Retail Pharmacy Services', default: true },
    { value: '2', label: '2 - Compounding Pharmacy Services' },
    { value: '3', label: '3 - Home Infusion Therapy Provider Services' },
    { value: '4', label: '4 - Institutional Pharmacy Services' },
    { value: '5', label: '5 - Long Term Care Pharmacy Services' },
    { value: '6', label: '6 - Mail Order Pharmacy Services' },
    { value: '7', label: '7 - Managed Care Organization Pharmacy Services' },
    { value: '8', label: '8 - Specialty Care Pharmacy Services' },
    { value: '99', label: '99 - Other' },
  ];

  static PlaceOfService: ILabelValue[] = [
    { value: '00', label: '00 - Not Specified' },
    { value: '01', label: '01 - Pharmacy', default: true },
    { value: '03', label: '03 - School' },
    { value: '04', label: '04 - Homeless Shelter' },
    { value: '05', label: '05 - Indian Health Service Free-standing' },
    { value: '06', label: '06 - Indian Health Service Provider-based' },
    { value: '07', label: '07 - Tribal 638 Free-standing' },
    { value: '08', label: '08 - Tribal 638 Provider-based' },
    { value: '09', label: '09 - Prison/Correctional Facility' },
    { value: '11', label: '11 - Office' },
    { value: '12', label: '12 - Home' },
    { value: '13', label: '13 - Assisted Living Facility' },
    { value: '14', label: '14 - Group Home' },
    { value: '15', label: '15 - Mobile Unit' },
    { value: '16', label: '16 - Temporary Lodging' },
    { value: '17', label: '17 - Walk-in Retail Health Clinic' },
    { value: '18', label: '18 - Place of Employment-Worksite' },
    { value: '19', label: '19 - Off Campus-Outpatient Hospital' },
    { value: '20', label: '20 - Urgent Care Facility' },
    { value: '21', label: '21 - Inpatient Hospital' },
    { value: '22', label: '22 - On Campus-Outpatient Hospital' },
    { value: '23', label: '23 - Emergency Room – Hospital' },
    { value: '24', label: '24 - Ambulatory Surgical Center' },
    { value: '25', label: '25 - Birthing Center' },
    { value: '26', label: '26 - Military Treatment Facility' },
    { value: '31', label: '31 - Skilled Nursing Facility' },
    { value: '32', label: '32 - Nursing Facility' },
    { value: '33', label: '33 - Custodial Care Facility' },
    { value: '34', label: '34 - Hospice' },
    { value: '41', label: '41 - Ambulance - Land' },
    { value: '42', label: '42 - Ambulance – Air or Water' },
    { value: '49', label: '49 - Independent Clinic' },
    { value: '50', label: '50 - Federally Qualified Health Center' },
    { value: '51', label: '51 - Inpatient Psychiatric Facility' },
    { value: '52', label: '52 - Psychiatric Facility-Partial Hospitalization' },
    { value: '53', label: '53 - Community Mental Health Center' },
    { value: '54', label: '54 - Intermediate Care Facility' },
    {
      value: '55',
      label: '55 - Residential Substance Abuse Treatment Facility',
    },
    { value: '56', label: '56 - Psychiatric Residential Treatment Center' },
    {
      value: '57',
      label: '57 - Non-residential Substance Abuse Treatment Facility',
    },
    { value: '60', label: '60 - Mass Immunization Center' },
    {
      value: '61',
      label: '61 - Comprehensive Inpatient Rehabilitation Facility',
    },
    {
      value: '62',
      label: '62 - Comprehensive Outpatient Rehabilitation Facility',
    },
    { value: '65', label: '65 - End-Stage Renal Disease Treatment Facility' },
    { value: '71', label: '71 - Public Health Clinic' },
    { value: '72', label: '72 - Rural Health Clinic' },
    { value: '81', label: '81 - Independent Laboratory' },
    { value: '99', label: '99 - Other Place of Service</' },
  ];

  static SubClarificationCode: ILabelValue[] = [
    { value: '', label: 'Not Specified' },
    { value: '1', label: '1 - No Override' },
    { value: '10', label: '10 - Meets Plan Limitations' },
    { value: '11', label: '11 - Certification on File' },
    { value: '12', label: '12 - DME Replacement Indicator' },
    {
      value: '13',
      label: '13 - Payer-Recognized Emergency/Disaster Assistance Request',
    },
    { value: '14', label: '14 - LTC Leave of Absence' },
    { value: '15', label: '15 - LTC Replacement Medication' },
    {
      value: '16',
      label: '16 - LTC Emergency box (kit) or auto disp. machine',
    },
    { value: '17', label: '17 - LTC Emergency supply remainder' },
    { value: '18', label: '18 - LTC Patient Admit/Readmit Indicator' },
    { value: '19', label: '19 - Split Billing' },
    { value: '2', label: '2 - Other Override' },
    { value: '20', label: '20 - 340B' },
    {
      value: '21',
      label: '21 - LTC dispensing: 14 days or less not applicable',
    },
    { value: '22', label: '22 - LTC dispensing: 7 days' },
    { value: '23', label: '23 - LTC dispensing: 4 days' },
    { value: '24', label: '24 - LTC dispensing: 3 days' },
    { value: '25', label: '25 - LTC dispensing: 2 days' },
    { value: '26', label: '26 - LTC dispensing: 1 day' },
    { value: '27', label: '27 - LTC dispensing: 4-3 days' },
    { value: '28', label: '28 - LTC dispensing: 2-2-3 days' },
    { value: '29', label: '29 - LTC dispensing: daily and 3-day weekend' },
    { value: '3', label: '3 - Vacation Supply' },
    { value: '30', label: '30 - LTC dispensing: Per shift dispensing' },
    { value: '31', label: '31 - LTC dispensing: Per med pass dispensing' },
    { value: '32', label: '32 - LTC dispensing: PRN on demand' },
    {
      value: '33',
      label: '33 - LTC dispensing: 7 day or less cycle not otherwise represented',
    },
    { value: '34', label: '34 - LTC dispensing: 14 days dispensing' },
    {
      value: '35',
      label:
        '35 - LTC dispensing: 8-14 day dispensing method not listed above - 8-14-Day dispensing cycle not otherwise represented',
    },
    {
      value: '36',
      label: '36 - LTC dispensing: dispensed outside short cycle',
    },
    { value: '4', label: '4 - Lost Prescription' },
    {
      value: '42',
      label: '42 - Prescriber ID Submitted has been validated, is active',
    },
    {
      value: '43',
      label:
        '43 - For prescriber ID submitted, associated prescriber DEA Renewed, or In Progress, DEA Authorized Prescriptive Right',
    },
    {
      value: '44',
      label:
        '44 - For prescriber ID submitted, associated prescriber DEA recently licensed or re-activated',
    },
    {
      value: '45',
      label: '45 - For prescriber ID submitted, associated DEA is a valid Hospital DEA with Suffix',
    },
    {
      value: '46',
      label:
        '46 - For prescriber ID submitted, and associated prescriber DEA, the DEA has authorized prescriptive right for this drug DEA Class',
    },
    { value: '5', label: '5 - Therapy Change' },
    { value: '6', label: '6 - Starter Dose' },
    { value: '7', label: '7 - Medically Necessary' },
    { value: '8', label: '8 - Process Compound For Approved Ingredients' },
    { value: '9', label: '9 - Encounters' },
    { value: '99', label: '99 - Other' },
  ];

  static DawCodes: ILabelValue[] = [
    { value: '0', label: '0 - No Product Selection Indicated', default: true },
    { value: '1', label: '1 - Substitution Not Allowed by Prescriber' },
    {
      value: '2',
      label: '2 - Substitution Allowed-Patient Requested Product Dispensed',
    },
    {
      value: '3',
      label: '3 - Substitution Allowed-Pharmacist Selected Product Dispensed',
    },
    { value: '4', label: '4 - Substitution Allowed-Generic Drug Not in Stock' },
    {
      value: '5',
      label: '5 - Substitution Allowed-Brand Drug Dispensed as a Generic',
    },
    { value: '6', label: '6 - Override' },
    {
      value: '7',
      label: '7 - Substitution Not Allowed-Brand Drug Mandated by Law',
    },
    {
      value: '8',
      label: '8 - Substitution Allowed-Generic Drug Not Available in Marketplace',
    },
    {
      value: '9',
      label: '9 - Substitution Allowed By Prescriber but Plan Requests Brand',
    },
  ];

  static EligibilityClarificationCodes: ILabelValue[] = [
    { value: '0', label: '0 - Not Specified', default: true },
    {
      value: '1',
      label: '1 - No Override – Eligibility denial cannot be superseded',
    },
    {
      value: '2',
      label: '2 - Override – Eligibility denial is being superseded',
    },
    {
      value: '3',
      label:
        '3 - Full Time Student – A dependent child enrolled as a full time student at a school',
    },
    {
      value: '4',
      label: '4 - Disabled Dependent – A dependent, regardless of age, who is disabled',
    },
    {
      value: '5',
      label: '5 - Dependent Parent - A dependent who is the parent',
    },
    {
      value: '6',
      label: '6 - Significant Other – Partner other than the spouse',
    },
  ];

  static DurReasonForService: ILabelValue[] = [
    { value: 'AD', label: 'AD - Additional Drug Needed', default: true },
    { value: 'AN', label: 'AN - Prescription Authentication' },
    { value: 'AR', label: 'AR - Adverse Drug Reaction' },
    { value: 'AT', label: 'AT - Additive Toxicity' },
    { value: 'CD', label: 'CD - Chronic Disease Management' },
    { value: 'CH', label: 'CH - Call Help Desk' },
    { value: 'CS', label: 'CS - Patient Complaint/Symptom' },
    { value: 'DA', label: 'DA - Drug-Allergy' },
    { value: 'DC', label: 'DC - Drug-Disease (Inferred' },
    { value: 'DD', label: 'DD - Drug-Drug Interaction' },
    { value: 'DF', label: 'DF - Drug-Food interaction' },
    // TODO: Check if we need to add DDCI here
    { value: 'DI', label: 'DI - Drug Incompatibility' },
    { value: 'DL', label: 'DL - Drug-Lab Conflict' },
    { value: 'DM', label: 'DM - Apparent Drug Misuse' },
    { value: 'DR', label: 'DR - Dose Range Conflict' },
    { value: 'DS', label: 'DS - Tobacco Use' },
    { value: 'ED', label: 'ED - Patient Education/Instruction' },
    { value: 'ER', label: 'ER - Overuse' },
    { value: 'EX', label: 'EX - Excessive Quantity' },
    { value: 'HD', label: 'HD - High Dose' },
    { value: 'IC', label: 'IC - Iatrogenic Condition' },
    { value: 'ID', label: 'ID - Ingredient Duplication' },
    { value: 'LD', label: 'LD - Low Dose' },
    { value: 'LK', label: 'LK - Lock In Recipient' },
    { value: 'LR', label: 'LR - Underuse' },
    { value: 'MC', label: 'MC - Drug-Disease (Reported' },
    { value: 'MN', label: 'MN - Insufficient Duration' },
    { value: 'MS', label: 'MS - Missing Information/Clarification' },
    { value: 'MX', label: 'MX - Excessive Duration' },
    { value: 'NA', label: 'NA - Drug Not Available' },
    { value: 'NC', label: 'NC - Non-covered Drug Purchase' },
    { value: 'ND', label: 'ND - New Disease/Diagnosis' },
    { value: 'NF', label: 'NF - Non-Formulary Drug' },
    { value: 'NN', label: 'NN - Unnecessary Drug' },
    { value: 'NP', label: 'NP - New Patient Processing' },
    { value: 'NR', label: 'NR - Lactation/Nursing Interaction' },
    { value: 'NS', label: 'NS - Insufficient Quantity' },
    { value: 'OH', label: 'OH - Alcohol Conflict' },
    { value: 'PA', label: 'PA - Drug-Age' },
    { value: 'PC', label: 'PC - Patient Question/Concern' },
    { value: 'PG', label: 'PG - Drug-Pregnancy' },
    { value: 'PH', label: 'PH - Preventive Health Care' },
    { value: 'PN', label: 'PN - Prescriber Consultation' },
    { value: 'PP', label: 'PP - Plan Protocol' },
    { value: 'PR', label: 'PR - Prior Adverse Reaction' },
    { value: 'PS', label: 'PS - Product Selection Opportunity' },
    { value: 'RE', label: 'RE - Suspected Environmental Risk' },
    { value: 'RF', label: 'RF - Health Provider Referral' },
    { value: 'SC', label: 'SC - Suboptimal Compliance' },
    { value: 'SD', label: 'SD - Suboptimal Drug/Indication' },
    { value: 'SE', label: 'SE - Side Effect' },
    { value: 'SF', label: 'SF - Suboptimal Dosage Form' },
    { value: 'SR', label: 'SR - Suboptimal Regimen' },
    { value: 'SX', label: 'SX - Drug-Gender' },
    { value: 'TD', label: 'TD - Therapeutic' },
    { value: 'TN', label: 'TN - Laboratory Test Needed' },
    { value: 'TP', label: 'TP - Payer/Processor Question' },
    { value: 'UD', label: 'UD - Duplicate Drug' },
  ];

  static DurProfessionalService: ILabelValue[] = [
    { value: '00', label: '00 - No intervention', default: true },
    { value: 'AS', label: 'AS - Patient assessment' },
    { value: 'CC', label: 'CC - Coordination of care' },
    { value: 'DE', label: 'DE - Dosing evaluation/determination' },
    { value: 'DP', label: 'DP - Dosage evaluated' },
    { value: 'FE', label: 'FE - Formulary enforcement' },
    { value: 'GP', label: 'GP - Generic product selection' },
    { value: 'M0', label: 'M0 - Prescriber consulted' },
    { value: 'MA', label: 'MA - Medication administration' },
    { value: 'MB', label: 'MB - Overriding benefit' },
    { value: 'MP', label: 'MP - Patient will be monitored' },
    { value: 'MR', label: 'MR - Medication review' },
    { value: 'P0', label: 'P0 - Patient consulted' },
    { value: 'PA', label: 'PA - Previous patient tolerance' },
    { value: 'PE', label: 'PE - Patient education/instruction' },
    { value: 'PH', label: 'PH - Patient medication history' },
    { value: 'PM', label: 'PM - Patient monitoring' },
    { value: 'PT', label: 'PT - Perform laboratory test' },
    { value: 'R0', label: 'R0 - Pharmacist consulted other source' },
    { value: 'RT', label: 'RT - Recommend laboratory test' },
    { value: 'SC', label: 'SC - Self-care consultation' },
    { value: 'SW', label: 'SW - Literature search/review' },
    { value: 'TC', label: 'TC - Payer/processor consulted' },
    { value: 'TH', label: 'TH - Therapeutic product interchange' },
  ];

  static DurResultOfService: ILabelValue[] = [
    { value: '00', label: '00 - Not Specified', default: true },
    { value: '1A', label: '1A - Filled As Is, False Positive' },
    { value: '1B', label: '1B - Filled Prescription As Is' },
    { value: '1C', label: '1C - Filled, With Different Dose' },
    { value: '1D', label: '1D - Filled, With Different Directions' },
    { value: '1E', label: '1E - Filled, With Different Drug' },
    { value: '1F', label: '1F - Filled, With Different Quantity' },
    { value: '1G', label: '1G - Filled, With Prescriber Approval' },
    { value: '1H', label: '1H - Brand-to-Generic Change' },
    { value: '1J', label: '1J - Rx-to-OTC Change' },
    { value: '1K', label: '1K - Filled with Different Dosage Form' },
    { value: '2A', label: '2A - Prescription Not Filled' },
    { value: '2B', label: '2B - Not Filled, Directions Clarified' },
    { value: '3A', label: '3A - Recommendation Accepted' },
    { value: '3B', label: '3B - Recommendation Not Accepted' },
    { value: '3C', label: '3C - Discontinued Drug' },
    { value: '3D', label: '3D - Regimen Changed' },
    { value: '3E', label: '3E - Therapy Changed' },
    { value: '3F', label: '3F - Therapy Changed-cost increased acknowledged' },
    { value: '3G', label: '3G - Drug Therapy Unchanged' },
    { value: '3H', label: '3H - Follow-Up/Report' },
    { value: '3J', label: '3J - Patient Referral' },
    { value: '3K', label: '3K - Instructions Understood' },
    { value: '3M', label: '3M - Compliance Aid Provided' },
    { value: '3N', label: '3N - Medication Administered' },
    { value: '4A', label: '4A - Prescribed with acknowledgements' },
  ];

  static DurLevelOfEffort: ILabelValue[] = [
    { value: '0', label: '0 - Not Specified', default: true },
    { value: '11', label: '11 - Level 1 (Lowest)' },
    { value: '12', label: '12 - Level 2' },
    { value: '13', label: '13 - Level 3' },
    { value: '14', label: '14 - Level 4' },
    { value: '15', label: '15 - Level 5 (Highest)' },
  ];

  static DurCoAgentIdQualifier: ILabelValue[] = [
    { value: '', label: "Don't send", default: true },
    { value: '03', label: 'NDC' },
    { value: '21', label: 'ICD-10 CM' },
  ];

  static benefitsInvestigationGenderList: ILabelValue[] = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];

  static PharmacyProviderType: ILabelValue[] = [
    { value: '', label: 'Not Specified', default: true },
    { value: '01', label: '01 - DEA' },
    { value: '02', label: '02 - State License' },
    { value: '03', label: '03 - SSN' },
    { value: '04', label: '04 - Name' },
    { value: '05', label: '05 - NPI' },
    { value: '06', label: '06 - HIN' },
    { value: '07', label: '07 - State Issued' },
    { value: '99', label: '99 - Other' },
  ];

  static BasisOfCostDet: ILabelValue[] = [
    { value: '00', label: '00 - Default' },
    { value: '01', label: '01 - AWP (Average Wholesale Price', default: true },
    { value: '02', label: '02 - Local Wholesaler' },
    { value: '03', label: '03 - Direct' },
    { value: '04', label: '04 - EAC (Estimated Acquisition Cost' },
    { value: '05', label: '05 - Acquisition' },
    { value: '06', label: '06 - MAC (Maximum Allowable Cost' },
    { value: '07', label: '07 - Usual &amp; Customary' },
    { value: '08', label: '08 - 340B' },
    { value: '09', label: '09 - Other' },
    { value: '10', label: '10 - ASP (Average Sales Price' },
    { value: '11', label: '11 - AMP (Average Manufacturer Price' },
    { value: '12', label: '12 - WAC (Wholesale Acquisition Cost' },
    { value: '13', label: '13 - Special Patient Pricing' },
  ];

  static PlaceOfResidence: ILabelValue[] = [
    { value: '0', label: '0 - Not Specified' },
    { value: '1', label: '1 - Home', default: true },
    { value: '10', label: '10 - Residential Substance Abuse Treatment Facility' },
    { value: '11', label: '11 - Hospice' },
    { value: '12', label: '12 - Psychiatric Residential Treatment Facility' },
    { value: '13', label: '13 - Comprehensive Inpatient Rehabilitation Facility' },
    { value: '14', label: '14 - Homeless Shelter' },
    { value: '15', label: '15 - Correctional Institution' },
    { value: '2', label: '2 - Skilled Nursing Facility' },
    { value: '3', label: '3 - Nursing Facility' },
    { value: '4', label: '4 - Assisted Living Facility' },
    { value: '5', label: '5 - Custodial Care Facility' },
    { value: '6', label: '6 - Group Home' },
    { value: '7', label: '7 - Inpatient Psychiatric Facility' },
    { value: '8', label: '8 - Psychiatric Facility' },
    { value: '9', label: '9 - Intermediate Care Facility/Mentally Retarded' },
  ];
}

export const allergenTypes = [
  {
    value: 'is_environment',
    label: 'Environment',
  },
  {
    value: 'is_biologic',
    label: 'Biologic',
  },
  {
    value: 'is_food',
    label: 'Food',
  },
  {
    value: 'is_medication',
    label: 'Medication',
  },
];

export const noKnownAllergyNames = [NO_KNOWN_ALLERGIES, NO_ALLERGY_INFORMATION_AVAILABLE];

export const specialtyTypeNames = {
  'SP-PA': 'Specialty-Provider Administered (SP-PA)',
  'L': 'Specialty-Lite (L)',
  'SP': 'Specialty (SP)',
  'CDM': 'Chronic Disease Management',
  'REMOVED': 'No longer in GTD',
};

export const auditCategories = {
  Demographics: 'demographics',
  Financials: 'financials',
  Clinical: 'clinical',
  Therapies: 'therapies',
  Checklist: 'checklist',
};

export const therapyAudits = [
  THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE,
  ENSURE_THERAPY_MUST_MOVE_TO_UPDATE_STATUS,
  THERAPY_SHOULD_NOT_BE_ON_ON_HOLD_FOR_TOO_LONG,
  THERAPY_SHOULD_HAVE_START_DATE,
  SERVICE_ENROLLMENT_SHOULD_BE_SET,
];

export const auditContext = {
  Tab: 1,
  Sidebar: 2,
  Warning: 3,
};

export const auditResolutionStates = {
  NotResolved: 1,
  Resolved: 2,
  ResolvedByOverride: 3,
  NoLongerValid: 4,
  PatientDeceased: 5,
};

export const insuranceTypes = {
  Primary: 1,
  Secondary: 2,
  Tertiary: 3,
  Quarternary: 4,
};

export const taskListOrder = [
  THERAPIES,
  STAR,
  SMS,
  AR,
  DUR,
  MR,
  DC,
  PA,
  FA,
  CSL,
  FC,
  FDC,
  RS,
  INT,
  OR,
  TPR,
  QRE,
];

export const relationCodes: ILabelValue[] = [
  { value: '0', label: '0 - Not Specified' },
  { value: '1', label: '1 - Cardholder', default: true },
  { value: '2', label: '2 - Spouse' },
  { value: '3', label: '3 - Child' },
  { value: '4', label: '4 - Other' },
];

export const interventionTypeRelatedFields = [
  'additional_reason',
  'additional_recommendation',
  'drug_interaction_description',
  'interacting_drugs',
  'outcome',
  'outcome_type',
  'reason',
  'reason_end_date',
  'reason_start_date',
  'recommendation',
  'serious_adverse_event',
  'recommended_appropriate_vaccines',
];

export const communicationIngoingOutgoing: ILabelValue[] = [
  { label: 'Outgoing', value: '0' },
  { label: 'Incoming', value: '1' },
];

export const rsCancelReasons = [
  { label: 'Outside provider', value: RsCancelReasons.OutsideProvider },
  { label: 'Not targeted therapy', value: RsCancelReasons.NotTargetedTherapy },
  { label: 'ICD correction', value: RsCancelReasons.IcdCorrection },
];

export const financialTypeFilterValues = [
  {
    id: FinancialAssistanceTypes.ExternalFoundation,
    label: FinancialAssistanceTypes.ExternalFoundation,
    key: 'externalFoundation',
  },
  {
    id: FinancialAssistanceTypes.Foundation,
    label: FinancialAssistanceTypes.Foundation,
    key: 'foundation',
  },
  {
    id: FinancialAssistanceTypes.InternalFoundation,
    label: FinancialAssistanceTypes.InternalFoundation,
    key: 'internalFoundation',
  },
  {
    id: FinancialAssistanceTypes.CopayCard,
    label: FinancialAssistanceTypes.CopayCard,
    key: 'copay',
  },
  { id: FinancialAssistanceTypes.Mpap, label: FinancialAssistanceTypes.Mpap, key: 'mpap' },
];

export const internalFoundationFilterValues = [
  {
    id: FinancialAssistanceSubTypes.InternalFoundation_AccountPayable,
    label: CopayCardTypes.accountPayable,
    value: FinancialAssistanceSubTypes.InternalFoundation_AccountPayable,
    key: 'accountPayable',
  },
  {
    id: FinancialAssistanceSubTypes.InternalFoundation_PharmacyBenefit,
    label: CopayCardTypes.pharmacyBenefit,
    value: FinancialAssistanceSubTypes.InternalFoundation_PharmacyBenefit,
    key: 'pharmacyBenefit',
  },
];

export const getFinancialTypeFilterValueByKey = (key: string) =>
  financialTypeFilterValues.find(fa => fa.key === key);

export const qreIncidentTypes = [
  { label: 'Near Miss', value: 0 },
  { label: 'Error', value: 1 },
];

export const qreIncidentSubtypes = [
  { label: 'Med Error', value: 1 },
  { label: 'Shipping Error', value: 0 },
];

export const qreIncidentSubtypesForIncidentAndComplaints = [
  { value: '2', label: 'Billing' },
  { value: '1', label: 'Med Error' },
  { value: '0', label: 'Shipping Error' },
];

export const equalityOperators = [
  { value: '<', label: '< (Less than)' },
  { value: '<=', label: '<= (Less than or equal to)' },
  { value: '=', label: '= (Equal to)' },
  { value: '>', label: '> (Greater than)' },
  { value: '>=', label: '>= (Greater than or equal to)' },
];

export const daysMonths = [
  { value: 'Days', label: 'Days' },
  { value: 'Months', label: 'Months' },
];

export const monthsList = [
  { value: 1, label: '1 Month' },
  { value: 2, label: '2 Months' },
  { value: 3, label: '3 Months' },
  { value: 4, label: '4 Months' },
  { value: 5, label: '5 Months' },
  { value: 6, label: '6 Months' },
  { value: 7, label: '7 Months' },
  { value: 8, label: '8 Months' },
  { value: 9, label: '9 Months' },
  { value: 10, label: '10 Months' },
  { value: 11, label: '11 Months' },
  { value: 12, label: '12 Months' },
];

export const severityAlertList = {
  1: 'Severity Low: Coordinated care, lowered medication costs, provided education',
  2: 'Severity Medium: Avoided unnecessary office visits or unnecessary medications',
  3: 'Severity High: Potentially avoided ED visit or inpatient hospital stay',
};
