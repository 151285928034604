import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Typography, Divider } from '@mui/material';

import {
  ControlledText,
  ControlledTextPhone,
  ControlledDropdown,
  ControlledAddressSearch,
  ControlledTimePicker,
} from 'components/react-hook-form-fields';

import { IAddressSuggestions } from 'components/react-hook-form-fields/types';

import { states } from 'constants/lists';

import { nameOfFactory } from 'utils/types-util';
import { ADD_ADDRESS_FORM, ADDRESS_USE_PROVIDER, PHONE_USE_BUSINESS } from 'constants/index';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';

import { buildQaIdProp, buildQaId } from 'utils/build-qa-id';
import { addDeliveryProvider } from 'actions/action-contacts';

import { IState } from 'interfaces/redux/IState';
import { assignRankingAndKeys, processPhoneData } from 'services/utils/demographic-service';

interface IFormProps {
  handleCancel: () => void;
}

interface IFormAddressFields {
  use: string;
  line1: string;
  city: string;
  state: string;
  zip: string;
  line2: string;
  key: number;
  rank: number;
}

interface IFormFields {
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  providerOfficeName: string;
  line1: string;
  city: string;
  state: string;
  zip: string;
  line2: string;
  phone: string;
  fromTime: Date;
  toTime: Date;
}

interface IFormPhoneFields {
  value: string;
  fromTime: Date;
  toTime: Date;
  key: number;
  rank: number;
  use: string;
}

const getFieldName = nameOfFactory<IFormFields>();

const qaIdProp = buildQaIdProp('add-delivery-provider-form');
const qaId = buildQaId('add-delivery-provider-form');

const AddDeliveryProviderModal: FC<IFormProps> = (props: IFormProps): JSX.Element => {
  const { handleCancel } = props;
  const {
    handleSubmit,
    control,
    setValue,

    formState: { errors },
  } = useForm<any>();
  const patient = useSelector((state: IState) => state.patient);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const address: IFormAddressFields[] = [
      {
        use: ADDRESS_USE_PROVIDER,
        line1: values.line1,
        city: values.city,
        state: values.state,
        zip: values.zip,
        line2: values.line2,
        key: 1,
        rank: 1,
      },
    ];

    const phone: IFormPhoneFields[] = [
      {
        use: PHONE_USE_BUSINESS,
        value: values.phone,
        key: 1,
        rank: 1,
        fromTime: values.fromTime,
        toTime: values.toTime,
      },
    ];
    const payload = {
      patient_id: patient.id,
      first_name: values.firstName,
      last_name: values.lastName,
      middle_name: values.middleName,
      suffix: values.suffix,
      provider_office_name: values.providerOfficeName,
      addresses: JSON.stringify(assignRankingAndKeys(address, 0)),
      phones: JSON.stringify(processPhoneData(assignRankingAndKeys(phone, 0), true)),
    };

    try {
      dispatch(addDeliveryProvider(payload));
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      props?.handleCancel();
    }

    setIsLoading(false);
    handleCancel();
  };

  return (
    <div>
      <Grid container direction="column">
        <Typography variant="h6" gutterBottom>
          Add Provider Information
        </Typography>
        <Box mt={2} />
        <form>
          <Grid container direction="column">
            <Typography variant="subtitle2">Provider Information</Typography>
            <Box mt={2} />
            <Grid spacing={2} item container>
              <Grid item xs={2}>
                <ControlledText
                  defaultValue={null}
                  name={getFieldName('lastName')}
                  control={control}
                  label="Last Name"
                  {...qaIdProp('lastName')}
                />
              </Grid>
              <Grid item xs={2}>
                <ControlledText
                  defaultValue={null}
                  name={getFieldName('firstName')}
                  control={control}
                  label="First Name *"
                  validations={{ required: true }}
                  inputMetaData={{ touched: Boolean(errors.firstName), error: 'Required' }}
                  {...qaIdProp('firstName')}
                />
              </Grid>
              <Grid item xs={2}>
                <ControlledText
                  defaultValue={null}
                  name={getFieldName('middleName')}
                  control={control}
                  label="Middle Initial"
                  {...qaIdProp('middleName')}
                />
              </Grid>
              <Grid item xs={2}>
                <ControlledText
                  defaultValue={null}
                  name={getFieldName('suffix')}
                  control={control}
                  label="Suffix"
                />
              </Grid>
            </Grid>
          </Grid>
          <Box mt={2} />
          <Grid container direction="column">
            <Typography variant="subtitle2">Office Address</Typography>
            <Box mt={2} />
            <Grid spacing={2} container>
              <Grid item xs={2}>
                <ControlledText
                  defaultValue={null}
                  name={getFieldName('providerOfficeName')}
                  control={control}
                  label="Office Name *"
                  inputMetaData={{ touched: Boolean(errors.providerOfficeName), error: 'Required' }}
                  validations={{ required: true }}
                  {...qaIdProp('providerOfficeName')}
                />
              </Grid>
              <Grid item xs={3}>
                <ControlledAddressSearch
                  defaultValue={null}
                  label="Address"
                  control={control}
                  name={getFieldName('line1')}
                  setSuggestions={(addr: IAddressSuggestions) => {
                    setValue('city', addr.city);
                    setValue('state', addr.state);
                    setValue('zip', addr.zip);
                  }}
                  {...qaIdProp('line1')}
                />
              </Grid>
              <Grid item xs={1}>
                <ControlledText
                  defaultValue={null}
                  name={getFieldName('line2')}
                  control={control}
                  label={ADD_ADDRESS_FORM.line2.label}
                  placeholder={ADD_ADDRESS_FORM.line2.placeholder}
                  {...qaIdProp('line2')}
                />
              </Grid>
              <Grid item xs={1}>
                <ControlledText
                  defaultValue={null}
                  name={getFieldName('city')}
                  control={control}
                  label="City *"
                  inputMetaData={{ touched: Boolean(errors.city), error: 'Required' }}
                  validations={{ required: true }}
                  {...qaIdProp('city')}
                />
              </Grid>
              <Grid item xs={2}>
                <ControlledDropdown
                  label="States"
                  control={control}
                  name={getFieldName('state')}
                  options={states}
                  defaultValue={null}
                  {...qaIdProp('state')}
                />
              </Grid>
              <Grid item xs={1}>
                <ControlledText
                  defaultValue={null}
                  name={getFieldName('zip')}
                  control={control}
                  label="Zip *"
                  inputMetaData={{ touched: Boolean(errors.zip), error: 'Required' }}
                  validations={{ required: true }}
                  {...qaIdProp('line1')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box mt={2} />
          <Grid container direction="column">
            <Typography variant="subtitle2">Office Phone</Typography>
            <Box mt={2} />
            <Grid item spacing={2} container>
              <Grid item xs={3}>
                <ControlledTextPhone
                  defaultValue={null}
                  name={getFieldName('phone')}
                  control={control}
                  label="Phone *"
                  inputMetaData={{ touched: Boolean(errors.phone), error: 'Required' }}
                  validations={{ required: true }}
                  {...qaIdProp('phone')}
                />
              </Grid>
              <Grid item xs={2}>
                <ControlledTimePicker
                  defaultValue={null}
                  name={getFieldName('fromTime')}
                  control={control}
                  label="Availability Start"
                  inputMetaData={{ touched: Boolean(errors.fromTime), error: 'Required' }}
                  {...qaIdProp('fromTime')}
                />
              </Grid>
              <Grid item xs={2}>
                <ControlledTimePicker
                  defaultValue={null}
                  name={getFieldName('toTime')}
                  control={control}
                  label="Availability End"
                  inputMetaData={{ touched: Boolean(errors.toTime), error: 'Required' }}
                  {...qaIdProp('toTime')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box pt={4} />
            <Divider />
            <ConfirmationPanel
              cancelButtonName="add_delivery_provider_cancel_button"
              submitButtonName="add_delivery_provider_submit_button"
              hideSubmit={false}
              hideCancel={false}
              submitButtonText="Save"
              handleCancel={() => handleCancel()}
              handleSubmit={handleSubmit(onSubmit)}
              isLoading={isLoading}
              loadingText="Saving"
              saveButtonQaId={qaId('save')}
              cancelButtonQaId={qaId('cancel')}
            />
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default AddDeliveryProviderModal;
