import { workListChanged } from 'actions/action-patient';
import {
  editFillDeliveryConfirmation,
  editTask,
  updateFdcStatusForPrintedOrder,
} from 'actions/action-tasks';
import { TaskStatus } from 'interfaces/enums/TaskStatus';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { IPrintLabelButton } from './types';

export const updateFdcStatus = ({
  data,
  dispatch,
  setStatus,
  setCurrentStatus,
}: IPrintLabelButton) => {
  if (data?.status_id === FillDeliveryConfirmationStatus.Dispense) {
    const { fdc_statuses, auto_update_courier } = window.FEATURES;
    const autoUpdateAndFdcStatuses = fdc_statuses && auto_update_courier;
    const statusId = autoUpdateAndFdcStatuses
      ? FillDeliveryConfirmationStatus.Shipped
      : FillDeliveryConfirmationStatus.Packed;
    const taskStatusText = autoUpdateAndFdcStatuses ? TaskStatus.Shipped : TaskStatus.Packed;

    const updatedTask = {
      id: data?.id,
      taskType: data?.taskType,
      therapy_id: data?.therapy_id,
      status_id: statusId,
    };
    dispatch && dispatch(editTask(updatedTask, data?.taskType));
    setStatus && setStatus(taskStatusText);
    dispatch &&
      dispatch(
        updateFdcStatusForPrintedOrder({
          ...updatedTask,
          task_status_text: taskStatusText,
        }),
      );
    setCurrentStatus && setCurrentStatus(updatedTask.status_id);
    dispatch && dispatch(workListChanged());
    dispatch && dispatch(editFillDeliveryConfirmation(updatedTask));
  }
};
