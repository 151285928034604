/* eslint-disable react/require-default-props, @typescript-eslint/naming-convention */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { styles } from './missing-required-form-values.styles';

const fieldLabels: Record<string, any> = {
  ancillary_supplies: 'Ancillary Supplies',
  dispensing_pharmacy: 'Dispensing Pharmacy',
  method: 'Method of data collection',
  dispense_qty: 'Dispense Quantity',
  delivery_dt: ['Expected Delivery Date', 'Expected Pick Up Date'],
  signature_required: 'Signature Required',
  order_type_address: 'Pick-up Location',
  shipping_method: 'Shipping Method',
  spoke_to_patient_dt: 'Spoke to Patient Date',
  ship_date: 'Ship Date',
  courier_type: 'Courier Type',
  payment_methods: 'Payment Method on File',
  phones: 'Phones',
  emails: 'Emails',
  address: 'Delivery Address',
  preferred_rx_delivery_method: 'Delivery Method',
  preferred_phone_number: 'Preferred Phone Number',
  needsby_date: 'Needs by Date',
  days_supply: 'Days Supply',
  validate_address: 'Delivery address is not validated',
  wk_pp_received: 'Welcome Kit and Privacy Policy received',
  welcome_kit_sent: 'Welcome kit required',
  is_notice_privacy_practices_sent: 'Privacy notice required',
  new_allergies: 'Does Patient have any New Allergies',
  side_effects: 'Any new side-effects',
  changes_in_medication: 'Any Changes in Patient Medications',
  patient_missed_doses: 'Has the Patient Missed any Doses',
  patient_questions: 'Does the Patient have any Questions for the Pharmacist',
  new_medical_conditions: 'Does the Patient have any New Medical Conditions',
  hospital_visit: 'Has the Patient been hospitalized',
  new_infections: 'Any New Infections',
  rx_number: 'Rx Number',
  authorization_code: 'REMS Authorization Code',
  followup_dt: 'Follow up date',
  in_progress_reason: 'In Progress Reason',
  order_notes: 'The order note value is more than 50 characters',
  special_instructions: 'The delivery note value is more than 50 characters',
  non_provider_address_reason:
    'Delivery of a Clinic-Administered therapy to a non-Provider address requires a reason',
};
const animationSettings = [
  { outline: '3px dashed transparent' },
  { outline: '3px dashed transparent' },
  { outline: '3px dashed transparent' },
  { outline: '3px dashed transparent' },
  { outline: '3px dashed transparent' },
  { outline: '3px dashed red' },
  { outline: '3px dashed transparent' },
  { outline: '3px dashed red' },
  { outline: '3px dashed transparent' },
  { outline: '3px dashed red' },
  { outline: '3px dashed transparent' },
  { outline: '3px dashed red' },
  { outline: '3px dashed transparent' },
  { outline: '3px dashed red' },
  { outline: '3px dashed transparent' },
];

interface Props {
  formName: string;
  isPickUp?: boolean;
  classes: any;
  isDeliveryAddressValidated: boolean;
  selectedStatus: number;
}

const MissingResponses: React.FC<Props> = (props: Props): JSX.Element => {
  const { isPickUp, classes, isDeliveryAddressValidated, selectedStatus } = props;
  const missingRequiredValues = useTypedSelector(state => {
    const parsedSyncErrors: Record<string, any> = {};
    Object.keys(state.form[props.formName].syncErrors || {}).forEach(key => {
      const val = state.form[props.formName].syncErrors[key];
      if (Array.isArray(val)) {
        val.forEach((value, index) => {
          if (value !== undefined) {
            if (!parsedSyncErrors[key]) {
              parsedSyncErrors[key] = [{ [index]: value }];
            } else {
              parsedSyncErrors[key].push({ [index]: value });
            }
          }
        });
      } else {
        parsedSyncErrors[key] = val;
      }
    });
    if (!isDeliveryAddressValidated) {
      parsedSyncErrors.validate_address = 'Required';
    }
    if (state.form[props.formName].values.order_notes?.length > 50 && selectedStatus === 4002) {
      parsedSyncErrors.order_notes = 'Required';
    }
    if (
      state.form[props.formName].values.special_instructions?.length > 50 &&
      selectedStatus === 4002
    ) {
      parsedSyncErrors.special_instructions = 'Required';
    }
    return parsedSyncErrors;
  });

  return (
    <Grid container item sx={{ display: 'flex' }} justifyContent="flex-end" xs={12}>
      <Grid item sx={{ paddingInline: 3, paddingY: 2 }} className={classes.content}>
        <Typography className={classes.title}>Missing information :</Typography>
        {Object.keys(missingRequiredValues).map(item => {
          const itemId = Array.isArray(missingRequiredValues[item])
            ? `${item}.${Object.keys(missingRequiredValues[item][0])[0]}`
            : item;
          const deliveryLabel = isPickUp ? fieldLabels.delivery_dt[1] : fieldLabels.delivery_dt[0];
          const singleFieldLabel = item === 'delivery_dt' ? deliveryLabel : fieldLabels[item];
          return (
            <Typography
              key={item}
              className={classes.error}
              onClick={() => {
                const target = document.querySelector(`[data-fc-mv-id="${itemId}"]`);
                const targetStart = document.querySelector(`[data-fc-mv-start="${itemId}"]`);

                (targetStart || target)?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                });
                target?.animate(animationSettings, 2000);
                // @ts-ignore
                target?.focus({ preventScroll: true });
              }}
            >
              {Array.isArray(missingRequiredValues[item]) && missingRequiredValues[item].length > 1
                ? `${fieldLabels[item]} (${missingRequiredValues[item].length})` || item
                : singleFieldLabel || item}
            </Typography>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default compose<Props, any>(withStyles(styles))(MissingResponses);
