import { MED_SYNC_UPDATE_SUCCESS } from 'containers/patient/med-sync/med-sync-actions';
import { updateTherapiesStateWithMedSyncData } from 'containers/patient/med-sync/med-sync-utils';
import {
  DATA_THERAPIES_REQUEST,
  DATA_THERAPIES_SUCCESS,
  DATA_THERAPIES_FAILURE,
  ADD_THERAPY,
  ADD_THERAPY_AR,
  EDIT_THERAPY,
  UPDATE_THERAPY_PROPERTIES,
  UPDATE_THERAPIES_DISPENSING_PHARMACY,
  SELECTED_PATIENT_ID,
  BULK_TASKS_UPDATED,
  UPDATE_MEDICATION_SUCCESS,
  FETCH_FILL_CYCLES_SUCCESS,
  UPDATE_COPIED_THERAPY,
} from '../constants';
import {
  getReducerDataInitialState,
  convertListToMap,
  addElementToReducerData,
  editElementInReducerData,
  editElementPropertiesInReducerData,
} from './helper-reducer';

const initialState = getReducerDataInitialState();

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECTED_PATIENT_ID:
    case DATA_THERAPIES_REQUEST: {
      return {
        loaded: false,
        loading: true,
        data: {},
        error: false,
      };
    }
    case DATA_THERAPIES_SUCCESS: {
      if (payload.data && payload.data.therapies) {
        const list = [...payload.data.therapies];
        list.sort((a, b) => {
          const statusA = a.administration_status_category_id;
          const statusB = b.administration_status_category_id;
          if (statusA === statusB) {
            return new Date(a.needsby_date) - new Date(b.needsby_date);
          }
          return statusB - statusA;
        });
        return {
          loaded: true,
          loading: false,
          data: convertListToMap(
            list.map((therapy, i) => ({
              ...therapy,
              order: i,
            })),
            'id',
          ),
          error: false,
        };
      }
      return state;
    }
    case DATA_THERAPIES_FAILURE: {
      return {
        loaded: false,
        loading: false,
        data: {},
        error: true,
      };
    }
    case ADD_THERAPY_AR:
    case UPDATE_COPIED_THERAPY:
    case ADD_THERAPY: {
      if (payload.data && payload.data.therapy) {
        return addElementToReducerData(state, payload.data.therapy);
      }
      return state;
    }
    case EDIT_THERAPY: {
      const payloadTherapyData = payload.data?.therapy;
      if (payloadTherapyData?.length) {
        return payloadTherapyData.reduce(
          (updatedState, therapyData) => editElementInReducerData(updatedState, therapyData),
          state,
        );
      }
      return state;
    }
    case UPDATE_THERAPIES_DISPENSING_PHARMACY: {
      const newState = { ...state };
      const { therapyIds, pharmacy } = payload.updatedPayload;
      therapyIds.forEach(therapyId => {
        newState.data[therapyId].dispensing_pharmacy_npi = pharmacy.npi;
        newState.data[therapyId].dispensing_pharmacy_name = pharmacy.name;
        newState.data[therapyId].dispensing_pharmacy_doing_business_as = null;
      });
      return newState;
    }
    case UPDATE_MEDICATION_SUCCESS: {
      const { updated_therapy: updatedTherapy } = payload.data;
      if (updatedTherapy && updatedTherapy.id) {
        const existingTherapy = state.data[updatedTherapy.id];
        const newTherapy = {
          ...existingTherapy,
          ...updatedTherapy,
        };
        return editElementInReducerData(state, newTherapy);
      }
      return state;
    }
    case UPDATE_THERAPY_PROPERTIES: {
      const { id, values } = payload;
      return editElementPropertiesInReducerData(state, id, values);
    }
    case BULK_TASKS_UPDATED: {
      const newState = { ...state };

      if (payload.data && payload.data.updated_tasks) {
        const { updated_tasks: updatedTasks } = payload.data;

        updatedTasks
          .filter(updatedTask => updatedTask.therapy_id)
          .forEach(updatedTask => {
            const newStateTherapy = newState.data[updatedTask.therapy_id];
            if (
              newStateTherapy &&
              updatedTask.fill_cycle_number &&
              newStateTherapy.fill_cycle < updatedTask.fill_cycle_number
            ) {
              newState.data[updatedTask.therapy_id].fill_cycle = updatedTask.fill_cycle_number;
            }
            if (
              newStateTherapy &&
              newStateTherapy.therapy_duration !== updatedTask.therapy_duration
            ) {
              newState.data[updatedTask.therapy_id].therapy_duration = updatedTask.therapy_duration;
            }
          });

        // Therapies' clinical support can be updated as a side effect of risk strat task updates.
        updatedTasks
          .flatMap(updatedTask => updatedTask.updatedTherapies || [])
          .forEach(updatedTherapy => {
            newState.data[updatedTherapy.id] = {
              ...newState.data[updatedTherapy.id],
              clinical_support_status: updatedTherapy.clinical_support_status,
              clinical_support_status_id: updatedTherapy.clinical_support_status_id,
              clinical_support_status_reason: updatedTherapy.clinical_support_status_reason,
              clinical_support_additional_reason: updatedTherapy.clinical_support_additional_reason,
            };
          });
      }

      return newState;
    }
    case FETCH_FILL_CYCLES_SUCCESS:
      if (!payload.data || typeof payload.data !== 'object') {
        return state;
      }
      const nextState = { ...state };
      let updates = 0;
      Object.entries(payload.data).forEach(([therapyId, fillCycles]) => {
        const therapy = state.data[Number(therapyId)];
        if (therapy) {
          const fillCycle = Object.values(fillCycles).find(
            fc => fc.fill_cycle === therapy.fill_cycle,
          );
          if (fillCycle) {
            nextState.data[therapyId] = {
              ...therapy,
              days_supply: fillCycle.days_supply,
            };
            updates += 1;
          }
        }
      });
      if (updates > 0) {
        return nextState;
      }
      return state;
    case MED_SYNC_UPDATE_SUCCESS:
      return {
        ...state,
        data: updateTherapiesStateWithMedSyncData(state.data, payload.medSyncData),
      };
    default:
      return state;
  }
};
