import { Drawer, List, ListItem, Button } from '@mui/material';
import { Sms as SmsIcon } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TogglePerProtocolFilter } from 'actions/action-tasklist-sort';
import { ToggleLeftDrawer, setInitialUrl } from 'actions/action-view';
import { clearPatientListAndUpdateHeader } from 'actions/action-patient-list';
import { Loader } from 'components/loading-overlay/loading-overlay';
import {
  TaskIconSidebar,
  ExpandIcon,
  PrescriptionBottleIcon,
  StarIcon,
  StarIconSelected,
} from 'components/icons/icons';
import { THERAPIES, MR, RS, SMS_RELATED_TASK, STAR } from 'constants/index';
import { taskListOrder } from 'constants/lists';
import { getTaskTypesInOrder } from 'services/utils/task-service';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { windowFeatureIsEnabled } from 'config/window-features';
import { styles } from './side-bar-styles';
import { addParamToQueryString, getPatientsUrl, getTaskUrl } from '../../../helpers/router';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => WrappedComponent => props => <WrappedComponent {...props} width="xs" />;

export class SideMenu extends Component {
  // Initialize redux state from url
  componentDidMount() {
    const { onRequestTasks, location } = this.props;
    onRequestTasks(location.pathname);
  }

  componentDidUpdate() {
    // eslint-disable-next-line no-shadow
    const { sidebarTaskType, taskCounts, location, push } = this.props;
    if (!taskCounts.loading && !sidebarTaskType) {
      let taskWithCount = null;
      const taskTypes = getTaskTypesInOrder(taskCounts.data);
      taskTypes.forEach(type => {
        if (!taskWithCount && taskCounts.data[type].task_cnt) {
          taskWithCount = type;
        }
      });
      if (!taskWithCount) {
        [taskWithCount] = taskTypes;
      }
      push({
        search: addParamToQueryString(location, 'task_type', taskWithCount),
      });
    }
    return null;
  }

  getTaskLink(taskType) {
    const { selectedPatientId, selectedTaskType, selectedTaskId, patientTab } = this.props;
    if (selectedTaskType && selectedTaskId) {
      const query = queryString.stringify({
        task_type: taskType,
        sidebar: 'tasks',
      });

      return getTaskUrl(
        selectedPatientId,
        undefined,
        selectedTaskType,
        selectedTaskId,
        false,
        query,
      );
    }
    return getPatientsUrl(taskType, patientTab || 'tasks');
  }

  renderTaskTypeMenuItem(taskType, classes) {
    // TODO: this should happen in the reducer after fetching tasks
    if (taskType === MR && window.FEATURES.hide_mr) {
      return null;
    }

    const { taskCounts, sidebarTaskType, clearPatientList } = this.props;
    const taskLink = this.getTaskLink(taskType);

    const count = taskCounts.data[taskType] ? taskCounts.data[taskType].task_cnt : 0;

    const getSidebarIcon = isSelected => {
      switch (taskType) {
        case THERAPIES:
          return PrescriptionBottleIcon;
        case SMS_RELATED_TASK:
          return SmsIcon;
        case STAR:
          if (isSelected) {
            return StarIcon;
          }
          return StarIconSelected;
        default:
          break;
      }
      return null;
    };

    const preFetchAction = () => {
      if (sidebarTaskType !== taskType) {
        clearPatientList(taskType);
        this.props.onToggleDrawer(false);
      }
    };

    return (
      <ListItem
        button
        key={taskType}
        id={taskType}
        className={classes.listItem}
        component={Link}
        to={taskLink}
        onClick={preFetchAction}
      >
        <TaskIconSidebar
          icon={getSidebarIcon(sidebarTaskType === taskType)}
          taskCount={count}
          btnText={taskType}
          taskName={taskCounts.data[taskType] ? taskCounts.data[taskType].task_description : null}
          isLoading={this.props.taskCounts.loading}
          isSelected={sidebarTaskType === taskType}
        />
      </ListItem>
    );
  }

  render() {
    const { classes, taskCounts, onToggleDrawer, isLeftDrawerOpen, show } = this.props;
    if (!show) return null;

    const showRS = windowFeatureIsEnabled('cdm_customer');

    const taskTypeList = Object.keys(taskCounts.data).filter(it => showRS || it !== RS);

    taskTypeList.sort((a, b) => {
      if (!taskListOrder.includes(a)) {
        return 1;
      }
      return taskListOrder.indexOf(a) - taskListOrder.indexOf(b);
    });

    const taskTypes = taskCounts ? taskTypeList : [];

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.taskBar),
        }}
      >
        <Loader loaded loadedClassName={classes.menuLoader}>
          <List className={classes.menuList}>
            <Button className={classes.expandButton} onClick={() => onToggleDrawer()}>
              <ExpandIcon
                className={
                  isLeftDrawerOpen ? classes.expandButtonExpanded : classes.expandButtonCollapsed
                }
              />
            </Button>

            {taskTypes.map(key => this.renderTaskTypeMenuItem(key, classes))}
          </List>
        </Loader>
      </Drawer>
    );
  }
}

function mapStateToProps(state) {
  const {
    tasklistSort,
    taskCounts,
    selectedPatientId,
    selectedTaskType,
    selectedTaskId,
    tabControl,
    view,
    sidebarTaskType,
  } = state;
  const { patientTab } = tabControl;
  return {
    tasklistSort,
    taskCounts,
    selectedPatientId,
    selectedTaskType,
    selectedTaskId,
    patientTab,
    isLeftDrawerOpen: view.leftDrawer,
    sidebarTaskType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelectTask: () => {
      dispatch(new ToggleLeftDrawer(true));
    },
    onTogglePerProtocolFilter: bool => dispatch(new TogglePerProtocolFilter(bool)),
    onRequestTasks: url => {
      dispatch(setInitialUrl(url));
    },
    onToggleDrawer: state => dispatch(new ToggleLeftDrawer(state)),
    push: value => dispatch(push(value)),
    clearPatientList: type => dispatch(clearPatientListAndUpdateHeader(type)),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
)(SideMenu);
